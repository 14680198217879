import axios from '@/request/http'

const services = {
  // 地区列表
  getServices(params) {
    return axios.get(`/api/news/services`, { params })
  },
  // 考试列表
  getexamineList(params) {
    return axios.get(`/api/examine`, { params })
  },
  // 章节列表
  getSectionList(params) {
    return axios.get(`/api/examine-section`, { params })
  },
  // 题目列表
  getQuestionsList(params) {
    return axios.get(`/api/examine-section-questions`, { params })
  },
  submitAnswer(data) {
    return axios.post(`/api/examine-user-answer`, data)
  },
  // 考试资料
  getInformationList(params) {
    return axios.get(`/api/examine-information`, { params })
  },
  // 证书与进度
  getCertificateList(params) {
    return axios.get(`/api/examine-certificate`, { params })
  },
  // 合作伙伴
  getPartnerList() {
    return axios.get(`/api/partner`)
  }
}
export default services
