export default {
  ifApplicablePleaseInputYourCodeHere: ' 如您持有代码，请在此输入。',
  memberID: '会员 ID',
  promotionCode: '代码（选填）',
  pleaseEnterPromotionCode: '请填写代码',
  unableToAdd: '无法添加',
  personalData: '个人资料',
  detailsOfCooperation: '合作详情',
  cooperationPartner: '合作方',
  cooperativeProducts: '合作产品',
  offTheShelf: '已下架',
  cooperationMaterials: '合作资料',
  cooperationMaterialsUpload: '合作资料上传，如合同、协议等。支持 jpg、 jpeg 和 png 图片及 pdf 文件。',
  myEvaluationOfCooperation: '我的合作评价',
  peAPartnershipEvaluation: '请输入合作评价',
  anonymousReviews: '匿名评价',
  acceptCooperation: '接受合作',
  refusalToCooperation: '拒绝合作',
  scanQrCodeAddWeChat: '扫描二维码添加微信',
  uniqueSellingPoint: '独家卖点',
  uniqueSellingPointEn: '独家卖点 (英文)',
  numberOfTourGuides: '导游数量',
  numberOfTourGuidesEn: '导游数量 (英文)',
  numberOfQualifiedTravelPlanners: '合格旅行规划师人数',
  numberOfQualifiedTravelPlannersEn: '合格旅行规划师人数 (英文)',
  membershipInWhichAssociations: '是哪些协会的成员 (如NTA、VIRTUOSO等)',
  membershipInWhichAssociationsEn: '是哪些协会的成员 (如NTA、VIRTUOSO等) (英文)',
  complaintContact: '投诉联系人',
  complaintContactEn: '投诉联系人 (英文)',
  emergencyContact: '紧急联系人',
  emergencyContactEn: '紧急联系人 (英文)',
  numberOfSuccessfullyArrangedVisitorsToChina: '成功安排来华旅游人数',
  numberOfSuccessfullyArrangedVisitorsToChinaEn: '成功安排来华旅游人数 (英文)',
  totalNumberOfVehicles: '车辆总数',
  totalNumberOfVehiclesEn: '车辆总数 (英文)',
  totalPassengerCapacityOfVehicles: '车辆载客总数',
  totalPassengerCapacityOfVehiclesEn: '车辆载客总数 (英文)',
  chainOrIndependent: '连锁或独立',
  chainOrIndependentEn: '连锁或独立 (英文)',
  chainName: '连锁名称',
  chainNameEn: '连锁名称 (英文)',
  accommodationRatingStarRating: '住宿业等级/星级',
  accommodationRatingStarRatingEn: '住宿业等级/星级 (英文)',
  numberOfRooms: '房间数',
  numberOfRoomsEn: '房间数 (英文)',
  numberOfBeds: '床位数',
  numberOfBedsEn: '床位数 (英文)',
  restaurantType: '餐馆类型',
  restaurantTypeEn: '餐馆类型 (英文)',
  cuisineRegionFlavor: '菜肴地区/口味',
  cuisineRegionFlavorEn: '菜肴地区/口味 (英文)',
  typeOfHonorsMichelinStarRating: '荣誉类型/米其林星级',
  typeOfHonorsMichelinStarRatingEn: '荣誉类型/米其林星级 (英文)',
  maximumSimultaneousDiningCapacity: '可同时最多接待就餐人数',
  maximumSimultaneousDiningCapacityEn: '可同时最多接待就餐人数 (英文)',
  businessHours: '营业时间',
  businessHoursEn: '营业时间 (英文)',
  operationalRoutes: '运营线路',
  operationalRoutesEn: '运营线路 (英文)',
  totalNumberOfAircraft: '飞机总数',
  totalNumberOfAircraftEn: '飞机总数 (英文)',

  tourLeaderProof: '领队证',
  tourGuideCertificate: '导游证书',
  tourLeaderCertificate: '领队证书',
  itineraryPlannerTrainingCertificate: '行程规划师培训证书',
  taxPaymentReceipt: '纳税税单',
  vehicleOperationCertificateIfOwnCar: '车辆运营证（若有车）',
  allVehicleInsurancePhotosOfAllVehiclesAllVehicleModelsAndProfiles: '所有车辆保险，所有车辆照片，所有车型及车辆概况（若有车）',
  vehicleOperationCertificate: '车辆运营证',
  vehiclePhotosVehicleProfiles: '车辆照片/车辆概况',
  foodPermit: '食品许可证',
  coachDriverPhotosOtherDocuments: '教练照片等资料',
  commercialVehicleOperationPermit: '商用车运营许可证',

  unlimited: '不限',
  evaluationContentIsRequired: '评价内容必填',
  groupTours: '团体旅游',
  airTicketAndHotel: '机票加酒店',
  airTicket: '机票',
  accommodation: '住宿',
  touristAttraction: '景点',
  socialAccounts: '社交账号',
  weibo: '微博',
  wechat: '微信',
  wechatAccount: '微信号',

  renewMembership: '会员续费',

  /**
   * 以下是之前表里的数据
   */
  identitySelection: '身份选择',
  regionSelection: '地区选择',
  pleaseSelect: '请选择',
  pleaseEnterPersonalWebsite: '请输入个人网址',
  companyName: ' 企业名',
  companyNameEn: ' 企业 (英文) 名',
  companyType: '行业类型',
  operatingStatus: '经营状况',
  companyLocation: '企业所在地',
  pleaseEnterIndustryLicenseNumber: '请输入行业执照编号',
  pleaseEnterRegisteredAddress: '请输入注册地址',
  pleaseEnterBusinessAddress: '请输入经营地址',
  pleaseEnterCompanyContactInformation: '请输入企业联系方式(座机)',
  pleaseEnterContactInformation: '请输入联系方式(座机)',
  pleaseEnterCompanyFaxNumber: '请输入企业传真号',
  pleaseEnterCompanyContactPerson: '请输入企业联系人(姓名 / 联系电话 / 邮箱)',
  'pleaseEnterComplaintContactPerson(name/ContactNumber/Email)': '请输入投诉负责人(姓名 / 联系电话 / 邮箱)',
  pleaseEnterCompanyWebsite: '请输入企业网址',
  starRating: '星级',
  features: '特色',
  isItAGroupCompany: '是否集团公司',
  'ifItIsAGroupCompany,PleaseEnterTheGroupName': '若是集团公司请输入集团名称',
  isThirdpartyFundSupervisionAvailable: '是否第三方资金监管',
  companySize: '企业规模',
  cateringServices: '餐饮服务',
  serviceLanguages: '服务语种',
  serviceLanguagesEn: '服务语种 (英文)',
  psServiceLanguages: '请填写服务语种，如：中文、英文、泰语、德语等',
  psServiceLanguagesEn: '请填写服务语种，如：中文、英文、泰语、德语等 (英文)',
  'industry-MajorCategory-Identity': '行业 - 大类 - 身份',
  domestic: '国内',
  international: '国际',
  pleaseEnterProductPrice: '请输入产品价格 (具体金额/价格范围/价格说明)',
  enterpriseInfoots: '请根据产品需要添加更多说明或服务的名称及内容（例：“服务人数-20人”）',
  detailedDescription: '详细介绍',
  pleaseFillInIfNeeded: '行程安排，请根据需要选填',
  basicsdata: '基础资料',
  enterpriseinformation: '基础资料',
  detailedinformationsupplement: '补充信息',
  qualificationcertificate: '资质证书',
  product: '产品',
  moreinformation: '更多资料',
  pleaseenterthebusinessname: '请输入企业名',
  pleaseenterthebusinessnameEn: '请输入企业 (英文) 名',
  unabletosavechange: '不能更改。若行业类型选择有误请',
  contactthecustomerservice: '联系客服',
  individualresume: '简介',
  briefintroduction: '简介',
  briefintroductionEn: '简介 (英文)',
  enterpriselabel: '企业标签',
  save: '保存',
  uploadProfilePicture: '上传头像',
  addlable: '添加标签',
  selected: '已选标签 (最多6个)：',
  destinationtag: '目的地标签：',
  input1: '请输入营业执照编号',
  inputtop1: '营业执照编号',
  registeredaddress: '注册地址',
  businessaddress: '经营地址',
  enterCompanyContactInformation: '企业联系方式(座机)',
  enterprisefaxnumber: '企业传真号',
  pleaseenterthecontactperson: '请输入企业联系人(姓名/联系电话/邮箱)',
  contactperson: '企业联系人(姓名/联系电话/邮箱)',
  pleaseenterthepersonresponsible: '请输入投诉负责人(姓名/联系电话/邮箱)',
  personresponsibleforcomplaint: '投诉负责人(姓名/联系电话/邮箱)',
  website: '企业网址',
  unloadziliao: '支持 jpg、jpeg 和 png 图片及 pdf 文件。',
  additiveproduct: '添加产品',
  puProductImage: '请上传产品图片',
  producttitle: '产品标题',
  articleTitle: '文章标题',
  articleTitleEn: '文章标题 (英文)',
  enterArticleTitle: '请输入文章标题',
  enterArticleTitleEn: '请输入文章标题 (英文)',
  entertheproducttitle: '请输入产品标题',
  productprice: '产品价格',
  entertheproductprice: '请输入产品价格',
  pleaseCompleteExplanation: '请完善说明项',
  pleaseCompleteTheItinerary: '请完善行程',
  name: '名称',
  content: '内容',
  adddescriptionitem: '添加说明项',
  enteritinerary: '请输入行程',
  cancel: '取消',
  customtag: '自定义标签：',
  enteracustomlabel: '请输入自定义标签',
  add: '添加',
  confirm: '确定',
  title: '标题',
  titleEn: '标题 (英文)',
  enterthetitle: '请输入标题',
  enterthetitleEn: '请输入标题 (英文)',
  entercontent: '请输入内容',
  entercontentEn: '请输入内容 (英文)',
  adddata: '添加资料',
  resubmitApplication: '重新提交申请',
  getback: '返回',
  businessidentity: '行业类型',
  otherBusinesses: '主营业务',
  pleaseenterpersonalbusinessprofile: '请输入个人/企业简介',
  selectedabels: '已选标签(最多6个)：',
  tagname: '标签名',
  pleaseenterabelname: '请输入标签名',
  tradeicensenumber: '行业执照编号',
  allfeatures: '所有特色(可多选)',
  completed: '已完成',
  jpgpngandpdf: '支持 jpg、jpeg 和 png 图片及 pdf 文件。',
  businesslicense: '营业执照',
  uploaded: '(已上传)',
  businesspermits: '营业许可证',
  mainbusinesspersonIDcard: '主要业务负责人身份证',
  insuranceinformation: '保险相关资料',
  close: '请选择',
  compile: '编辑',
  remove: '删除',
  price: '价格 (具体金额/价格范围/价格说明)',
  pleaseenterdetails: '请输入详细介绍',
  addstroke: '添加行程',
  productlabel: '产品标签',
  pleaseenteryourname: '请输入姓名',
  individuallabel: '个人标签',
  selectidentity: '选择身份',
  touristcertificate: '导游证',
  vocationaltrainingcertificateobtained: '曾获得的职业培训证书',
  obtaineddestinationandproducttrainingcertificatesormaterials: '曾获得的目的地和产品培训证书或资料',
  havewonhonors: '曾获得荣誉',
  contactway: '联系方式',
  phone: '联系电话',
  pleaseenteracontactnumber: '请输入联系电话',
  email: '邮箱',
  pleaseenteremail: '请输入邮箱',
  pleaseEnterAValidEmailAddress: '请输入正确的邮箱',
  hwebsite: '网址',
  jpgpng: '支持上传 jpg、jpeg 和 png 图片。',
  basicinfo: '基础资料',
  pleaseentername: '请输入名称',
  fillinuploadtheinformation: '加入正信会员，享更多权益。',
  yourAccountIsCurrentlyUnderReviewYouCanFillInOrEditMoreInformationToCompleteTheReviewFaster: '您的账号正在审核中，您可填写/编辑更多资料以便更快完成审核',
  theInformationOnThisPageIsNotPublic: '本页信息不公开，为方便后续企业间的合作交流建议填写完整',
  applyforprobation: '代码（选填）',
  year: '年',
  purchase: '立即购买',
  corporatmembershiptrialapplication: '企业会员试用申请',
  dmomembershiptrialapplication: '协会/目的地旅游局会员试用申请',
  corporatemembershiptrial: '协会/目的地旅游局会员试用是为加入正信平台的企业提供的一个限期的福利政策，我们会根据你填写资料的完整度作为一个考量标准给予申请的企业一定期限的企业会员试用资格。',
  dmocorporatemembershiptrial: '企业会员试用是为加入正信平台的企业提供的一个限期的福利政策，我们会根据你填写资料的完整度作为一个考量标准给予申请的企业一定期限的企业会员试用资格。',
  filloutthepaperworkfirst: '先去填写资料',
  applow: '立即申请',
  applicationis: '申请成功，请等待官方审核。',
  applicationisSuccessful: '恭喜，您已成功成为正信会员！', // 待通知修改
  pleaseselecttheocationofyourbusiness: '请选择地区',
  modifysuccessfully: '修改成功',
  ok: '好的',
  deleteornot: '是否删除',
  destinationCountriesReceivingTourists: '接收入境华人游客的目的地国家和地区',
  psDestinationCountriesReceivingTourists: '请选择接收入境华人游客的目的地国家和地区',
  whetherTheDomesticBusinessCoversTheWholeCountry: '国内业务是否覆盖全国',
  psWhetherTheDomesticBusinessCoversTheWholeCountry: '请选择国内业务是否覆盖全国',
  theMainMarketForDomesticBusiness: '国内业务主要市场',
  theMainMarketForDomesticBusinessEn: '国内业务主要市场 (英文)',
  psTheMainMarketForDomesticBusiness: '请填写国内业务主要市场',
  psTheMainMarketForDomesticBusinessEn: '请填写国内业务主要市场 (英文)',
  publicityPicture: '宣传图片',
  publicityDocument: '宣传文件',
  publicityArticle: '宣传文章',
  pdfpptwodexcel: '文件上传支持pdf、ppt、word、excel等常用文件格式。',
  articleLabel: '文章标签',
  addArticle: '添加文章',

  // source = corporate 挪过来的
  sortByRating: '按评分排序',
  initiateCooperation: '发起合作',
  privateMessage: '私信',
  basicInformation: '基础资料',
  moredata: '更多资料',
  reviews: '评价',
  viewMore: '查看更多',
  sendTo: '发送给：',
  cooperationProduct: '合作产品(选填)：',
  cooperationTitle: '合作标题',
  cooperationDetails: '合作详情',
  uploadFiles: '资料上传',
  reportComplaint: '举报投诉',
  reportcomplaintTarget: '举报/投诉对象：',
  reportcomplaintTitle: '举报/投诉标题',
  'report/complaintDetails': '举报详情',
  productDetails: ' 产品详情',
  systemMessage: '系统消息',
  search: '搜索',
  region: '地理位置',
  typeofenterprise: '行业类型',
  listofenterprises: '企业清单',
  complaintsandreports: '投诉/举报',
  supplementalInformation: '补充信息',
  pleaseenteracollaborationtitle: '请输入合作标题',
  uploadhtxy: '(合作资料上传，如合同、协议等)',
  send: '发送',
  viewdetails: '查看详情',
  pleaseenterareportcomplainttitle: '请输入举报/投诉标题',
  pleaseenterareportcomplaint: '请输入举报/投诉内容',
  myorder: '我的订单',
  set: '设置',
  logout: '退出登录',
  issuecooperation: '发起合作',
  sendpictures: '发送图片',
  pleaseenteramessage: '请输入消息',
  type: '类型',
  selectthearea: '选择地区',
  submit: '提交',
  unfold: '展开',
  packup: '收起',
  enterprise: '企业',
  price1: '价格：',
  journey: '行程',
  successfulcomplaint: '投诉成功',
  youdonothavepermission: '请加入会员',
  numberOfYearsInOperation: '运营年数',
  pleaseEnterNumberOfYearsInOperation: '请输入运营年数',
  globalNumberOfEmployees: '全球员工数量',
  psGlobalNumberOfEmployees: '请选择全球员工数量',
  mainContactForTheChineseMarket: '联系人姓名',
  mainContactForTheChineseMarketEn: '联系人姓名 (英文)',
  pleaseEnterMainContactForTheChineseMarket: '请输入联系人姓名',
  pleaseEnterMainContactForTheChineseMarketEn: '请输入联系人姓名 (英文)',
  mainContactForTheChineseMarketPhone: '联系人电话',
  pleaseEnterMainContactForTheChineseMarketPhone: '请输入联系人电话',
  mainContactForTheChineseMarketEmail: '联系人邮箱',
  pleaseEnterMainContactForTheChineseMarketEmail: '请输入联系人邮箱',
  officialWebsiteAddress: '官网地址',
  pleaseEnterOfficialWebsiteAddress: '请输入官网地址',
  termsAndConditions: '条款和条件',
  pleaseEnterTermsAndConditions: '请输入条款和条件',
  operatingOfficeAddress: '经营办公地址',
  operatingOfficeAddressEn: '经营办公地址 (英文)',
  pleaseEnterOperatingOfficeAddress: '请输入经营办公地址',
  pleaseEnterOperatingOfficeAddressEn: '请输入经营办公地址 (英文)',
  noBusinessWasFound: '暂无企业',
  goaddinformation: '暂无信息,快去添加吧',

  // source hotelcenter 挪过来的
  regularCorporateMembership: '普通企业会员',
  accountReviewInProgress: '账号审核中',
  accountReviewFailed: '账号审核未通过',
  dataCompleteness: '资料完整度：',
  relevantCertificatesAndMaterials: '资质证书',
  myCollaborations: '我的合作',
  notConfirmed: '未确认',
  underReview: '审核中',
  approved: '已通过',
  editProduct: '编辑产品',
  qualifiedEmployees: '资质员工',
  moreMaterials: '更多资料',
  tourGuide: '导游',
  teamLeader: '领队',
  removeEmployee: '移除员工',
  addEmployee: '添加员工',
  expand: '展开',
  collapse: '收起',
  advancedEnterpriseMembership: '高级企业会员',
  nocooperation: '暂无合作',
  noProductsYetAddYourProductsNow: '暂无产品，快去添加你的产品吧！',
  noDataYetAddYourInformationNow: '暂无资料，快去添加你的资料吧！',
  noQualifiedEmployeesYetAddYourEmployeesNow: '暂无资质员工，快去添加你的员工吧！',
  noevaluate: '暂无评价，合作后合作企业的评价将会出现在这里！',
  'customTags:': '自定义标签：',
  'destinationTags:': '目的地标签：',
  'selectedTags(upTo6):': '已选标签 (最多6个)：',
  addTags: '添加标签',
  searchResults: '搜索结果',
  'employeeNotFound.DirectlyAdd“xxAsANewEmployee.': '未找到该员工，直接添加“xx”为新员工',
  addDocument: '添加文件',
  addPicture: '添加图片',
  editdata: '编辑资料',
  noPersonalCorporateProfileProvided: '未填写个人/企业简介',
  dataIntegrity: '资料完整度：',
  expirationtime: '到期时间：',
  basicinformation: '基础资料',
  uploadziliao: '如身份证/护照、领队证/导游证等、职业培训证书、目的地和培训证书或资料、曾获得的荣誉等。支持 jpg、jpeg 和 png 图片及 pdf 文件。',
  viewmore: '查看更多',
  evaluate: '评价',
  fornewemployees: '为新员工',
  directaddition: '直接添加',
  noismy: '以上均不是我要找的员工',
  nofound: '未找到与',
  relevantemployee: '相关的员工',
  cooperativeenterprise: '合作企业：',
  toevaluate: '去评价',
  noinformationgotoaddit: '暂无信息，快去添加吧！',
  nocertificategotoaddacertificate: '暂无证书，快去添加证书吧！',
  noevaluation: '暂无评价',
  permissionswitch: '权限开关：',
  havebeenadded: '已添加',
  iftheemployee: '未找到该员工，直接添加',
  whethetoremovtheemployee: '是否移除该员工',
  confirmremoval: '确认移除',
  gotoCertificate: '暂无证书，快去添加证书吧',
  corporatemember: '企业会员',
  associationMember: '协会会员',
  premiumenterprisemember: '优质会员',
  ranking: '排行榜',
  turndown: '已驳回',
  businesslicensenumber: '营业执照编号：',
  egisteredddress: '注册地址：',
  companycontact: '企业联系方式：',
  corporatefaxnumber: '企业传真号：',
  enterprisecontact: '企业联系人：',
  complaintholder: '投诉负责人：',
  searchNow: '检索',
  reset: '重置',
  resultFindBegin: "找到 ",
  resultFindCener: " 条结果 (用时",
  resultFindEnd: "秒)",
  /**
   * 表里数据结束
   */
}
