export default {
  membershipFee: 'For Corporate members, the annual membership fee is $2000.',
  dmoFirstYearFree: 'The membership period is for one year from the date of registration, and renewal is required annually to maintain membership privileges.',
  renewManuallyAfterOneYear: "If you have any questions regarding code submission, please contact the SmoothTravel team at: <a href='mailto:membershipmanager{'@'}smoothtravel.com'>membershipmanager{'@'}smoothtravel.com</a>",

  /**
   * 以下是之前表里的数据
   */
  membercontent: 'Join us now. You will discover new ways to establish connections with global tourism industry partners and find quality and reliable service collaborators.',
  memberBenefits: 'Member Benefits',
  memberBenefitsSummary: 'To boost your arrivals from China or your China travel business, and to minimize your risk of doing business with new Chinese partners, SmoothTravel offers the following unique services to overseas members:',
  memberone: '1.The right to evaluate services provided by partner companies and access high-quality service providers for project collaboration and business exchanges.',
  membertwo: '2.Participation in seminars, field visits to destinations organized by the platform, expert training, etc.',
  memberthree: '3.Free access to industry updates, including in-depth annual industry reports.',
  membertfour: '4.The right to use the platform logo.',
  membertfive: '5.Participation in forums, salons, and other activities organized by the platform to facilitate member interaction and cooperation.',
  membertsix: '6.Promotion of charitable activities or high-quality projects of member organizations using the platform.',
  membertseven: "7.The right to provide suggestions, inquiries, criticism, and supervision regarding the platform's work and its responsible personnel.",
  memberteight: '8.The right to request assistance when facing significant difficulties or infringement of legal rights and interests, such as mediation or litigation.',
  membertnine: '9.The right to provide evidence and report cases of unfair industry competition.',
  membertten: '10.Voluntary membership with the freedom to resign.',
  memberObligationsSummary: 'Member Obligations Summary',
  memberteleven: "1.Compliance with the platform's articles of association, implementation of platform resolutions, and protection of the platform's reputation and the legal rights and interests of the association.",
  memberttwelve: '2.Completion of assigned tasks by the platform, active participation in and support of activities organized by the platform.',
  membertthirteen: '3.Payment of membership fees as required.',
  membertfourteen: "4.Acceptance of the association's evaluation and mediation.",
  'reportingSituations,ProvidingInformation,AndRelevantMaterialsToTheAssociation.': 'Reporting situations, providing information, and relevant materials to the association.',
  membersixteen: "6.Legitimate operation and adherence to the platform's recommended rules and norms.",
  memberseventeen: '7.Non-organization and non-participation in any activities that harm the platform and other members.',
  membershipRequirements: 'Membership Requirements',
  membereighteen: '1.Willingness to join the platform.',
  membernineteen: '2.Legally established within China, operating lawfully, and free from any negative reputation. This includes travel agencies, enterprises and institutions providing business services to travel agencies, and social organizations related to travel agencies.',
  membertwenty: "3.Companies or entities involved in receiving Shanghai's cross-border tourists, such as travel agencies, car rental companies, hotels, airlines, cruise companies, restaurants, tourist attractions, etc., must possess valid operating qualifications and adequate insurance.",
  membershipApplicationProcedure: 'Membership Application Procedure',
  membertwentyone: '1.Submit an application form for membership.',
  membertwentytwo: '2.Submit relevant supporting documents, including:',
  membertwentythree: '①.Copy of business license (or association registration certificate).<br/>②.Copy of the organization code certificate.<br/>③.Proof of valid insurance.',
  membertwentyfive: '3.Discussion and approval by the Council or authorized institution.',
  membertwentysix: "4.Issuance of an electronic membership certificate by the platform's Council or authorized institution, followed by an announcement.",
  membertwentyseven: "In the case of a member's violation of laws, regulations, or the articles of association, the Council or Executive Council may impose the following actions upon a majority vote",
  warningNotification: '1.Warning notification.',
  suspensionOfMemberRights: '2.Suspension of member rights.',
  expulsion: '3.Expulsion.',
  listingInTheIndustryBlacklist: '4.Listing in the industry blacklist.',
  membertwentyeight: 'Members who wish to resign must notify the platform in writing and return the electronic membership certificate. Automatic loss of membership status occurs if any of the following circumstances apply:',
  membertwentynine: '1.Failure to pay membership fees as required for two consecutive years.',
  memberthirty: '2.Failure to participate in platform activities as required for two consecutive years.',
  memberthirtyone: '3.No longer meeting the membership requirements.',
  lossOfCivilCapacity: '4.Loss of civil capacity.',
  memberthirtytwo: '5.Legal representative of the member entity deprived of political rights.',
  memberthirtythree: 'Upon resignation, automatic loss of membership, or expulsion, all corresponding positions, rights, and obligations within the platform will be terminated. The platform keeps a membership registry to record member information. In the event of changes to member information, the commitment is to promptly update the membership registry and inform the members.',
  selectionOfExcellentMerchants: 'Selection of Excellent Merchants',
  'toMeetTheEvaluationCriteriaForTheExcellentTourismMerchantProgram,YourCompanyMustEnsureThatItsManagementStrategiesContributeToProvidingHigh-qualityServices.IfYourCompanyOperatesChainStoresOrFranchisesInTheTourismIndustry,TheseStrategiesMustBeAppliedConsistentlyAcrossAllAssetsUnderYourCompany.DuringTheEvaluationProcess,YouWillBeScoredBasedOnTheExtentOfImplementingExcellentServices.Therefore,WhenApplyingForTheExcellentTourismMerchantProgram,YouNeedToSubmitAListOfEvaluationCriteria.ApplicantsShouldProvideAnOverallScoreForTheirManagementStrategiesAndTheExtentOfTheirApplicationInTheBusiness.': 'To meet the evaluation criteria for the Excellent Tourism Merchant program, your company must ensure that its management strategies contribute to providing high-quality services. If your company operates chain stores or franchises in the tourism industry, these strategies must be applied consistently across all assets under your company. During the evaluation process, you will be scored based on the extent of implementing excellent services. Therefore, when applying for the Excellent Tourism Merchant program, you need to submit a list of evaluation criteria. Applicants should provide an overall score for their management strategies and the extent of their application in the business.',
  'theSelectionCriteriaAndProcessWillBeImplementedBasedOnTheEvaluationGuidelinesAndElementsSpecificToVariousTourism-relatedIndustries.MeetingTheStandardsForAnExcellentMerchantWillQualifyYourCompanyAsAnExcellentTourismMerchantForOneYear.TheProgramWillBeEvaluatedAnnually.': 'The selection criteria and process will be implemented based on the evaluation guidelines and elements specific to various tourism-related industries. Meeting the standards for an excellent merchant will qualify your company as an Excellent Tourism Merchant for one year. The program will be evaluated annually.',
  'exclusiveBenefitsForExcellentMerchants:DiscountOnMembershipRenewalFees.': 'Exclusive Benefits for Excellent Merchants: Discount on membership renewal fees.',
  'iWouldLikeToParticipateInTheSelectionProcess.': 'I would like to participate in the selection process.',
  'toProvideHigh-qualityServicesToDifferentTypesOfEnterprises/institutions,WeHaveDifferentMembershipFeesForJoiningTheAdvancedEnterpriseMembershipBasedOnTheTypeOfCompany.IfYourCompanyIsReadyToJoinOurAdvancedMembership,PleaseSelectYourCompanyTypeBelowToViewOurPricing.IfYouHaveAnyQuestions,PleaseContactUs!': 'To provide high-quality services to different types of enterprises/institutions, we have different membership fees for joining the Advanced Enterprise Membership based on the type of company. If your company is ready to join our advanced membership, please select your industry below to view our pricing. If you have any questions, please contact us!',
  pleaseSelectTheCompanyType: 'Please select the industry',
  pleaseSelect: 'Please select',
  'selectionCannotBeChangedAfterSaving.IfYouHaveSelectedTheWrongCompanyType,PleaseContactCustomerService.': 'Selection cannot be changed after saving. If you have selected the wrong industry, please contact customer service.',
  viewPricesAfterSelectingTheCompanyType: 'View prices after selecting the industry',
  customizeAndPurchaseAdvancedEnterpriseMembership: 'Customize and purchase Advanced Enterprise Membership',
  "thePriceForYourCompany'sAdvancedEnterpriseMembershipIs": "The price for your company's Advanced Enterprise Membership is",
  orderServices: 'Order services',
  advancedEnterpriseMembership: 'Advanced Enterprise Membership',
  serviceDuration: 'Service duration',
  orderingCompany: 'Ordering company',
  orderPrice: 'Order price',
  weChatPay: 'WeChat Pay',
  alipay: 'Alipay',
  bankTransfer: 'Bank transfer',
  'afterCompletingThePayment,PleaseUploadThePaymentReceiptOrScreenshot.': 'After completing the payment, please upload the payment receipt or screenshot.',
  bankAccount: 'Bank account',
  bankName: 'Bank name',
  transferRemarks: 'Transfer remarks',
  transferAmount: 'Transfer amount',
  paymentReceipt: 'Payment receipt',
  'pleaseUploadThePaymentReceiptImageOrScreenshot.SupportsJpg,Png.': 'Please upload the payment receipt image or screenshot. Supports jpg, jpeg, png.',
  submit: 'Submit',
  membershipprogram: 'Membership program',
  membertfifteen: '5.Report the situation to the Association, provide information and relevant materials.',
  memberthirtyfour: 'Premium membership Requirements and criteria: (Admission criteria)',
  generalrulesforimplementation: 'General rules for implementation ',
  memberthirtyfive: 'The platform aims to provide the industry and consumers with: Better industry standards, better service, better travel experience, safer travel, promote the awareness of travel insurance, increase the supervision and safety of cross-border tourism transactions, reduce vicious tourism accidents, reduce complaints and disputes, and select all kinds of tourism suppliers in tourist destinations or tourist cities around the world that can provide quality services for Chinese tourists. Recommended to Chinese tourists and travel agencies; To help and guide Chinese tourists to identify and choose high-quality tourism service providers in the world, so as to obtain a comfortable and high-quality travel experience; Actively promote high-quality travel suppliers in various destinations in the Chinese market, and help Chinese outbound travel agencies and agents to select services and products from high-quality suppliers in order to provide more high-quality, diversified and personalized travel products for tourists. At the same time, it also builds a good supply and demand relationship for tourism service providers around the country to connect more and better tourism cooperation enterprises.',
  memberthirtysix: 'The platform will conduct annual selection according to the mutual rating of the members after cooperation, and launch the annual quality service list to affirm the enterprises that provide quality services. Enterprises that do not meet the quality service standards are verified and listed in the untrustworthy list to avoid additional losses for other partners.',
  memberthirtyseven: 'To meet the criteria of the QTS Scheme, your business/merchant must ensure that the management strategy is conducive to the provision of quality services. If your business/merchant operates a chain store or travel franchise business, the policy applies and applies to all assets of your business/merchant simultaneously. In the assessment process, you are asked to rate the implementation of excellent services. Therefore, your business/merchant is required to submit a list of assessment rules when applying to join the "Quality Travel Merchant" Scheme. The applicant enterprise/merchant should be given a comprehensive rating on its own management strategy and the extent to which the strategy has been applied in its business.',
  memberthirtyeight: 'The selection criteria and process will be implemented according to the selection criteria and selection elements of tourism-related industries. If an applicant’s score reaches the quality merchant standard, it will be defined as a quality tourism merchant, valid for one year. The assessment will be done annually.',
  referencestandard: 'reference standard',
  memberthirtynine: 'The selection criteria and process will be implemented according to the selection criteria and selection elements of tourism-related industries. If an applicant’s score reaches the quality merchant standard, it will be defined as a quality tourism merchant, valid for one year. The assessment will be done annually.',
  memberfortyTitile: '',
  memberforty: '1. Reasonable schedule: Travel agencies should design a schedule, taking into account the interests and needs of tourists, and adjust it according to local conditions. At the same time, the attractions and activities in the itinerary need to be arranged reasonably and fully, and not offered in a time span that is too compressed.',
  memberfortyone: "2. Excellent service attitude: Travel agency staff should have professional knowledge and good service attitudes, actively answer tourists' questions and provide help. During the journey, they should pay attention to the safety and health of tourists and make necessary arrangements.",
  memberfortytwo: '3. Dining and accommodation comfort: travel agencies should provide comfortable dining and accommodation environments to meet the basic needs of tourists. The quality of a hotel should match the price range of the tourism product and meet local standards',
  memberfortythree: '4. Professional tour guide service: Tour guides should have professional knowledge and skills, be able to provide accurate and detailed explanation and guidance for tourists, and assist tourists to deal with relevant problems. The tour guide should also know the local culture and customs and offer some useful advice to tourists.',
  memberfortyfour: "5. Solve problems in a timely manner: travel agencies should deal with problems in a timely manner, provide adequate explanations, and offer commensurate compensation if needed to tourists. Travel agencies should accept tourists' feedback in good faith and take measures to improve their service quality.",
  memberfortyfive: '6. Other special requirements: For some special types of tourism, such as exploration, mountaineering, etc., travel agencies need to provide relevant security and safety measures. In addition, travel agencies also need to pay attention to environmental protection and social responsibility.',
  memberfortysix: '7. Honest service: Travel agencies should not engage in false publicity or unfair competition in the process of trading, and should not disrupt the normal order of market trading.',
  memberfortyseven: 'I want to participate in the judging',
  /**
   * 表里数据结束
   */
}
