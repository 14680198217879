export default {
  labelMaxNote: '按线路出境人次/营业额最多选择6个标签',
  labelCustomNote: '如果您未找到适用于您的标签，请在自定义标签中填写',
  frequentlySearch: '热门搜索',
  yes: '是',
  no: '否',
  chinese: '中文',
  english: '英文',
  other: '其他',
  pleasechoose: '请选择',
  uploadProfilePicture: '上传头像',
  customtag: '自定义标签：',
  label: '标签：',
  enteracustomlabel: '请输入自定义标签',
  pleaseEnter: '请输入',
  alipay: '支付宝',
  wechat: '微信',
  transfer: '转账',
  creditCard: '信用卡',
  pendingPayment: '待支付',
  paid: '已付款',
  inApprovalProcess: '审批中',
  paymentCancel: '已取消',
  paymentSuccessful: '支付成功',
  download: '下载',
  china: '中国',
  international: '国际及港澳台',
  province: '省份',
  city: '城市',
  continent: '大洲',
  countriesAndRegions: '国家和地区',
  relatedResults: '相关结果',
  maxUploadSize: '最大上传 %s',
  pleaseInput: '请填写',
  pleaseProvideYourEmailAddress: '邮箱号不能为空',
  pleaseEnterTheCorrectEmailAddress: '请填写正确的邮箱号',
  sentSuccessfully: '发送成功',
  uploadArticleCover: '上传文章封面 (建议jpg/jpeg/png图片，尺寸 562x256)',
  psUploadArticleCover: '请上传文章封面',
  pleaseFillInTheArticleTitle: '请填写文章标题',
  pleaseFillInTheArticleTitleEn: '请填写文章标题 (英文)',
  anonymousUser: '匿名用户',
  credentialsPendingReview: '凭证待审核',
  rejected: '被驳回',
  refunded: '已退款',
  picture: '图片',
  cooperate: '合作',
  registrationBenefits24: '24年上半年注册试用',
}
