export default {
  /**
   * 以下是之前表里的数据
   */
  theOnlineTrainingExamFo: '旅游专家在线培训考试分为若干部分，只有答对每一部分的全部问题之后，才可以保存进入下一部分的培训考核。您可以分几次进行培训，从上次保存的章节部分开始下一阶段的培训考核。全部完成所有问答部分即可获得该培训的电子专家证书。全部答对即可获得该分类的专家证书。',
  nextQuestion: '下一题',
  singlechoiceQuestion: '单选题',
  multiplechoiceQuestion: '多选题',
  'section2Progress:': 'Section 2 答题进度：',
  submit: '提交',
  hint: '提示',
  okay: '好的',
  'thereAreIncorrectAnswersInThisSection.PleaseReattemptAndSubmitTheCorrectAnswers.': '该部分存在错题，请重新作答并提交正确答案',
  'congratulations!': '恭喜',
  'youHavePassedTheExamAndObtainedTheExpertCertificate.': '你通过了考试并获得专家证书',
  progress: '答题进度：',
  youpassedtheexamandgot: '您通过了考试并获得',
  expertcertificate: '专家证书',
  mistakes: '错题',
  mistakesNum: '当前还有 %d 道题未作答正确，请重新作答',
  /**
   * 表里数据结束
   */
}
