import { defineStore } from 'pinia'
import api from '@/request/api'
import { isEmpty, isNull, isUndefined } from "lodash";
import router from "@/router";

export const userAdminInfo = defineStore({
  id: 'adminInfo',
  state: () => ({
    token: '',
    adminInfo: {},
    language: localStorage.getItem('language') || 'cn',
    permission: false,
    identityList: [],
    allIdentityList: [], // (后加的) 所有的, 包含主营业务类型
    memberLog: [], // 订单列表
    unreadTotal: 0, // 未读数量
  }),
  getters: {
    // 宣传文件
    publicityDocument: state => state.adminInfo?.attachment?.filter(att => att.type === 2).map(att => {
          if (!att.hasOwnProperty('uid')) {
            att.uid = att.id
          }

          return att
        }),
    // 货币单位 中文对应人民币, 英文对应港币, 用于订单支付前展示, 支付后以 order 表的字段内容为准
    monetaryUnit: state => {
      return state.language === 'cn' ? '¥' : 'HK$'
    },
    // 目前入会价 (目前为止会员价格是一致的, 展示时可以直接前端展示, 实际价格还是会以后端的为主)
    membershipPrice: state => {
      return state.language === 'cn' ? state.adminInfo?.memberDictionary?.price : state.adminInfo?.memberDictionary?.hkPrice
    },
    // 有待支付的订单
    hasUnpaidOrder: state => {
      return state.memberLog.some(item => item.order?.status === 1)
    },
    // 有审核中的订单
    hasAuditingOrder: state => {
      return state.memberLog.some(item => item.order?.status === 2)
    },
    // 资料完整度进度比例
    dataCompletenessProgressRatio: state => {
      // 企业/协会
      let field = []
      let total = 0
      let emptyCount = 0
      if (parseInt(state.adminInfo?.memberDictionaryType) === 2) {
        total = 25
        field = [
          'userName', // 名称
          'enUserName',
          'otherMemberDictionaries', // 主营业务
          'intro', // 简介
          'enIntro',
          'labels', // 标签
          'serviceLanguages', // 服务语言
          'enServiceLanguages', // 服务语言
          'numberOfYearsInOperation', // 运营年数
          'employeesNum', // 全球员工数量
          'operateAddress', // 经营办公地址
          'enOperateAddress',
          'businessCharge', // 中国市场主要联系人(姓名)
          'enBusinessCharge',
          'businessChargePhone', // 中国市场主要联系人(电话)
          'businessChargeEmail', // 中国市场主要联系人(邮箱)
          'socialAccountInstagram', // 社交账号 Instagram
          'socialAccountTwitter', // 社交账号 Twitter
          'socialAccountWeibo', // 社交账号 Weibo
          'socialAccountWechat', // 社交账号 Wechat
          'socialAccountWechatAccount', // 社交账号 WechatAccount
          'socialAccountOther' // 社交账号 Other
        ]

        // 国内业务是否覆盖全国
        if (state.adminInfo.businessCoverageThroughoutTheCountry === null) {
          emptyCount++;
        }
        // 国内业务主要市场
        if (parseInt(state.adminInfo.businessCoverageThroughoutTheCountry) === 2) {
          if (isEmpty(state.adminInfo.businessMainMarkets)) {
            emptyCount++
          }
          if (isEmpty(state.adminInfo.enBusinessMainMarkets)) {
            emptyCount++
          }
        }
      } else {
        total = 18
        field = [
          'userName', // 名称
          'enUserName',
          'intro', // 简介
          'enIntro',
          'labels', // 标签
          'operateAddress', // 经营办公地址
          'enOperateAddress',
          'businessCharge', // 中国市场主要联系人(姓名)
          'enBusinessCharge',
          'businessChargePhone', // 中国市场主要联系人(电话)
          'businessChargeEmail', // 中国市场主要联系人(邮箱)
          'officialWebsite', // 官网地址
          'socialAccountInstagram', // 社交账号 Instagram
          'socialAccountTwitter', // 社交账号 Twitter
          'socialAccountWeibo', // 社交账号 Weibo
          'socialAccountWechat', // 社交账号 Wechat
          'socialAccountWechatAccount', // 社交账号 WechatAccount
          'socialAccountOther' // 社交账号 Other
        ]
      }

      const empty = value => isNull(value) || isUndefined(value) || value === '' || (value instanceof Object && Object.keys(value).length === 0)

      emptyCount += field.filter(key => {
        if (key.includes('|')) {
          const keys = key.split('|')

          return empty(state.adminInfo[keys[0]]) && empty(state.adminInfo[keys[1]])
        }

        return empty(state.adminInfo[key])
      }).length

      return (((total - emptyCount) / total) * 100).toFixed(2)
    }
  },
  actions: {
    logout() {
      this.setToken('')
      this.setUserInfo({})
      this.memberLog = []
      this.unreadTotal = 0
      router.push('/')
    },
    setUnreadTotal(total) {
      this.unreadTotal = total
    },
    setToken(token) {
      this.token = token
    },
    setUserInfo(info) {
      this.adminInfo = info
    },
    setLanguage(value, reload = true) {
      this.language = value

      localStorage.setItem('language', value)

      // 当有登录时, 记录用户语言
      if (Object.keys(this.adminInfo).length !== 0) {
        api.admin.setLanguage().finally(() => reload && window.location.reload())
      } else if (reload) {
        window.location.reload()
      }
    },
    setPermission(value) {
      this.permission = value
    },
    setIdentityList(data) {
      this.identityList = data
    },
    setAllIdentityList(data) {
      this.allIdentityList = data
    },
    isNotLogin() {
      return this.token === ''
    },
    /**
     * 是 个人.
     * @returns {boolean}
     */
    isPersonal() {
      return parseInt(this.adminInfo?.memberDictionaryType) === 1
    },
    /**
     * 判断个人是否企业已授权.
     * @returns {boolean}
     */
    personalHasAuth() {
      if (!this.isPersonal()) {
        return false
      }

      // 企业授权 && 企业是会员
      return parseInt(this.adminInfo?.staff?.isAuthorization) === 1 && this.isEnterpriseMembership(this.adminInfo.staff?.enterprise)
    },
    /**
     * 没权益
     */
    noEquity() {
      return (
        (this.isPersonal() && !this.personalHasAuth()) || // 个人且没有会员企业授权
        (this.isEnterprise() && this.unPaid()) || // 不是会员企业
        (this.isAssociation() && (this.isAssociationNeedReview() || this.unPaid())) // 协会还没有通过审核 / 没会员
      )
    },
    /**
     * 有权益
     * @returns {boolean|*}
     */
    hasEquity() {
      return (
        this.personalHasAuth() || // 个人且有会员企业授权
        this.isEnterpriseMembership() || // 是会员企业
        this.isAssociationMemberShip() // 协会已通过审核 && 会员有效期内
      )
    },
    /**
     * 是 企业
     * @returns {boolean}
     */
    isEnterprise(enterprise = null) {
      const ep = enterprise || this.adminInfo

      return parseInt(ep.memberDictionaryType) === 2
    },
    /**
     * 是 (有效) 企业会员
     * @returns {*}
     */
    isEnterpriseMembership(enterprise = null) {
      const ep = enterprise || this.adminInfo

      return this.isEnterprise(ep) && parseInt(ep.type) === 2 && this.memberShipWithinTheValidityPeriod(ep)
    },
    /**
     * 会员有效期内
     */
    memberShipWithinTheValidityPeriod(enterprise = null) {
      const ep = enterprise || this.adminInfo

      return ep.periodOfValidity && new Date(ep.periodOfValidity) > new Date()
    },
    /**
     * 未付费的企业 且没有用过 折扣码
     * @returns {boolean}
     */
    enterpriseHasTry() {
      return this.unPaid() && !this.adminInfo.isUsedPromotionCode
    },
    /**
     * 未付费
     */
    unPaid() {
      return parseInt(this.adminInfo.type) === 0
    },
    /**
     * 是 协会
     * @param needPass 且通过
     * @returns {boolean}
     */
    isAssociation(needPass = false) {
      // 用户详情接口返回的是驼峰, 注册完后返回的是下划线, 这里兼容下
      const isAssociation = parseInt(this.adminInfo?.memberDictionaryType || this.adminInfo?.member_dictionary_type) === 3
      const pass = parseInt(this.adminInfo?.auditStatus || this.adminInfo?.audit_status) === 2

      return isAssociation && (!needPass || (needPass && pass))
    },
    /**
     * 是 (有效的) 协会会员
     */
    isAssociationMemberShip() {
      return this.isAssociation(true) && this.memberShipWithinTheValidityPeriod()
    },
    /**
     * 是协会, 且审核被拒绝
     */
    isAssociationReject() {
      return this.isAssociation() && parseInt(this.adminInfo?.auditStatus) === 3
    },
    /**
     * 是协会, 且审核还未通过
     */
    isAssociationNeedReview() {
      return this.isAssociation() && parseInt(this.adminInfo?.auditStatus) !== 2
    },
    /**
     * 是协会, 且审核在审核中
     */
    isAssociationUnderReview() {
      return this.isAssociation() && parseInt(this.adminInfo?.auditStatus) === 1
    },
    /**
     * 获取订单列表.
     */
    getOrderList() {
      if (this.isPersonal()) {
        return
      }

      api.admin.orderList().then(res => {
        this.memberLog = res.data.rows
      })
    },
    /**
     * 获取用户名称 (根据中英文环境选择)
     * @param item 用户信息对象
     */
    getName(item) {
      if (!item) {
        return ''
      }

      if (this.language === 'en') {
        return item.enUserName || item.userName
      }

      return item.userName || item.enUserName
    },
    /**
     * 根据中英文环境获取值
     * @param item
     * @param key
     * @returns {*|string}
     */
    valueByLanguage(item, key) {
      if (!item) {
        return ''
      }

      const ucFirst = key.charAt(0).toUpperCase() + key.slice(1)

      if (this.language === 'en') {
        return (item[`en${ucFirst}`] || item[`en_${key}`]) || item[key]
      }

      return item[key] || (item[`en${ucFirst}`] || item[`en_${key}`])
    }
  },
  persist: true
})
