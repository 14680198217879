// 企业资料编辑页
const enterpriseInfo = () => import('@/views/enterprise/enterpriseInfo.vue')
// 基础信息
const basicsInfo = () => import('@/views/enterprise/info/basicsInfo.vue')
// 更多资料
const moreData = () => import('@/views/enterprise/info/moreData.vue')
// 产品
const product = () => import('@/views/enterprise/info/product.vue')
const moreInfo = () => import('@/views/enterprise/info/moreInfo.vue')
const publicityPicture = () => import('@/views/enterprise/info/publicityPicture.vue')
const publicityDocument = () => import('@/views/enterprise/info/publicityDocument.vue')
const publicityArticle = () => import('@/views/enterprise/info/publicityArticle.vue')

const enterprise = [
  {
    name: 'enterpriseInfo',
    path: '/enterpriseInfo',
    component: enterpriseInfo,
    redirect: '/enterpriseInfo/basicsInfo',
    children: [
      {
        path: '/enterpriseInfo/basicsInfo',
        name: 'basicsInfo',
        component: basicsInfo
      },
      {
        path: '/enterpriseInfo/moreData',
        name: 'moreData',
        component: moreData
      },
      {
        path: '/enterpriseInfo/product',
        name: 'product',
        component: product
      },
      {
        path: '/enterpriseInfo/moreInfo',
        name: 'moreInfo',
        component: moreInfo
      },
      {
        path: '/enterpriseInfo/publicityPicture',
        name: 'publicityPicture',
        component: publicityPicture
      },
      {
        path: '/enterpriseInfo/publicityDocument',
        name: 'publicityDocument',
        component: publicityDocument
      },
      {
        path: '/enterpriseInfo/publicityArticle',
        name: 'publicityArticle',
        component: publicityArticle
      }
    ]
  }
]

export default enterprise
