import axios from '@/request/http'

const visitShanghai = {
  getArticleList(params) {
    return axios.get(`/api/visit-shanghai/article`, { params })
  },
  getArticle(id, lang) {
    return axios.get(`/api/visit-shanghai/article/${id}`, { params: {lang} })
  },
  apply(params) {
    return axios.post(`/api/visit-shanghai/apply`, params)
  }
}

export default visitShanghai
