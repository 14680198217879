export default {
  membershipFee: '企业会员的年度会员费为15800港币。',
  dmoFirstYearFree: '目的地管理机构和协会会员第一年免费。',
  renewManuallyAfterOneYear: '会员期限为自注册之日起的一年，一年期限结束后需手动续费。',

  /**
   * 以下是之前表里的数据
   */
  membercontent: '即刻加入我们。您将发现与全球旅游行业合作伙伴建立全新联系的方法，并找到优质可靠的服务合作方。',
  memberBenefits: ' 会员权益',
  memberBenefitsSummary: '为了促进您的旅游业务，并最大限度地降低您与新合作伙伴开展业务的风险，正信为会员提供以下会员服务：',
  memberone: '1.对合作企业的服务评价权， 并可以得到优质服务供应商以便建立项目合作、业务交流',
  membertwo: '2. 参加正信所组织的研讨会、目的地实地考察活动，专家培训等等',
  memberthree: '3. 免费获取行业动态信息，包括行业年度深度报告等；',
  membertfour: '4. 享有使用平台标志的权利；',
  membertfive: '5. 可以参与平台举办的论坛、沙龙等活动，促进会员交流合作；',
  membertsix: '6. 利用平台，宣传会员单位的公益活动或优质项目',
  membertseven: '7. 对平台的工作和负责人提出建议、质询、批评和监督；',
  memberteight: '8. 当遇到重大困难或合法权益受到侵害时，有请求提供帮助（如涉及调解、诉讼等）的权利；',
  membertnine: '9. 对不正当行业竞争有举证并举报权；',
  membertten: '10. 入会自愿，退会自由。',
  memberObligationsSummary: '会员义务摘要',
  memberteleven: '(一) 遵守本会章程，执行平台决议，维护平台的声誉和本会的合法权益；',
  memberttwelve: '(二) 完成平台交办的工作，积极参加并支持平台组织的活动；',
  membertthirteen: '(三) 按规定缴纳会费；',
  membertfourteen: '(四) 接受本会的评议和调解；',
  'reportingSituations,ProvidingInformation,AndRelevantMaterialsToTheAssociation.': '(五) 向本会反映情况，提供信息和有关资料；',
  membersixteen: '(六) 合法经营，遵守平台建议的行规行约等规范；',
  memberseventeen: '(七) 不组织、不参与有损平台和其他会员的一切活动；',
  membershipRequirements: '入会要求',
  membereighteen: '(一) 有加入本平台的意愿；',
  membernineteen: '(二) 在中国境内依法设立，守法经营，无不良信誉的旅行社、为旅行社提供业务服务的企事业单位、与旅行社相关的社会团体等；',
  membertwenty: '(三) 接待上海跨境游客的旅行社，租车公司， 酒店，航空公司， 邮轮公司，餐厅，景点等等，有正规运营资质，有效保险等；',
  membershipApplicationProcedure: '入会程序',
  membertwentyone: '(一) 提交入会申请书；',
  membertwentytwo: '(二) 提交有关证明材料，包括:',
  membertwentythree: "1. 营业执照(或社团登记证)复印件<br/>2. 组织机构代码证复印件<br/>3. 有效保险<br/>4. 行业主管部门审批或行政许可文件",
  membertwentyfive: '(三) 由理事会或者常务理事会授权的机构讨论通过；',
  membertwentysix: '(四) 由本平台理事会或理事会授权的机构颁发电子会员证，并予以公告；',
  membertwentyseven: '会员如有违反法律法规和本章程的行为，经理事会或者常务理事会表决通过，给予下列处理:',
  warningNotification: '(一) 通知警告；',
  suspensionOfMemberRights: '(二) 暂停行使会员权利；',
  expulsion: '(三) 除名；',
  listingInTheIndustryBlacklist: '(四) 列入行业黑名单；',
  membertwentyeight: '会员退会须书面通知本平台后收回电子会员证。如会员有下列情形之一的，自动丧失会员资格:',
  membertwentynine: '(一) 2年不按规定交纳会费；',
  memberthirty: '(二) 2年不按要求参加本平台活动；',
  memberthirtyone: '(三) 不再符合会员条件；',
  lossOfCivilCapacity: '(四) 丧失民事行为能力；',
  memberthirtytwo: '(五) 会员单位法人代表被剥夺政治权利；',
  memberthirtythree: '会员退会、自动丧失会员资格或者被除名后，其在本平台相应的职务、权利、义务自行终止。本平台置备会员名册，对会员情况进行记载。会员情况发生变动的，承诺及时修改会员名册，并向会员公告。',
  selectionOfExcellentMerchants: '优质商户评选',
  'toMeetTheEvaluationCriteriaForTheExcellentTourismMerchantProgram,YourCompanyMustEnsureThatItsManagementStrategiesContributeToProvidingHigh-qualityServices.IfYourCompanyOperatesChainStoresOrFranchisesInTheTourismIndustry,TheseStrategiesMustBeAppliedConsistentlyAcrossAllAssetsUnderYourCompany.DuringTheEvaluationProcess,YouWillBeScoredBasedOnTheExtentOfImplementingExcellentServices.Therefore,WhenApplyingForTheExcellentTourismMerchantProgram,YouNeedToSubmitAListOfEvaluationCriteria.ApplicantsShouldProvideAnOverallScoreForTheirManagementStrategiesAndTheExtentOfTheirApplicationInTheBusiness.': '为符合“优质旅游商户”计划的评审规则，贵企业/商户必须确保有关管理策略有助提供优质服务。如果贵企业/商户经营的是连锁店或旅游特许经营业务，则有关策略同时在贵企业/商户旗下所有资产适用并实施。在评审流程中，阁下应就实施优良服务的程度进行评分，所以，贵企业/商户申请加入“优质旅游商户”计划时，需要提交一份评审规则列表。申请企业/商户应就本身管理策略及该策略在业务上应用的程度综合评分。',
  'theSelectionCriteriaAndProcessWillBeImplementedBasedOnTheEvaluationGuidelinesAndElementsSpecificToVariousTourism-relatedIndustries.MeetingTheStandardsForAnExcellentMerchantWillQualifyYourCompanyAsAnExcellentTourismMerchantForOneYear.TheProgramWillBeEvaluatedAnnually.': '评选标准及流程将依据旅游相关各行业的评选准则和评选要素进行实施，分值达到优质商户标准将定义为优质旅游商户，有效期为一年。该项目将每年评定一次。',
  'exclusiveBenefitsForExcellentMerchants:DiscountOnMembershipRenewalFees.': '优质商户专享权益：会员续费折扣。',
  'iWouldLikeToParticipateInTheSelectionProcess.': '我想参加评选',
  'toProvideHigh-qualityServicesToDifferentTypesOfEnterprises/institutions,WeHaveDifferentMembershipFeesForJoiningTheAdvancedEnterpriseMembershipBasedOnTheTypeOfCompany.IfYourCompanyIsReadyToJoinOurAdvancedMembership,PleaseSelectYourCompanyTypeBelowToViewOurPricing.IfYouHaveAnyQuestions,PleaseContactUs!': '为了更好的给不同类型的企业/机构提供优质的服务，我们对不同类型的企业加入高级企业会员的收费标准不尽相同。如贵司准备好加入我们的高级会员行列，请在下方选择贵司的行业类型查看我们的收费标准。如有疑问可联系我们！',
  pleaseSelectTheCompanyType: '请选择行业类型',
  pleaseSelect: '请选择',
  'selectionCannotBeChangedAfterSaving.IfYouHaveSelectedTheWrongCompanyType,PleaseContactCustomerService.': '选择保存后不能更改。若行业类型选择有误请 联系客服',
  viewPricesAfterSelectingTheCompanyType: '选择行业类型后查看价格',
  customizeAndPurchaseAdvancedEnterpriseMembership: ' 高级企业会员定制购买',
  "thePriceForYourCompany'sAdvancedEnterpriseMembershipIs": '贵企业高级企业会员价格为',
  orderServices: ' 订购服务',
  advancedEnterpriseMembership: '高级企业会员',
  serviceDuration: '服务期限',
  orderingCompany: '订购企业',
  orderPrice: '订单价格',
  weChatPay: ' 微信支付',
  alipay: '支付宝支付',
  bankTransfer: ' 银行转帐',
  'afterCompletingThePayment,PleaseUploadThePaymentReceiptOrScreenshot.': '完成付款后，请上传付款图片回执或截图',
  bankAccount: '银行账户',
  bankName: '开户银行',
  transferRemarks: '转账备注',
  paymentAmount: '转账金额',
  paymentReceipt: '付款回执',
  'pleaseUploadThePaymentReceiptImageOrScreenshot.SupportsJpg,Png.': '请上传付款回执图片或截图，支持 jpg、jpeg 和 png',
  submit: '提交',
  membershipprogram: '会员计划',
  membertfifteen: '(五) 向本会反映情况，提供信息和有关资料；',
  memberthirtyfour: '优质会员加入要求和标准：（准入标准）',
  generalrulesforimplementation: '实施总则',
  memberthirtyfive: '平台本着为同业和消费者提供：更优质的行业标准，更好的服务，更好的旅游体验，更安全的出行， 推广旅游保险意识，增加跨境旅游交易资金监管和安全，减少恶性旅游事故，减少投诉纠纷等为目标， 遴选世界各旅游目的地或旅游城市能够为中国游客提供优质服务的各类旅游供应商，推荐给中国旅游者和旅行社；帮助和引导中国旅游者识别和选择世界各国的优质旅游服务供应商，以便获得放心舒适的、高品质的旅行体验；在中国市场积极推广各目的地的优质旅游供应商，帮助中国出境旅游组团社和代理商选择优质供应商的服务和产品，以便为旅游者提供更多高品质、多元化、个性化的旅游产品。同时， 也为各地的旅游服务供应商连接更多更好的旅游合作企业搭建良好的供求关系。',
  memberthirtysix: '平台将根据会员之间合作后的互相评分， 来进行年度甄选， 推出年度优质服务名单， 以对提供优质服务的企业表示肯定。对不符合优质服务标准的企业进行核实后列入失信名单， 以避免其他合作方产生额外的损失。',
  memberthirtyseven: '为符合“优质旅游服务会员”计划的标准，贵企业/商户必须确保有关管理策略有助提供优质服务。如果贵企业/商户经营的是连锁店或旅游特许经营业务，则有关策略同时在贵企业/商户旗下所有资产适用并实施。在评审流程中，阁下应就实施优良服务的程度进行评分，所以，贵企业/商户申请加入“优质旅游商户”计划时，需要提交一份评审规则列表。申请企业/商户应就本身管理策略及该策略在业务上应用的程度综合评分。',
  memberthirtyeight: '评选标准及流程将依据旅游相关各行业的评选准则和评选要素进行实施，分值达到优质商户标准将定义为优质旅游商户，有效期为一年。该项目将每年评定一次。',
  referencestandard: '参考标准',
  memberthirtynine: '评选标准及流程将依据旅游相关各行业的评选准则和评选要素进行实施，分值达到优质商户标准将定义为优质旅游商户，有效期为一年。该项目将每年评定一次。',
  memberfortyTitile: '参照相关国家/行业标准为主，以下包括但不仅限于：',
  memberforty: '(一) 行程安排合理：旅行社应该设计出行程计划，考虑到游客的兴趣和需求，并根据当地的情况进行调整。同时，行程中的景点和活动需要安排得合理充实，不过分压缩时间。',
  memberfortyone: '(二) 服务态度优秀：旅行社员工应该具备专业知识和良好的服务态度，并积极解答游客的问题和提供帮助。在旅行途中，他们应该关注游客的安全和健康状况，并进行必要的安排。',
  memberfortytwo: '(三) 餐食住宿舒适：旅行社应该提供舒适的餐食和住宿环境，以满足游客的基本需求。酒店的品质应该与旅游产品的价格相匹配，并符合当地标准。',
  memberfortythree: '(四) 导游服务专业：导游应该具备专业的知识和技能，能够为游客提供准确、详细的讲解和指引，并协助游客处理相关问题。导游还应该了解当地文化和风俗，为游客提供一些有用的建议。',
  memberfortyfour: '(五) 解决问题及时：旅行社应该在发现问题时及时进行处理，并向游客做出解释和补偿。对于游客的反馈，旅行社应该认真对待，并采取措施改进服务质量。',
  memberfortyfive: '(六) 其他特殊要求：对于一些特殊的旅游类型，例如探险、登山等，旅行社需要提供相关的保障和安全措施。此外，旅行社也需要关注环境保护和社会责任等方面。',
  memberfortysix: '(七) 诚信服务，不在交易过程中采取虚假宣传等不正当竞争行为， 不扰乱市场交易的正常秩序。',
  memberfortyseven: '我想参加评选',
  memberforunionpay: '使用银联（Unionpay）提供的支付保障系统结算',
  memberforunionpaytext: '在组团社以及地接社双方明确了解并同意的前提下，选择正信平台的合同交易将由银联（Unionpay）提供第三方支付保障服务进行结算。所有参与方都默认并同意以下结算条款。',
  memberforunionpaylist: '组团社和地接社同意按照以下方式进行结算：',
  memberforunionpaylist1: '1. 保障账户设立：',
  'memberforunionpaylist1-1': '为了确保旅行费用的安全支付，我们建议在旅行团出发前7天内（可以随时根据需求调整），将合同中所需支付的费用汇入组团社开设的带有银联标志的指定保障账户，并授权银联对这些款项进行冻结与管理。在付款给地接社之前，银联会确保这些资金的安全。一旦款项成功汇入银联的指定账户，银联会向地接社发送书面通知，确认旅行费用已到达银联的保障账户。',
  memberforunionpaylist2: '2. 旅费代付流程：',
  'memberforunionpaylist2-1': '在旅行团完成旅行后，银联将自动冻结该笔旅行费用30天。如果在此期间确认双方没有任何纠纷，且没有对款项提出异议，则在冻结期结束后，款项将自动转入地接社指定的账户。',
  memberforunionpaylist3: '3. 投诉及纠纷处理：',
  'memberforunionpaylist3-1': '如果在旅行结束后的30天内出现任何旅游投诉或纠纷，银联将暂时冻结该笔旅行费用，直到双方达成一致解决方案为止。',
  /**
   * 表里数据结束
   */
}
