export default {
  // 新的 services 条目
  attachYourMembershipID: '当您通过电子邮件联系正信时，请在主题行中附上您的会员ID。',
  serviceTips: '点击下面任何一个标题查看更多详情。',
  serviceMainEntry1: '对接优质中国出入境旅游合作伙伴',
  serviceMainEntry2: '将旅游业务风险降至最低',
  serviceMainEntry3: '旅游业务拓展',
  serviceMainEntry4: '优化中国旅业战略',
  serviceMainEntry5: '战略合作伙伴',
  // serviceEntry1: '会员搜索功能',
  serviceEntry1: '会员配对',
  // serviceEntryContent1: "正信为会员提供强大的搜索引擎，帮助您寻找新的中国出入境旅游合作伙伴，或通过理想的新合作伙伴拓展现有的中国业务。可用的搜索格式包括关键字搜索、地区搜索、按合作伙伴类型搜索（如入境或出境中国旅行社）。我们还将为您指派一名正信的会员专属客户经理，协助您寻找理想的中国旅游业合作伙伴。使用正信的搜索引擎来搜索符合您要求的会员或通过 <a href='mailto:membermatching{'@'}smoothtravel.com'>membermatching{'@'}smoothtravel.com</a> 联系您的会员专属客户经理。",
  serviceEntryContent1: "正信为会员提供专业的配对服务，帮助您寻找新的中国出入境旅游合作伙伴，或通过理想的新合作伙伴拓展现有的中国业务。我们将为您指派一名经验丰富的正信会员专属客户经理，并根据您的要求和偏好推荐潜在合作对象名单，全程一对一协助您寻找理想的合作伙伴并建立联系。通过 <a href='mailto:membermatching{'@'}smoothtravel.com'>Membermatching{'@'}smoothtravel.com</a> 联系您的会员专属客户经理。",
  "serviceEntryContent1-1": "点击<a href='/#/Search' style='color: #0000ff; text-decoration: underline;'>此处</a>进入正信强大的搜索引擎，全天候通过关键词、地理位置、行业类型来搜索潜在合作伙伴，并一站式在站内与潜在合作伙伴建立联系。",
  serviceEntry2: '市场材料翻译服务',
  serviceEntryContent2: "正信的会员专属客户经理将帮助您评估您的市场材料是否适合在海外或中国市场使用，以吸引旅业同仁并建立新的合作伙伴关系。如有需要，正信可利用专门为中国出入境旅游市场设计新的市场资料的翻译团队，以低成本提供翻译服务支持。确保您的市场材料和在线内容的翻译准确且符合相关国家的文化，以满足旅游市场的需求和细微差别，将是促进您旅游业务发展的关键。如需免费评估您线上或线下的市场材料，请通过 <a href='mailto:memberengagement{'@'}smoothtravel.com'>Memberengagement{'@'}smoothtravel.com</a> 联系您的会员专属客户经理。",
  serviceEntry3: '会员信息互享',
  // serviceEntryContent3: "在选定潜在目标旅游合作伙伴后，会员可在线访问合作伙伴提供的资料。通过我们的用户界面，会员可以轻松浏览全球会员发布的大量中英文资料，例如详细的公司简介、产品目录、营销介绍、业务状况等。此外，会员还可以查看客户评价和案例研究，以便决定是否与目标旅游合作伙伴开展对话并建立潜在合作关系。",
  serviceEntryContent3: "在选定潜在目标旅游合作伙伴后，会员可在线访问合作伙伴提供的资料。通过我们的用户界面，会员可以轻松浏览全球会员发布的大量中英文资料，例如详细的公司简介、产品目录、营销介绍、业务状况等。此外，会员还可以查看客户评价和案例研究，以便决定是否与目标旅游合作伙伴开展对话并建立潜在合作关系。如果您希望我们为您免费评估销售材料，请将材料发送至 <a href='mailto:memberengagement{'@'}smoothtravel.com'>Memberengagement{'@'}smoothtravel.com</a>。",
  serviceEntry4: '正信会员专属客户经理',
  serviceEntryContent4: "作为正信的会员，您将被指派一名会员专属客户经理，他将带领您完成所需流程，并协助您，确保您能够在旅游行业建立新的合作伙伴关系。若如需联系您的会员专属客户经理，请联系 <a href='mailto:memberengagement{'@'}smoothtravel.com'>Memberengagement{'@'}smoothtravel.com</a>",
  serviceEntry5: '支付担保系统',
  serviceEntryContent5: "为了最大限度地降低与新的旅业合作伙伴打交道时的支付风险，正信携手银联（<span id='609135372051537921' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>详见简介</span>），为会员提供独特的支付担保系统，弥补了中外旅游业界支付习惯的差异。银联的支付担保系统将有效地消除了在中国和海外的所有支付风险。该服务将在2024年7/8月正式上线。",
  serviceEntry6: '双语争议仲裁',
  serviceEntryContent6: "如果海外会员与中国会员之间发生任何争议，正信与上海市政府的专业旅游争议解决中心（<span id='603523492931014656' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>详见简介</span>）合作，由专门解决跨境旅游争议的双语仲裁员，以独特的优势为任何一方提供可靠的仲裁服务，无需支付任何费用，并能消除文化和语言差异，公平有效地解决任何冲突。要访问正信的争议仲裁平台，请发送电子邮件至 <a href='mailto:arbitration{'@'}smoothtravel.com'>Arbitration{'@'}smoothtravel.com</a>。",
  serviceEntry7: '旅游保险',
  serviceEntryContent7: "正信与中国太平洋保险（CPIC）（<span id='534690179236065280' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>详见简介</span>）合作开发了中国首个专为游客量身定制的旅游综合保险，为中国游客、中国出入境旅游行业和海外合作伙伴的利益提供安全保障。一旦发生意外，大部分费用都将得到保障，从而降低中国会员和海外会员的经济风险。如需投保，请<a href='%s' target='_blank' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>点击此处</a>。欲了解更多有关正信与太平洋保险合作的保险计划，请联系 <a href='mailto:insurance{'@'}smoothtravel.com'>Insurance{'@'}smoothtravel.com</a>。",
  serviceEntry8: '会员评价/反馈',
  serviceEntryContent8: "正信携手上海市旅游质量监测服务中心（<span id='603523492931014656' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>详见简介</span>），以提高中国与世界各国之间的跨境旅游服务质量，并让海内外优质会员与全球旅游行业的佼佼者携手合作为最终目标。正信给会员提供互惠的反馈机制，推动旅游业服务的持续改进，确保中国旅游业界最优质的企业与合适的海内外会员合作，并提升旅游产品的整体质量。如需对会员提供反馈意见，请联系 <a href='mailto:feedback{'@'}smoothtravel.com'>Feedback{'@'}smoothtravel.com</a>。",
  serviceEntry9: '优质服务榜单',
  serviceEntryContent9: "正信与上海市政府将每年发布一份全球旅游业各个领域的优秀企业名单，该榜单由会员推荐和评选得出，以表彰其卓越的服务质量。该榜单将评估企业的服务质量、专业能力和客户满意度。同时还将根据会员的评价，展示优质出境和入境旅行社。若需查看当前的优质服务榜单，请点击<a href='/#/rankinglist' target='_blank' style='color: #0000ff; text-decoration: underline;'>此处</a>。",
  serviceEntry10: '专家培训',
  serviceEntryContent10: "正信提供全面的双向培训平台，为海外会员和中国会员开设了专门的培训课程。海外会员可利用该平台培训中国旅游业界合作伙伴，使其成为目的地、服务或产品方面的专家。同样，正信也为中国会员提供平台，培训海外旅游业界合作伙伴成为接待中国入境游客的专家。如需了解正信的培训选项或为旅游业界制定自己的培训计划，并了解成本结构，请联系 <a href='mailto:chinatraining{'@'}smoothtravel.com'>Chinatraining{'@'}smoothtravel.com</a>",
  serviceEntry11: '针对中国出入境旅游业的活动推广',
  serviceEntryContent11: "会员可通过正信来组织针对旅游业界的推广活动，包括培训、研讨会、路演、旅业展会和相关旅游产品发布会。若要向旅游业界推广您的活动，请联系 <a href='mailto:chinaevents{'@'}smoothtravel.com'>Chinaevents{'@'}smoothtravel.com</a>",
  serviceEntry12: '正信标志使用授权',
  serviceEntryContent12: "正信是上海市政府的官方旅游网站，将华东地区的旅游业与世界各地的旅游行业实体联系在一起。正信会员可在其非商业用途的旅游行业市场材料上使用由上海市政府批准的标志。请知悉，正信有权随时撤回此使用权。",
  serviceEntry13: '旅游消费者和旅游行业动态',
  serviceEntryContent13: "正信利用广泛的政府和行业资源，开发最新的中国出入境旅游市场研究、行业趋势以及消费者行为和偏好。正信通过调查、深度访谈、焦点小组讨论、研讨会和实地考察等方式，对中国出入境旅游市场的现状和中国出入境旅游者的看法进行更新。我们的目标是让您深入了解中国出入境旅游市场的现状，提供数据和分析，帮助您充分利用中国乃至全球市场的需求、变化和动态。",
  serviceEntryContent13Add: "若要获取最新的出入境游市场和消费者洞察，请点击<a href='/#/ChinaTourismMarketAndConsumerInsights' target='_blank' style='color: #0000ff; text-decoration: underline;'>此处</a>。",
  serviceEntry14: '专属旅游市场调研方案',
  serviceEntryContent14: "正信的会员可根据需要，委托定制专属的旅游市场调研（费用自理）。定制旅游市场调研可包括品牌知名度调查、市场份额分析、主要市场参与者识别、产品渠道分析和建议、竞争对手分析、消费者细分识别、价格分析、战略规划和数据分析等。我们的最终目标是帮助您抓住旅游市场机遇，制定有针对性的市场营销战略，并协助您在旅游市场寻找合适的合作伙伴。若要定制会员专属的正信旅游市场调研，请联系 <a href='mailto:research{'@'}smoothtravel.com'>Research{'@'}smoothtravel.com</a>",

  viewWebsite: '查看网站',

  /**
   * 服务条例
   */
  termsOfService: '服务条例',
  acceptanceOfTerms: '服务条款的接受',
  acceptanceOfTermsOne: '无论是作为访客还是注册用户,本服务条款由您和www.正信.com（“正信”，“我们”或“我们的”）共同签署,包括以下条款和条件，连同任何明确引用的文档（统称为“服务条款”），管理您访问和使用www.正信.com及相关域名，包括通过www.正信.com提供的任何内容、功能和服务（“网站”）。',
  acceptanceOfTermsTwo: '在开始使用网站之前，请仔细阅读服务条款。当您使用网站或在可用时点击接受或同意服务条款时，即表示您接受并同意受这些服务条款的约束。如果您不想同意这些服务条款，则不能访问或使用网站。',
  acceptanceOfTermsThree: '本网站提供给年满18岁或您所在居住地区的适用法定年龄以上的用户使用。通过使用本网站，您声明并保证您已达到与正信签订具有约束力合同的法定年龄。如果您不符合此要求，则不能访问或使用本网站。',

  softwareLicenseAndUserServiceAgreement: '正信平台软件许可及用户服务协议',
  softwareLicenseAndUserServiceAgreementOne: '【审慎阅读】您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。重点条款将以粗体标识，您应仔细阅读。 如您对协议有任何疑问，可通过本协议中提供的联系方式咨询。',
  softwareLicenseAndUserServiceAgreementTwo: '【签约认可】当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，成为正信平台的用户。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。',
  softwareLicenseAndUserServiceAgreementThree: '【服务协议的确认和接纳】正信由上海文旅质量服务与监测中心指导，德启（上海）管理咨询有限公司运营，涉及具体的产品及/或服务（以下简称“产品/服务”），则由有资质的服务商提供。如果用户（以下或称“您”）访问正信平台，使用正信平台的产品或服务，便视为您接受了《正信平台用户服务协议》（以下简称“本协议”）。请您仔细阅读以下内容，尤其是以下加粗字体。如果您不同意以下任何内容，请立刻停止访问正信平台/使用正信平台所提供的任何产品或服务。',

  generalProvisions: '1、总则',
  generalProvisionsOne: '预订须知、旅游合同、账户注销协议等其他协议（以下简称“其他条款”）。如果本协议与“其他条款”有不一致之处，则以“其他条款”为准。除另行明确声明的情形之外，任何正信平台提供的产品/服务均受本协议约束。',
  generalProvisionsTwo: '本协议中，“用户”指访问或使用正信平台所提供的产品/服务的公司/企业，包括但不限于注册用户及未注册用户。凡未注册正信平台的产品/服务的用户，自开始使用正信平台的产品及/或服务时即成为我们的“非注册用户”，在使用过程中须遵循本协议中除注册用户专属约定以外的其他所有条款。预订须知、旅游合同、账户注销协议等其他协议（以下简称“其他条款”）。如果本协议与“其他条款”有不一致之处，则以“其他条款”为准。除另行明确声明的情形之外，任何正信平台提供的产品/服务均受本协议约束。',
  generalProvisionsThree: '若您作为正信或合作公司的用户访问正信平台或使用正信产品/服务，即视为您同意本协议的所有条款及正信平台公布的其他条款、规则、说明和操作指引。',

  serviceNotices: '2、服务须知',
  serviceNoticesOne: '正信平台运用自己的操作系统通过国际互联网络为用户提供各类产品/服务。您必须：',
  serviceNoticesTwo: '（1）自行配置必要的上网设备，包括PC、手机、平板电脑，以及调制解调器、路由器等。',
  serviceNoticesThree: '（2）个人上网和支付与此服务有关的网络费用、电话费用。',
  serviceNoticesFour: '考虑到正信平台提供的产品/服务的重要性，您同意：',
  serviceNoticesFive: '（1）提供真实、详尽及准确的公司/个人资料用于注册正信平台账户，不得使用他人邮箱、手机号码或以其他冒用他人身份的方式进行账号注册。',
  serviceNoticesSix: '（2）在提交注册信息后，如果信息发生变化，您应及时更改。如果您提供的信息不符合合法、真实、准确、详尽的要求，您需承担因此引起的相应责任及后果，并且正信平台保留终止您使用各项产品/服务的权利。',

  modificationOfTheServiceAgreement: '3、服务协议的修改',
  modificationOfTheServiceAgreementOne: '正信平台会在必要时修改本协议及/或各类规则，并将在正信平台相应页面提前公告。具体而言，正信将通过适当的方式在平台上对本协议的修改事宜进行公告或通知，此类通知于公布或发送之日即视为已送达您。',
  modificationOfTheServiceAgreementTwo: '正信承诺，对于本协议及/或各类规则的修改，正信平台应当于变更后的文本生效之日前至少七日进行公告或通知。如您不同意相关变更，应当立即停止访问正信平台或使用正信平台的产品/服务。若您在本协议及/或各类规则变更生效之日后继续使用正信平台的产品/服务的，即表示您接受修订后的文本。',

  serviceChangesInterruptionsAndTermination: '4、服务变更、中断、终止',
  serviceChangesInterruptionsAndTerminationOne: '您完全理解并同意，本服务涉及到互联网及移动通讯等服务，可能会受到各个环节不稳定因素的影响。因此任何因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机、GSM网络、互联网络、通信线路等其他正信平台无法预测或控制的原因，造成服务中断、取消或终止的风险，由此给您带来的损失，正信不承担赔偿责任。',
  serviceChangesInterruptionsAndTerminationTwo: '正信平台需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，如因此类情况而造成网络服务（包括收费网络服务）在合理时间内的中断，正信无需为此承担任何责任，但正信平台应尽可能事先进行通知。',
  serviceChangesInterruptionsAndTerminationThree: '您完全理解并同意，除本服务协议另有规定外，鉴于网络服务的特殊性，正信平台有可能变更、中断或终止部分或全部的网络服务。除了您已经通过正信平台订购或申请的产品/服务并已完成支付并已获得确认订购或申请的情况之外，正信无需为变更、中断或终止服务承担任何责任，但正信应尽可能事先进行通知，并尽可能给您预留时间以便处理相关权益。',

  userCodeOfConduct: '5、用户行为规范',
  userCodeOfConductOne: '您在使用正信平台产品/服务时，必须遵守中华人民共和国相关法律法规的规定，您承诺将不会利用正信平台的产品/服务进行任何违法或不正当的活动，包括但不限于下列行为：',
  userCodeOfConductTwo: '（1）上载、展示、张贴、传播或以其它方式传送含有下列内容之一的信息: ',
  userCodeOfConductThree: '• 反对宪法所确定的基本原则的；',
  userCodeOfConductFour: '• 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；',
  userCodeOfConductFive: '• 损害国家荣誉和利益的； ',
  userCodeOfConductSix: '• 煽动民族仇恨、民族歧视、破坏民族团结的；',
  userCodeOfConductSeven: '• 破坏国家宗教政策，宣扬邪教和封建迷信的；',
  userCodeOfConductEight: '• 散布谣言，扰乱社会秩序，破坏社会稳定的；',
  userCodeOfConductNine: '• 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；',
  userCodeOfConductTen: '• 侮辱或者诽谤他人，侵害他人合法权利的；',
  userCodeOfConductEleven: '• 含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；',
  userCodeOfConductTwelve: '• 含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的。',

  illegal: '（2）为任何非法目的而访问正信平台或使用正信产品/服务，或未经允许，利用正信平台从事以下活动：',
  illegalOne: '• 未经允许，进入计算机信息网络或者非法获取、使用计算机信息网络资源的；',
  illegalTwo: '• 未经允许，对计算机信息网络功能进行删除、修改或者增加的；',
  illegalThree: '• 未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；',
  illegalFour: '• 故意制作、传播计算机病毒等破坏性程序的；',
  illegalFive: '• 其他危害计算机信息网络安全的行为；',
  illegalSix: '• 未经允许，将正信平台上的任何数据作商业性利用，包括但不限于在未经正信事先书面同意的情况下，自行、协助或授权任何第三方以网络爬虫、复制、传播等任何方式获取、使用正信平台上展示的资料；',
  illegalSeven: '• 使用任何装置、软件或例行程序等其他方式干预或试图干预正信平台的正常运作或正在正信平台上进行的任何交易、活动，或采取任何将导致不合理的庞大数据负载加诸正信平台网络设备的行动；',
  illegalEight: '• 从事洗钱、窃取商业秘密、窃取个人信息、违规套现、假冒正信发布任何虚假或诈骗信息等违法违规活动；',
  illegalNine: '• 与网上交易无关的其他行为，例如发布广告信息（得到正信书面许可的除外）；',
  illegalTen: '• 发布任何侵犯正信平台或他人合法权益、违反法律法规及其他规范性文件（包括但不限于《互联网视听节目服务管理规定》、《网络信息内容生态治理规定》等）及本协议的内容。',

  disruptingPlatformTransactionOrder: '（3）扰乱平台交易秩序行为，包括但不限于：',
  disruptingPlatformTransactionOrderOne: '• 超过真实生活消费需求购买服务/产品；',
  disruptingPlatformTransactionOrderTwo: '• 以不正当方式达成交易或参与活动，如使用插件、外挂等工具；',
  disruptingPlatformTransactionOrderThree: '• 违反诚实信用原则的不正当竞争行为，如刷单炒信；',
  disruptingPlatformTransactionOrderFour: '• 恶意下单、恶意维权、虚假交易等。',
  disruptingPlatformTransactionOrderFive: '上述行为的发生，将视为您对本协议的根本违约。在任何情况下，正信平台一旦合理地认为您存在或可能存在上述行为的，可以在任何时候，不经事先通知删除您散布或传播的任何信息，并中止或终止向您提供正信平台的产品/服务。您需对自己在访问正信平台或使用正信平台的产品/服务中的行为承担法律责任。您若在正信平台散布或传播以上违反国家法律法规或公序良俗的信息，正信平台的系统记录将有可能作为您违法犯罪的证据向有关部门提供。',

  iolating: '（4）以任何方式违反任何适用的国家法律或国际法规（包括但不限于涉及向中国或其他国家转移或出口数据、个人可识别信息或软件的法律）。',
  iolatingOne: '• 以暴露未成年人接触不适当内容的方式，利用、伤害或试图利用或伤害未成年人。',
  iolatingTwo: '• 发送、接收、上传、下载、使用或重复使用任何不符合这些服务条款中所规定内容标准的材料。',
  iolatingThree: '• 在未经我们事先书面同意的情况下传输或获取任何广告或促销材料，包括任何“垃圾邮件”、“连锁信”或“垃圾邮件”或任何其他类似要求。',
  iolatingFour: '• 冒充或试图冒充正信、正信员工、其他用户或任何其他个人或实体（包括但不限于使用与前述任何内容相关联的电子邮件地址或屏幕名）。',
  iolatingFive: '• 进行任何虚假、欺诈或投机的预订或预约，或在需求预期的情况下进行任何预订或预约。',
  iolatingSix: '• 从事任何其他限制或阻碍任何人使用或享受本网站的行为，或者根据我们的判断，可能会损害正信或本网站用户或使他们承担责任的行为。',

  agreeNotTo: '此外，您同意不会：',
  agreeNotToOne: '• 以任何可能禁用、超负荷、损坏或损害本网站的方式使用本网站，或干扰任何其他方使用本网站，包括他们通过本网站进行实时活动的能力。',
  agreeNotToTwo: '• 使用任何机器人、蜘蛛或其他自动设备、过程或手段访问本网站，包括监控或复制本网站上的任何材料。',
  agreeNotToThree: '• 使用任何手动过程监视或复制本网站上的任何材料，或未经我们的事先书面同意，用于任何其他未经授权的目的。',
  agreeNotToFour: '• 使用任何设备、软件或程序干扰本网站正常运作。',
  agreeNotToFive: '• 引入任何病毒、木马、蠕虫、逻辑炸弹或其他恶意或技术有害物质。',
  agreeNotToSix: '• 尝试未经授权获取、干扰、损害或中断本网站的任何部分、存储本网站的服务器或连接到本网站的任何服务器、计算机或数据库。',
  agreeNotToSeven: '• 通过拒绝服务攻击或分布式拒绝服务攻击攻击本网站。',
  agreeNotToEight: '• 其他试图干扰本网站正常运作的行为。',

  responsibleFor: '您需要负责：',
  responsibleForOne: '• 为您获得访问本网站所需要的所有安排。',
  responsibleForTwo: '• 确保所有通过您的互联网连接访问本网站的人都知道这些服务条款并遵守它们。',

  requiredTo: '您需要负责：',
  requiredToOne: '• 为您获得访问本网站所需要的所有安排。',
  requiredToTwo: '• 确保所有通过您的互联网连接访问本网站的人都知道这些服务条款并遵守它们。',

  userCodeOfConductThirteen: '为了访问本网站或其提供的某些资源，可能需要要求您提供某些注册详细信息或其他信息。您使用本网站的条件之一是，在本网站上提供的所有信息都是正确、当前和完整的。您同意，您向本网站注册或以其他方式提供的所有信息（包括但不限于通过本网站上的任何交互功能提供的信息）均受我们的隐私政策所管辖，并且您同意我们针对您的信息采取的所有行动都与我们的隐私政策一致。',
  userCodeOfConductFourteen: '如果您选择或被提供用户名称、密码或任何其他安全程序作为我们的安全程序的一部分，则必须将此类信息视为机密信息，并且不得将其透露给任何其他人或实体。您还承认，您的账户是个人的，并同意不向任何其他人提供使用您的用户名、密码或其他安全信息访问本网站或其中任何部分的权限。您同意立即通知我们任何未经授权的使用或对您的用户名或密码的任何其他安全漏洞的访问。您还同意确保在每次会话结束时退出账户。无论是否获得您的许可，任何使用您的账户的行动（包括用户贡献）均由您负责。您应特别谨慎在公共场合或共享计算机上访问您的账户，以确保他人无法查看或记录您的密码或其他个人信息。',
  userCodeOfConductFifteen: '我们有权在自己的唯一决定下随时暂停或终止任何账户和/或禁用任何用户名称、密码或其他标识符，无论是您选择还是我们提供的，包括在我们的意见中，您违反了这些服务条款的任何规定',

  intellectualPropertyRights: '6、知识产权',
  intellectualPropertyRightsOne: '正信在平台提供的相关产品/服务中使用或产生的内容（包括但不限于产品、软件、网页、文字、图片、音频、视频、数据、信息等）的知识产权归正信所有。正信所有和享有的知识产权，不因您的任何使用行为而发生权利转移。未经正信事先书面明确同意，您不得以任何形式对前述内容进行使用或创造相关衍生作品，但如您是作品的权利人、您已获得相关权利人授权或正信与您另有约定的除外。',
  intellectualPropertyRightsTwo: '您承诺，通过正信平台发布上传的内容（包括但不限于文字、图片、视频、音频等各种形式的内容）为原创或已获合法授权，上传内容的知识产权归属于您本人或者相关权利人所有。您通过正信平台上传发布的内容，由您自行承担责任。如您发送或传播的内容违法违规或侵犯他人权利的，正信有权进行独立判断并采取删除、屏蔽或断开链接等措施。因侵犯他人知识产权而引发纠纷的，您须自行承担全部责任。如您的行为给正信造成损失的，正信有权按照法律规定要求您赔偿。',
  intellectualPropertyRightsThree: '您承诺，针对您通过正信平台上传、发布或传输的内容（包括但不限于产品、软件、网页、文字、图片、音频、视频、数据、信息等），您不可撤销地授予正信、或正信指定的第三方一项全球范围内、免费、非独家、可再许可的权利（包括但不限于复制、翻译、汇编、信息网络传播、改编、制作衍生品、表演、展示、放映、摄制、发行的权利，以及与上述权利相关的商业开发、宣传和推广等权利）。正信对有关内容的使用范围包括但不限于在正信平台或正信指定的其他网站、应用程序、产品或终端设备等。',
  intellectualPropertyRightsFour: '您确认并同意授权正信以正信自己的名义或委托专业第三方对侵犯您上传发布的享有知识产权的内容进行代维权，维权形式包括但不限于：监测侵权行为、发送维权函、提起诉讼或仲裁、调解、和解等，正信有权对维权事宜做出决策并独立实施。',
  intellectualPropertyRightsFive: '本网站及其全部内容、功能和特性（包括但不限于所有信息、软件、文本、展示、图像、视频和音频以及设计、选择和排列）归属于正信，其许可人或其他提供此类材料的供应商，并受到中国和国际版权、商标、专利、商业秘密和其他知识产权或专有权法律的保护。',
  intellectualPropertyRightsSix: '这些服务条款允许您按照以下方式使用本网站：如果您代表正信会员代理商（“会员代理商”）或正信优选供应商（“优选供应商”），则可以将网站用于会员代理商和/或优选供应商预期的合法商业用途（统称为“允许使用”）。除非遵循以下规定，否则您不得复制、分发、修改、创建衍生作品、公开展示、公开表演、重新发布、下载、存储或传输我们网站上的任何材料：',
  intellectualPropertyRightsSeven: '• 您可以存储因为网页浏览器自动缓存而用于显示增强的文件。',
  intellectualPropertyRightsEight: '• 您可以打印或下载一份合理数量的页面，仅供允许使用，不得进行进一步复制、出版或分发。',
  intellectualPropertyRightsNine: '• 如果我们提供桌面、移动或其他应用程序供下载，则您可以下载单个副本到计算机或移动设备上，仅限于允许使用，并同意受该应用程序的最终用户许可协议的约束。',
  intellectualPropertyRightsTen: '• 如果我们向某些内容提供社交媒体功能，则您可以采取这些功能所启用的操作。',

  notAllowedTo: '您不得：',
  notAllowedToOne: '• 修改任何从此网站获得的材料的副本。',
  notAllowedToTwo: '• 使用任何插图、照片、视频或音频序列或任何图形。',
  notAllowedToThree: '• 删除或更改任何来自本网站材料副本上的版权、商标或其他专有权公告。',
  notAllowedToFour: '此外，请注意，某些优选供应商已提供位于产品详细页面上的图片库中的旅游产品图片（“供应商图片”），并且这些优选供应商已向正信明示同意，仅当会员代理商在其合法准确地营销和广告活动与会员代理商的商品和服务以及/或适用的优选供应商的商品和服务有关时，可以使用、复制和分发供应商图片。除前述规定外，不得将供应商图片或该网站上的任何其他图像、图形、插图或照片（包括但不限于正信照片或用户贡献）用于任何目的。未经授权使用供应商图片或该网站上的任何其他图像、图形、插图或照片可能会使您承担法律责任，包括但不限于索赔、诉讼、损害赔偿、律师费以及民事和刑事处罚。如果会员代理商不确定网站上特定图像是否为供应商图片，会员代理商有责任向其正信营销联系人进行核实。',
  notAllowedToFive: '请注意，我们未选择或推荐供应商图片，对其所有权不作任何保证，并明确声明不承担调查或验证供应商图片或该网站上任何其他图像、图形、插图或照片所有权或使用权的责任。您明确放弃并同意赔偿、捍卫和使正信免受任何与您使用、复制或分发供应商图片或该网站上任何其他图像、图形、插图或照片有关的索赔、损害赔偿、责任、成本、收费或费用（包括但不限于任何侵犯第三方专有权利的索赔）。',
  notAllowedToSix: '如果您打印、复制、修改、下载或以违反服务条款的方式对网站的任何部分进行使用或向其他人提供访问权限，则正信可能会立即终止您使用网站的权利，并且您必须根据我们的选择，退回或销毁您所制作的任何材料的副本。您不会转让网站或网站上任何内容的任何权利、所有权或利益，所有未明示授权的权利均由正信保留。未经这些服务条款明确授权的任何使用都是违反这些服务条款并可能违反版权、商标和其他法律的行为。',

  userContributions: '7. 用户贡献',
  userContributionsOne: '本网站可能包含或具有留言板、聊天室、个人网页或个人资料、论坛、公告板、保存和分享旅行服务的功能、与评级和评论互动的功能、调查、抽奖、比赛、促销和其他交互功能（统称“互动服务”），允许用户在网站上发布、提交、发布、显示或向其他用户或其他人传输（以下简称“发布”）内容或材料（统称“用户贡献”）。',
  userContributionsTwo: '所有用户贡献必须符合这些服务条款中规定的内容标准。您在网站上发布的任何用户贡献将被视为非保密和非专有的。通过在网站上提供任何用户贡献，您授予我们及我们的附属公司和服务提供商以及他们和我们各自的许可证、继承人和受让人使用、复制、修改、执行、展示、分发和否则披露给第三方任何此类材料的权利，用于任何目的。您必须拥有或控制用户贡献的所有权，并具有向我们及我们的附属公司和服务提供商以及他们和我们各自的许可证、继承人和受让人授予上述许可的权利。',
  userContributionsThree: '您理解并确认，您对提交或贡献的任何用户贡献负责，您有完全责任，而非正信，对此类内容（包括其合法性、可靠性、准确性和适当性）负责。我们对您或网站上任何其他用户发布的用户贡献的内容或准确性不负责任，也不对任何第三方承担责任或承担责任。',
  userContributionsFour: '本网站可能不时提供特殊功能和功能（包括但不限于抽奖、比赛和其他促销活动），这些功能和功能可能具有参与的其他规则、条款和条件。如果您选择进入或参加，您还将受到针对这些特殊功能和功能发布的任何规则、条款和条件的约束。',

  contentStandards: '8. Content standards',
  contentStandardsOne: '这些内容标准适用于所有用户贡献和互动服务的使用。用户贡献必须完全遵守所有适用的国家法律和国际法律和法规。除了上述要求之外，用户贡献不得：',
  contentStandardsTwo: '• 包含任何诽谤、淫秽、不雅、恶意、攻击性、暴力、仇恨、煽动性或其他令人反感的材料。',
  contentStandardsThree: '• 推广色情或淫秽材料、暴力或基于种族、性别、宗教、国籍、残障、性取向或年龄的歧视。',
  contentStandardsFour: '• 侵犯任何其他人的专利、商标、商业秘密、版权或其他知识产权或其他权利。',
  contentStandardsFive: '• 违反他人的合法权利（包括公众形象权和隐私权），或包含可能导致根据适用法律或法规构成任何民事或刑事责任或否则可能与这些服务条款和我们的隐私政策发生冲突的任何材料。',
  contentStandardsSix: '• 可能欺骗任何人。',
  contentStandardsSeven: '• 推广任何非法活动，或者鼓吹、促进或帮助任何违法行为。',
  contentStandardsEight: '• 造成烦恼、不便或毫无必要的焦虑，或者可能使任何其他人感到沮丧、尴尬、警惕或反感。',
  contentStandardsNine: '• 冒充任何人，或者歪曲您与任何人或组织的身份或关联。',
  contentStandardsTen: '• 涉及商业活动或销售，如比赛、抽奖和其他促销活动、易货或广告（除会员机构和优选供应商的允许使用）。',
  contentStandardsEleven: '• 如果不是这种情况，给人留下这些贡献来自我们或任何其他人或实体的印象。',

  privacyRights: '9、用户个人信息与隐私权保护',
  privacyRightsOne: '尊重用户个人信息以及隐私权是正信的基本政策。在您使用正信平台的产品/服务的过程中，正信将采用相关技术措施及其他安全措施来保护您的个人信息与隐私权。',
  privacyRightsTwo: '正信是针对同业优质服务质量供应商和服务商的平台，因此，不对未成年人开放。',

  registration: '10、正信平台账户注册',
  registrationOne: '您确认，在您开始注册使用正信平台的产品/服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。',
  registrationTwo: '您可以创建一个正信平台账户来使用正信平台的产品/服务。您可以直接在正信平台使用您的手机号注册。',
  registrationThree: '在成功注册正信平台账号后，您亦可通过授权登录等方式使用正信平台账户登录正信的部分合作方的产品/服务。但请您注意，您通过正信平台账号登录与合作方的产品/服务的，应当同时遵守相关合作方的账号使用规则。',
  registrationFour: '您仅可通过正信平台官方提供的渠道注册正信平台账户。您不得通过包括但不限于购买、租用、借用、分享、利用或破坏我们的注册系统及/或其他未经我们许可的方式获得正信平台账户。',
  registrationFive: '您享有正信平台账户的有限使用权，您的账户仅可为登录以及查询联系的目的进行使用,不得以转让、出租、出借、售卖、分享或其他任何方式交由他人使用（但法律另有规定或正信明确书面同意或授权的除外）。',
  registrationSix: '如有证据证明或正信根据正信平台规则判断您存在不当注册或不当使用多个正信平台账户的情形，正信平台可采取冻结或关闭账户、取消订单、拒绝提供服务等措施，如给正信平台及相关方造成损失的，您还应承担赔偿责任。',
  registrationSeven: '您在正信平台开通的超级会员特权将与您的正信平台账户绑定，您可以通过已绑定的正信平台账户行使您的超级会员特权，但无法在其他账户下享有上述特权。',

  accountSecurity: '11、账户安全',
  accountSecurityOne: '根据页面提示注册成功后，您将得到一个正信平台账户，并可根据密码规则自行设置账户密码。鉴于网络服务的特殊性，正信无义务审核是否是您本人使用账户的用户名及密码。任何人只要输入的用户名及密码与数据库中保存的一致，即可凭借该组用户名及密码登录正信平台账户，并使用正信产品/服务。因此，请您对密码加以妥善保管，切勿将密码告知他人。如果您未能妥善保管密码，您将承担因此而导致或可能导致的全部责任。除正信存在过错外，您应对您账户项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、购买商品及服务等）负责。',
  accountSecurityTwo: '您理解并同意，为使您更好地使用正信平台的各项服务，保障您的账户安全，正信可要求您按正信平台规则以及相关法律法规规定完成实名认证。',
  accountSecurityThree: '另外，考虑到提供客户服务的便捷性，当用户使用在确认订单时向正信平台提供的联系手机号拨打客服电话，正信平台会通过核对订单信息的方式确认用户身份，并根据用户的要求对有关的订单执行相关操作。因此，请您妥善保管您的手机，设置必要的安全措施防止订单信息泄露。若非因正信过错（例如您的手机失窃或您手机未设置必要的加密措施，致使正信平台订单信息受到不法窥探，他人使用有关信息冒充您的身份使用正信平台及客户服务等）导致订单信息外泄及其他后果的，即使您认为有关的行为并非您本人所为，正信将不会承担因此而产生的任何责任。',
  accountSecurityFour: '您确认并同意，您对通过您的账户所进行的活动、行为和事件依法享有权利和承担责任。您不得以任何形式转让账户、授权他人使用您的账户以及与他人交易账户。如果您发现有人未经您的授权使用了您的正信平台账户，或您的账户存在其他异常情况导致无法正常登录使用，您需要按照正信平台提供的修改密码流程进行密码重置。在此过程中，正信可能会要求您提供相应信息及/或证明资料，请确保您所提供的内容真实有效，否则将可能无法通过我们的验证而导致找回失败。您若发现任何非法使用用户账户或安全漏洞的情况，请立即根据本协议提供的联系方式通知正信。',

  advertising: '12、广告、第三方链接与商业营销',
  advertisingOne: '您知悉并同意，正信平台在提供产品/服务的过程中，在符合法律法规的前提下，保留以各种方式投放商业性广告（包括广告链接）或其他任何类型的商业信息的权利（包括但不限于在正信平台的任何位置上投放广告，在您上传、传播的内容中投放广告）。',
  advertisingTwo: '在符合法律法规的前提下，正信平台有权自主决定广告投放的形式、时长、位置、内容而不受任何第三方的干预。',
  advertisingThree: '正信平台的产品/服务可能会提供与其他互联网网站或资源进行链接，正信会依照法律规定对广告商履行相关义务，但您知悉并同意，这些广告商和广告内容非由正信控制，您对广告信息应审慎判断其真实性、可靠性。您通过正信平台的产品/服务与广告商或广告主进行任何形式的行为或产生任何形式的纠纷，由您自行负责与解决，除法律法规规定由广告发布者承担的责任外，正信不承担任何额外责任，但根据需要会依法提供必要的协助。您在访问正信平台或使用产品/服务的同时，同意接受正信平台提供的各类信息推送服务，包括但不限于短信推送等服务类及营销类信息。',
  advertisingFour: '链接到本网站和社交媒体功能',
  advertisingFive: '仅会员机构和优选供应商可以按照我们的品牌和广告指南链接到我们的主页。',
  advertisingSix: '网站可能提供某些社交媒体功能，使您可以：',
  advertisingSeven: '• 从您自己或某些第三方网站链接到本网站上的某些内容。',
  advertisingEight: '• 发送电子邮件或其他通信，带有本网站上的某些内容或链接到本网站上的某些内容。',
  advertisingNine: '• 在您自己或某些第三方网站上显示或似乎显示本网站上的部分内容。',
  advertisingTen: '您只能根据我们提供与此类功能相关的任何附加条款和条件使用这些功能，仅限于它们所提供的内容，并且以其他方式符合我们提供的附加条款和条件。我们可能随时自行决定禁用所有或任何社交媒体功能和任何链接而不事先通知。',
  advertisingEleven: '接到其他网站',
  advertisingTwelve: '如果本网站包含由第三方提供的其他网站和资源的链接，则这些链接仅供您方便和信息之用，包括广告中包含的链接，包括横幅广告和赞助链接。我们无法控制这些网站或资源的内容，并且对它们或由其产生的任何损失或损坏不承担任何责任。如果您决定访问链接到本网站的任何第三方网站，则完全由您自行承担风险并受制于该网站的使用条款和条件',

  legalLiability: '13、法律责任',
  legalLiabilityOne: '正信明确表示不提供任何类型的担保，不论是明确的或隐含的，但是不对商业性的隐含担保，特定目的和不违反规定的适当担保作限制。',
  legalLiabilityTwo: '对于下述原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更等情形，正信并不承担相应的违约责任：',
  legalLiabilityThree: '• 因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；',
  legalLiabilityFour: '• 因电力供应故障、通讯网络故障等公共服务因素或第三方因素；',
  legalLiabilityFive: '• 在正信已尽善意管理责任的情形下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。',
  legalLiabilitySix: '正信不对任何来自用户不正当使用网络会员服务，非法使用服务或用户向正信平台提供的信息有所变动造成的损害承担任何后果，因为正信早已提出这些损害的可能性并要求用户加以防范。',
  legalLiabilitySeven: '在任何情况下，正信不对任何间接损害（包括商誉损失、利润损失、收入损失、营业中断、缔约机会损失、预期利益损失，及其他间接的、随附性的、惩罚性的损失）承担赔偿责任。',
  legalLiabilityEight: '若您在正信平台上实施了违反本协议或其他单项服务协议、规则的行为，正信平台可依据相应规则对您的账户执行扣减积分、限制参加营销活动、中止提供部分或全部服务、取消订单等处理措施。如您的行为构成根本违约的，正信平台可终止提供服务。如您的行为使正信遭受损失（包括直接经济损失和间接经济损失），或受到任何第三方的索赔，或受到有关行政部门的处罚，您应当承担全部的赔偿责任。',
  legalLiabilityNine: '若有任何人声称您的行为损害第三方权益，并导致正信或向正信平台提供产品/服务的第三方遭受诉讼或其他纠纷的，您同意为正信及/或上述第三方提供抗辩，且正信保留自费进行独自抗辩和向您追索的权利。',

  supervision: '14. 监督和执行；终止；我们有权：',
  supervisionOne: '• 自行决定移除或拒绝发布任何用户贡献，理由不限。然而，我们没有义务在发布前或后审查用户贡献，并不能保证删除或更正违反这些服务条款的材料。因此，我们对任何用户或第三方提供的传输、通信或内容所进行的行动或不作为不承担任何责任。我们对本节中所述活动的执行或不执行对任何人都没有责任或责任。',
  supervisionTwo: '• 采取任何必要或适当的措施，包括如果我们认为此类用户贡献违反了服务条款（包括内容标准）、侵犯任何人或实体的知识产权或其他权利、威胁到网站用户或公众的个人安全或可能为正信带来责任，对任何用户贡献进行处理。',
  supervisionThree: '• 披露您的身份或其他有关您的信息，以回应任何声称您发布的材料侵犯其权利（包括其知识产权或隐私权）的第三方。',
  supervisionFour: '• 采取适当的法律行动，包括但不限于向执法机构转介，针对任何非法或未经授权的使用本网站。',
  supervisionFive: '• 无需任何理由终止或暂停您访问本网站的全部或部分内容，包括但不限于违反这些服务条款。',
  supervisionSix: '除了上述内容外，我们有权全力配合任何要求或指示我们披露在本网站上发布任何材料的任何人的身份或其他信息的执法机构或法院命令。您放弃并保护正信和其附属公司免受任何由正信和/或上述任何方采取的行动或调查以及正信和/或此类方或执法机构进行调查所采取的行动的任何索赔的责任。',

  copyrightInfringement: '15. 版权侵犯',
  copyrightInfringementOne: '任何版权所有人或其代理人认为本网站上的任何内容侵犯了他们的版权，均可提交符合版权法的通知，并向正信的版权代理提供以下书面信息：',
  copyrightInfringementTwo: '（1）被授权代表涉嫌受到侵犯的专有权利的所有者签署的物理或电子签名；',
  copyrightInfringementThree: '（2）所声称已经被侵犯的版权作品的标识，或者如果单个在线站点上的多个版权作品由单个通知进行覆盖，则该站点上此类作品的代表性清单；',
  copyrightInfringementFour: '（3）被声称侵犯或是侵犯活动的主题并应予删除或停用访问的材料，并提供充分信息使得正信能够定位这些材料；',
  copyrightInfringementFive: '（4）合理的信息以使正信l能够联系您，例如地址、电话号码和电子邮件（如有）；',
  copyrightInfringementSix: '（5）声明该人诚信相信所投诉使用材料的方式未获得版权所有者、其代理或法律的授权；',
  copyrightInfringementSeven: '（6）声明通知中的信息准确无误，并且在宣誓下，提交通知的人被授权代表涉嫌受到侵权的专有权利的所有者行事。',
  copyrightInfringementEight: '如果您相信您提供给网站的内容未侵犯任何版权，或者您具有版权所有者、版权所有者的代理或根据法律的授权来发布和使用该内容的权限，则可以发送包含以下信息的反通知给版权代理：',
  copyrightInfringementNine: '（1）您的物理或电子签名；',
  copyrightInfringementTen: '（2）已被删除或已被停用访问的内容的标识以及在内容被删除或被停用访问之前所出现的位置；',
  copyrightInfringementEleven: '（3）声明您诚信相信内容被错误地删除或停用访问或内容被错误地识别；',
  copyrightInfringementTwelve: '（4）您的姓名、地址、电话号码和电子邮件地址，声明您同意接受上海市徐汇区高级人民法院的管辖，并声明您将接受提供声称进行侵权通知的人提供的法律文书。',
  copyrightInfringementThirteen: '如果版权代理收到反通知，正信可能会向原告发送反通知的副本，通知他们在十（10）个工作日内可以替换被删除的内容或停止禁用它。除非版权所有者提起诉讼寻求法院针对内容提供者的命令，否则在收到反通知后的十（10）至十四（14）个工作日内，将恢复删除的内容或恢复对其的访问，由正信自行决定。',

  legal: '16、法律',
  legalOne: '本协议涉及的概念与界定，与中华人民共和国法律中的相同概念与界定一致。因通过正信平台联系/开展任何商务合作或使用任何服务而导致的争议，双方可协商解决；协商不成的，任何一方均可向上海市仲裁委员会提出仲裁。',
  legalTwo: '对于与正信平台有关的纠纷，您知晓并完全同意对事实清楚、权利义务关系明确、争议不大、标的额为人民币五万元以上、十万元以下的简单金钱给付案件，使用小额仲裁程序，实行一审终审。使用小额仲裁程序审理的案件，审判组织、审理期限、审理方式、一审终审等相关事项以xxxxx颁发的《xxxxx》为准。',

  websiteChanges: '17. 网站变更',
  websiteChangesOne: '我们可能会定期更新本网站上的内容，但其内容不一定完整或最新。任何时候，本网站上的任何材料都可能过时，我们没有义务更新此类材料。',
  websiteChangesTwo: '有关您和您访问本网站的信息',
  websiteChangesThree: '我们在本网站上收集的所有信息均受到我们的隐私政策的约束。通过使用本网站，您同意我们采取的与您的信息相关的所有行动均符合隐私政策。',
  websiteChangesFour: '在线预订请求',
  websiteChangesFive: '本网站的某些功能可能会促进会员和/或会员机构与优选供应商预订预订。在您通过本网站提交预订请求时，特此告知会员，正信将根据我们的隐私政策向适用的会员机构和相关服务提供者提供您的预订信息（包括联系信息、付款信息和预订详情），以便这些方完成所请求的预订并处理您的付款；正信不收取信用卡费用，也不处理付款。仅当适用会员机构的全球分销系统书面接受和确认预订并处理付款时，预订才是最终确定的。',

  paymentDisputes: '18. 付款纠纷、取消和/或服务问题',
  paymentDisputesOne: '必须向适用的会员机构和/或优选供应商提出。优选供应商的附加条款和条件可能适用。价格和可用性如有更改将不另行通知。对于国际旅行，由于货币汇率波动，报价金额可能与您信用卡所收取的金额不同。您的银行和/或信用卡公司的转换费用也可能显示在您的账单上。',
  paymentDisputesTwo: '作为独立平台运营方，正信不拥有或控制会员机构和优选供应商，正信不对其行为或不作为或因此产生的任何伤害、损失、费用或责任负责。正信不是通过本网站预订或预定的任何旅行产品或服务的销售者或提供者，正信特此声明所有明示或暗示的、法定的或其他的任何保证，包括但不限于可用性、满意度、适销性或特定用途的适用性等均不承担任何责任。前述不影响适用法律下无法排除或限制的任何保证。',

  contactInformation: '19、联系方式',
  contactInformationOne: '如您对本协议或使用正信平台的产品/服务相关的事宜有任何问题（包括问题咨询、投诉等），我们专门为您提供以下反馈通道，希望为您提供满意的解决方案：',
  contactInformationTwo: "• 邮件通道: support{'@'}smoothtravel.com",

  disclaimer: '20. 免责说明',
  disclaimerOne: '正信由上海文旅质量服务与监测中心指导，德启（上海）管理咨询有限公司运营，涉及具体产品服务的，将由有资质的服务商提供。如果用户（“用户”“会员”或“您”）在本网站访问、预定或使用正信平台会员单位的产品或服务（以上统称为“服务”），便视为用户接受了以下免责说明，请您仔细阅读以下内容，尤其是以下加粗字体。如果您不同意以下任何内容，请立刻停止访问/使用本网站或其他任何移动应用或软件所提供的相关服务。',
  disclaimerTwo: '您完全同意及认可，对用户预订的旅游服务项目中出现的瑕疵等问题，正信并不承担责任，但正信会尽力协助用户与相关旅游服务提供商进行协商或寻求仲裁服务。',
  disclaimerThree: '您完全理解并同意，鉴于正信是一个提供信息交流的平台，对于您选择合作的合作方提供的服务将是您双方自行商议的结果，您完全理解并豁免上述服务结果，正信不承担任何责任。',
  disclaimerFour: '为方便您的使用，正信可能会对产品的可信赖程度而进行的评级、推荐或风险提示仅供您参考，正信不担保该等评级、推荐或风险提示的准确性和完整性，对推荐的网站的内容及服务亦不承担任何责任。',
  disclaimerFive: '您了解并理解，任何经由正信提供服务而发布的图形、图片或个人言论等，均表示了内容提供者、服务使用者个人的观点、观念和思想，并不代表正信的观点或主张，对于在享受网络服务的过程中可能会接触到令人不快、不适当等内容的，由您个人自行加以判断并承担所有风险，正信不承担任何责任。',
  disclaimerSix: '正信为所有旅游业供需方，出行服务提供方、第三方代理商（包括但不限于机票、住宿、旅游产品、景点门票等）提供一个在线信息交流平台，供其宣传自己可供预订的产品及服务，同时也通过本平台让企业双方预订旅行服务资源。',
  disclaimerSeven: '您完全理解并同意，通过正信购买合作企业现有旅游产品或服务或此后新的产品和服务时，您在预订产品或服务后应当及时付款；您在预订产品或服务后未全额支付前，您尚未完成购买上述产品或服务。因上述产品或服务的价格、舱位、数量或库存等实时更新或变化而造成您的额外费用、损失或无库存等，由您自行承担，正信不承担任何责任。您完全理解并同意，您在支付过程中，因网络中断、银行反馈信息错误等非正信的原因造成的付款失败，由您自行承担，正信不承担任何责任。',
  disclaimerEight: '请您及时保存或备份您的文字、图片等其他信息，您完全理解并同意，由于正信储存设备有限、设备故障、设备更新或设备受到攻击等设备原因或人为原因，您使用网路服务储存的信息或数据等全部或部分发生删除、毁损、灭失或无法恢复的风险，均由您自行承担，正信不承担任何责任。',
  disclaimerNine: '正信不担保网络服务一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不做担保。',
  disclaimerTen: '正信不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由正信实际控制的任何网页上的内容，正信不承担任何责任。',
  disclaimerEleven: '对于因不可抗力或正信不能控制的原因造成的网络服务中断或其他缺陷，正信不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。',
  disclaimerTwelve: '您了解我们不能保证互联网或本网站上提供下载的文件不含有病毒或其他破坏性代码。您应当负责实施足够的程序和检查点以满足您特定的反病毒保护和数据输入输出准确性要求，并且为任何丢失数据的重建维护本站之外的手段。我们将不承担因分布式拒绝服务攻击、病毒或其他技术有害材料感染您的计算机设备、计算机程序、数据或其他专有材料而导致的任何损失或损害，原因可能是您使用本网站或通过本网站获取的任何服务或物品，或者因您下载在本网站或链接的任何网站上发布的任何材料。',
  disclaimerThirteen: '您使用本网站、其内容和通过本网站获得的任何服务或产品的风险自负。本网站、其内容和通过本网站获得的任何服务或产品均按“原样”和“可用性”提供，不附带任何明示或暗示的保证。正信或与之相关的任何人士均不对本网站、其内容以及通过本网站获得的任何服务或产品的完整性、安全性、可靠性、质量、准确性或可用性进行任何保证或表示。在不限制前述内容的前提下，正信或与之相关的任何人士均不对本网站、其内容或通过本网站获得的任何服务或产品的准确性、可靠性、无误或不中断性进行任何陈述或保证，也不保证我们的网站或使其可用的服务器没有病毒或其他有害组件，或者本网站或通过本网站获得的任何服务或产品将满足您的需求或期望。',
  disclaimerFourteen: '正信特此声明所有明示或暗示的、法定的或其他的任何保证，包括但不限于适销性、非侵权和特定用途的适用性等均不承担任何责任。',
  disclaimerFifteen: '本免责声明要与中华人民共和国法律解析一致，包括法律条款中有争议抵触的内容，您和正信一致同意服从法院的管辖。若有任何服务条款与法律相抵触，那这些条款将按尽可能接近的方法重新解析，而其它条款则保持对用户产生法律效力和影响。因解释本免责声明以及用户通过正信平台预订任何产品而导致的争议，将同意接受上海市仲裁委员会的仲裁。',

  limitedLiability: '21. 有限责任',
  limitedLiabilityOne: '在任何情况下，正信其关联公司或它们各自的雇员、代理人、高级职员或董事不承担任何责任，无论是在任何法律理论下，因您使用或无法使用本网站、任何链接到它的网站、本网站或其他网站上的任何内容以及由本网站或其他网站获取的任何服务或产品而产生的任何损害赔偿，包括但不限于直接、间接、特殊、附带、后果性或惩罚性损害，包括但不限于个人受伤、痛苦和煎熬、情感上的困扰、收入损失、利润损失、业务或预期储蓄损失、使用损失、商誉损失、数据损失，以及无论是否由侵权行为（包括过失）、合同违约或其他方式引起，即使是可预见的。前述内容不影响适用法律下无法排除或限制的任何责任。',

  indemnification: '22. 赔偿',
  indemnificationOne: '您同意赔偿、保护和使正信其关联公司及其各自的董事、高级职员、员工、代理、继承人和受让人免受任何索赔、责任、损害赔偿、判决、奖励、损失、成本、费用或收费（包括合理的律师费）的责任，因您违反这些服务条款或您使用本网站而产生，包括但不限于您的用户贡献、任何对本网站内容的使用，或您从本网站获得的任何服务、产品和/或信息的使用。',

  waiverAndSeverability: '23. 放弃和可分割性',
  waiverAndSeverabilityOne: '正信对这些服务条款中规定的任何条款或条件的放弃不得被视为对此类条款或条件的进一步或继续放弃，或对任何其他条款或条件的放弃，而正信l未能在这些服务条款下主张权利或规定不构成对该等权利或规定的放弃。如果根据有管辖权的法院或其他仲裁机构判决，这些服务条款的任何规定因任何原因被认为无效、非法或不可执行，则该规定应被取消或限制至最小程度，以使服务条款的其余规定继续完全有效和具有约束力。',

  entireAgreement: '24.完整协议',
  entireAgreementOne: '服务条款和我们的隐私政策构成您与正信就本网站达成的唯一和全部协议，并取代了所有先前和同时期的理解、协议、陈述和保证，无论是书面还是口头，都与本网站有关。',
  entireAgreementTwo: '尽管前述内容或本文中的相反内容，但对于代表会员机构或正信优选供应商的用户，现有或将来可能存在其他书面协议（包括但不限于正信会员协议、酒店预订计划协议和/或正信首选供应商协议）。这些书面协议保持完全有效，并不修改或更改这些其他书面协议。',
  entireAgreementThree: '如果这些服务条款与正信的任何其他书面协议条款存在冲突，正信有权在其合理判断下确定哪些条款控制。',

  /**
   * 以下是之前表里的数据
   */
  resourceCenterIntro: '资料中心提供各个专家领域考核对应的资料，您或您的员工可在这里学习对应的行业资料。<br/>每个学习资料都有对应的专家考核，通过考核可获得对应的专家资质。展示您的专家水平。',
  'smoothTravelProvidesAComprehensiveAndEffectiveOnlineTravelExpertTrainingForDestinationsAndTourismIndustryPartnersWorldwide.': '正信为全球的目的地和旅游行业伙伴提供全面有效的在线旅游专家培训。',
  startLearning: '开始学习',
  takeTheExamDirectly: '直接考试',
  resourceCenter: '资料中心',
  expertCertificatesIveObtained: '我获得的专家证书 ',
  allMaterials: '资料中心',
  ongoingTrainings: '进行中的培训',
  allTrainings: '所有培训',
  expertTraining: '专家培训',
  chineseOutboundPartners: '跨境旅游合作伙伴',
  insuranceintroduction: '保险介绍',
  introductiontoarbitration: '仲裁介绍',
  fundsupervision: '资金监管',
  marketsurvey: '市场调研',
  cooperativepartner: '战略合作伙伴',
  viewmore: '查看更多',
  servicesthree: '通过对资料中心的学习，您可在此通过完成专家考核获得对应的专家资质，展示您的专业水平，更好的获得客户的认可。',
  noexpertcertificatehasbeenobtained: '暂未获得专家证书',
  pleaseenteramessage: '请输入消息',
  theexpert: '专家培训考试分为若干部分，只有全部答对前面部分的试题才可以保存或继续作答下一部分的试题。全部答对即可获得该分类的专家证书。',
  progress: '答题进度：',
  singlechoice: '单选题',
  nextquestion: '下一题',
  multipleChoice: '多选题',
  submit: '提交',
  continuetheexamination: '继续考试',
  startheexamination: '开始考试',
  alreadypassed: '已通过',
  /**
   * 表里数据结束
   */
}
