import axios from '@/request/http'

const home = {
  // 轮播图
  carousel(params) {
    return axios.get(`/api/ad`, { params })
  },
  carouselDetail(params) {
    return axios.get(`/api/ad/${params}`)
  },
  // 新闻列表
  getNewList(params) {
    return axios.get(`/api/news`, { params })
  },
  // 新闻详情
  getNewDetail(params) {
    if (params.lang === 'cn' || params.lang === 'en') {
      return axios.get(`/api/news/${params.id}?lang=${params.lang}`)
    }
    return axios.get(`/api/news/${params.id}`)
  },
  // 榜单列表
  getRankList(params) {
    return axios.get(`/api/ranking`, { params })
  },
  // FAQ列表
  getfaqList(params) {
    return axios.get(`/api/faq`, { params })
  },
  // 加入我们
  getContactUsList() {
    return axios.get(`/api/contact-us`)
  },
  // 理事会
  getCouncliUsList() {
    return axios.get(`/api/member-council`)
  },
  getAdvertising() {
    return axios.get(`/api/advertising`)
  },
  // uploadFile(data) {
  //
  //   return axios({
  //     url: `${import.meta.env.VITE_APP_BASE_API}open/file`,
  //     method: 'post',
  //     data,
  //     headers: { 'Content-Type': 'multipart/form-data' }
  //   })
  // },
  addFile(data) {
    return axios.post(`/open/file/add`, data)
  }
}
export default home
