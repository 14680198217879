import { createRouter, createWebHashHistory } from 'vue-router'
// 模块引入
import enterprise from '@/router/modules/enterprise'

// 布局页面
const layout = () => import('@/layout/layout.vue') // 作业管理
// home
const home = () => import('@/views/home/index.vue') // home
// 移动端导航页面
// Membershipscheme
const Membershipscheme = () => import('@/views/home/Membershipscheme.vue')
// 新闻页面
const News = () => import('@/views/home/News.vue')
// 全球媒体关注
const GlobalMediaCoverage = () => import('@/views/home/GlobalMediaCoverage.vue')
// 宣传文章页面
const PublicityArticle = () => import('@/views/home/PublicityArticle.vue')
// 榜单页面
const rankinglist = () => import('@/views/home/rankinglist.vue')
// 新闻资讯详情页面
const Information = () => import('@/views/home/Information.vue')
// 理事会
const council = () => import('@/views/home/council.vue')
const paymentOrder = () => import('@/views/home/paymentOrder.vue')
// 企业页面
const corporate = () => import('@/views/corporate/corporate.vue')
// 服务页面
const services = () => import('@/views/services/services.vue')
// 合作酒店子页面
const Cooperativehotel = () => import('@/views/corporate/Cooperativehotel.vue')
// 酒店房间详情页面
const roomdetails = () => import('@/views/corporate/roomdetails.vue')
// 服务子页面
const chineseOutboundPartners = () => import('@/views/services/Servicecenter/chineseOutboundPartners.vue')
const Insurance = () => import('@/views/services/Servicecenter/Insurance.vue')
const arbitration = () => import('@/views/services/Servicecenter/arbitration.vue')
const capital = () => import('@/views/services/Servicecenter/capital.vue')
const Market = () => import('@/views/services/Servicecenter/Market.vue')
const exhibition = () => import('@/views/services/Servicecenter/exhibition.vue')
const data = () => import('@/views/services/Servicecenter/data.vue')
const expert = () => import('@/views/services/Servicecenter/expert.vue')
// 开始学习资料页面
const learningMaterials = () => import('@/views/services/learningMaterials.vue')
const smallNav = () => import('@/views/home/smallNavbar.vue')
const Informationmore = () => import('@/views/corporate/Informationmore.vue')

// 继续考试页面
const examinationpage = () => import('@/views/services/examinationpage.vue')
const cooperative = () => import('@/views/services/Servicecenter/cooperative.vue')
// 我的订单页
const orderForm = () => import('@/views/Individualcenter/orderForm.vue')
// 服务条款页面
const termsofService = () => import('@/views/Individualcenter/termsofService.vue')

// 设置页面
const settings = () => import('@/views/Individualcenter/settings.vue')
// 个人中心页面
const usercenter = () => import('@/views/Individualcenter/usercenter.vue')
// 个人资料编辑页
const usercenterInfo = () => import('@/views/Individualcenter/usercenterInfo.vue')
// 酒店中心页面
const hotelcenter = () => import('@/views/Individualcenter/hotelcenter.vue')
// 合作详情
const Cooperationdetails = () => import('@/views/Individualcenter/Cooperationdetails.vue')
const search = () => import('@/views/search/search.vue')

// 入境上海页面
const visitShanghai = () => import('@/views/visitShanghai/visitShanghai.vue')

const blank = () => import('@/views/blank.vue')
const routes = [
  {
    path: '/blank',
    component: blank,
    name: 'blank'
  },
  {
    path: '/layout',
    component: layout,
    name: 'layout',
    children: [
      ...enterprise,
      {
        path: '/termsofService',
        name: 'termsofService',
        component: termsofService
      },
      {
        path: '/home',
        name: 'home',
        component: home,
        meta: {
          keepAlive: true,
          scrollTop: 0
        }
      },
      {
        path: '/smallNavbar',
        name: 'smallNavbar',
        component: smallNav
      },
      {
        path: '/searchPage',
        name: 'searchPage',
        component: search,
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/paymentOrder',
        name: 'paymentOrder',
        component: paymentOrder
      },
      {
        path: '/search',
        name: 'corporate',
        component: corporate
      },
      {
        path: '/Cooperativehotel',
        name: 'Cooperativehotel',
        component: Cooperativehotel
      },
      {
        path: '/roomdetails',
        name: 'roomdetails',
        component: roomdetails
      },

      {
        path: '/Membershipscheme',
        name: 'Membershipscheme',
        component: Membershipscheme
      },
      {
        path: '/ChinaTourismMarketAndConsumerInsights', // 客户需要修改域名, 原先是 "News"
        name: 'News',
        component: News,
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/GlobalMediaCoverage',
        name: 'GlobalMediaCoverage',
        component: GlobalMediaCoverage,
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/Information',
        name: 'Information',
        component: Information
      },
      {
        path: '/PublicityArticle/:id',
        name: 'PublicityArticle',
        component: PublicityArticle
      },
      {
        path: '/Informationmore',
        component: Informationmore,
        name: 'Informationmore'
      },
      {
        path: '/rankinglist',
        name: 'rankinglist',
        component: rankinglist
      },
      {
        path: '/council',
        name: 'council',
        component: council
      },
      {
        path: '/services',
        name: 'services',
        component: services,
        // 二级路由
        children: [
          {
            path: '/services/chineseOutboundPartners',
            name: 'chineseOutboundPartners',
            component: chineseOutboundPartners
          },
          {
            path: '/services/Insurance',
            name: 'Insurance',
            component: Insurance
          },
          {
            path: '/services/arbitration',
            name: 'arbitration',
            component: arbitration
          },
          {
            path: '/services/capital',
            name: 'capital',
            component: capital
          },
          {
            path: '/services/Market',
            name: 'Market',
            component: Market
          },
          {
            path: '/services/exhibition',
            name: 'exhibition',
            component: exhibition
          },
          {
            path: '/services/cooperative',
            name: 'cooperative',
            component: cooperative
          }
        ]
      },
      {
        path: '/services/expert',
        name: 'expert',
        component: expert
      },
      {
        path: '/services/data',
        name: 'data',
        component: data
      },
      {
        path: '/learningMaterials',
        name: 'learningMaterials',
        component: learningMaterials
      },
      {
        path: '/usercenter',
        name: 'usercenter',
        component: usercenter
      },
      {
        path: '/examinationpage',
        name: 'examinationpage',
        component: examinationpage
      },
      {
        path: '/orderForm',
        name: 'orderForm',
        component: orderForm
      },
      {
        path: '/settings',
        name: 'settings',
        component: settings
      },
      {
        path: '/usercenterInfo',
        name: 'usercenterInfo',
        component: usercenterInfo
      },
      {
        path: '/hotelcenter',
        name: 'hotelcenter',
        component: hotelcenter
      },
      {
        path: '/Cooperationdetails',
        name: 'Cooperationdetails',
        component: Cooperationdetails
      },
      //入境上海
      {
        path: '/visitShanghai',
        name: 'visitShanghai',
        component: visitShanghai,
        meta: {
          keepAlive: true
        },
      },
      //最新入境政策详情
      {
        path: '/visitShanghai/zxrjzc/:id',
        name: 'zxrjzc',
        component: () => import('@/views/visitShanghai/zxrjzc/detail.vue'),
      },
      //入境市场洞察详情
      {
        path: '/visitShanghai/rjscdc/:id',
        name: 'rjscdc',
        component: () => import('@/views/visitShanghai/rjscdc/detail.vue'),
      },
       //优质入境线路
       {
        path: '/visitShanghai/yzrjxl/:id',
        name: 'yzrjxl',
        component: () => import('@/views/visitShanghai/yzrjxl/detail.vue'),
      },
    ]
  },
  {
    path: '/',
    redirect: '/home'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
