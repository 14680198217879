export default {
  globalMediaCoverage: '全球媒体关注',
  verification: '验证',
  officeCode: '代码（选填）',
  plsEnterOfficeCode: '请输入折扣',
  refundStatement: "如您在付费后的 14 天内申请退款，请联系 <a style='color: red' href='mailto:membershipmanager{'@'}smoothtravel.com'>membershipmanager{'@'}smoothtravel.com</a>，我们的工作人员将为您处理全额退款事项。超过14天将不支持退款。",

  CPIC: '中国太平洋保险',
  travelTimes: '旅游时报',
  chinaTravelTradeMedia: '旅业资讯',
  comingSummer2024: '2024 年夏季即将发布',
  pleaseCompleteTheBasicInformationOfTheCompany: "公司基本信息的中英文资料都需要填写，如果遇到翻译困难，可以发送邮件至 support{'@'}smoothtravel.com",
  congratulationsOnYourRegistration: '最后一步！',
  nowYouCanFindYourChinesePartnersHere: '完成您的年度会员付款以访问 SmoothTravel 的服务。',
  dmoRegisterCompleted: '感谢您提交的初步申请，目前正在上海审查中。一旦您的申请获批，您将收到正信发来的电子邮件，届时您将可以完善您的个人资料。',
  associationsFree: '协会/目的地旅游局可以免费使用会员服务',
  getStarted: '开始使用',
  startFreeMembershipTrial: '开始免费会员试用',
  congratulationsRegistrationComplete: '恭喜，注册完成！',
  Welfare24: '企业可以在2024年上半年<strong>免费拥有6个月的试用会员</strong>。试用结束后，您将可以选择订阅为定期的企业会员，年费为2,000美元。',
  beforeRegistration: '为什么要成为正信会员？',
  beforeRegistrationOne: "正信是上海市政府支持的跨境服务质量提升平台（<span id='603523492931014656' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>详见战略合作伙伴</span>），将中国旅游业与世界各地寻求旅游业务的旅游行业实体联系起来。正信为会员带来的主要权益如下：",

  beforeRegistrationItemOneTitle: '对接优质中国出入境旅行社',
  beforeRegistrationItemOneContent: '正信的核心是让您与全球领先的出境或入境旅行社建立新的合作关系。找出符合您特定需求的优质旅游合作伙伴，评估他们的能力，建立对话，并与精选的合作伙伴建立富有成效的工作关系，以促进您的旅游业务。',
  beforeRegistrationItemTwoTitle: '查阅会员的市场材料',
  beforeRegistrationItemTwoContent: '在确定了潜在的旅游贸易合作伙伴后，正信可让您查阅他们的市场材料，以便全面了解他们的产品、能力、业绩记录，以及是否适合作为额外的旅游合作伙伴。',
  beforeRegistrationItemThreeTitle: '市场材料的翻译服务',
  beforeRegistrationItemThreeContent: '中国一线旅游业界也在利用正信寻找新的海外合作伙伴，包括最适合接待中国海外游客的新目的地、跨境旅游运营商、巴士公司、酒店集团、景点、零售商、餐厅、邮轮公司等。为了吸引会员的兴趣，在正信上发布功能强大的市场材料也是最理想的选择。如有必要，正信将利用专门为中国出入境旅游市场开发市场资料的翻译团队提供翻译支持，费用自理。针对全球市场的需求和细微差别，确保市场材料的翻译准确且符合各个国家的文化，这将是促进您旅游业务的关键。',
  beforeRegistrationItemFourTitle: '建立新的合作伙伴关系，拓展您的旅游业务',
  beforeRegistrationItemFourContent: '正信的会员专属客户经理将带领您完成所需流程，并协助您，确保您能够在旅游行业建立新的合作伙伴关系。',
  beforeRegistrationItemFiveTitle: '保障资金安全，降低付款风险',
  beforeRegistrationItemFiveContent: "为了最大限度地降低与新的旅业合作伙伴打交道时的支付风险，正信携手银联（<span id='609135372051537921' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>详见简介</span>），为会员提供独特的支付担保系统，弥补了中外旅游业界支付习惯的差异。银联的支付担保系统将有效地消除在中国和海外的所有支付风险。",
  beforeRegistrationItemSixTitle: '双语争议仲裁服务',
  beforeRegistrationItemSixContent: "如果海外会员与中国会员之间发生任何争议，正信与上海市政府的专业旅游争议解决中心（<span id='603523492931014656' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>详见简介</span>）合作，由专门解决跨境旅游争议的双语仲裁员，以独特的优势为任何一方提供可靠的仲裁服务，无需支付任何费用，并能消除文化和语言差异，公平有效地解决任何冲突。",
  beforeRegistrationItemSevenTitle: '保障游客拥有全面的旅游保险安全保障',
  beforeRegistrationItemSevenContent: "正信与中国太平洋保险（CPIC）（<span id='534690179236065280' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>详见简介</span>）合作开发了中国首个专为游客量身定制的旅游综合保险，为中国游客、中国出入境旅游行业和海外合作伙伴的利益提供安全保障。",
  beforeRegistrationItemEightTitle: '及时响应会员的反馈意见',
  beforeRegistrationItemEightContent: "正信携手上海市旅游质量监测服务中心（<span id='603523492931014656' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>详见简介</span>），以提高中国与世界各国之间的跨境旅游服务质量，并让海内外优质会员与全球旅游行业的佼佼者携手合作为最终目标。正信给会员提供互惠的反馈机制，推动旅游业服务的持续改进，确保中国旅游业界最优质的企业与合适的海内外会员合作，并提升旅游产品的整体质量。",
  beforeRegistrationItemNineTitle: '获取有关中国出入境旅游业的最新动态',
  beforeRegistrationItemNineContent: "掌握最新趋势，洞察游客的心态，了解全球旅游市场快速发展的动态，是在旅游行业取得成功的基础。正信利用与各地政府、媒体和行业信息来源（<span id='563313891564601344' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>详见战略合作伙伴</span>）的独特联系，为会员提供对这一瞬息万变市场的全面了解，并确保必要的战略把握，以制定针对游客的长期成功路线。",
  beforeRegistrationItemTenTitle: '专属旅游市场调研方案，完善您的旅游战略与战术',
  beforeRegistrationItemTenContent: '如果公开信息来源不够充分，可委托专属定制研究，以更精确地制定您的市场战略，并对战术进行微调。',

  // 会员等级和权益详情
  membershipLevelsAndBenefitsDetails: {
    title: '企业会员等级和权益详情',
    keyMemberBenefits: {
      title: '主要会员权益',
      detail: `1. 对接优质中国出入境旅行社<br />2. 查阅会员的市场材料<br />3. 市场材料的翻译服务<br />4. 会员信息互享 <br />5. 建立新的合作伙伴关系，拓展旅游业务<br />6. 保障资金安全，降低付款风险<br />7. 双语争议仲裁服务<br />8. 保障游客拥有全面的旅游保险安全保障<br />9. 及时响应会员的反馈意见<br />10. 获取有关中国出入境旅游业的最新动态<br />11. 委托定制专属旅游市场调研方案<br />12. 正信标志使用授权<br />`
    }
  },
  activateNewTopTierOutboundChinaTravelPartners: {
    title: '对接优质中国出境旅游合作伙伴',
    chineseMemberSearchFunction: {
      title: '会员搜索功能',
      detail: '正信为会员提供强大的搜索引擎，帮助您寻找新的中国出入境旅游合作伙伴，或通过理想的新合作伙伴拓展现有的中国业务。可用的搜索格式包括关键字搜索、地区搜索、按合作伙伴类型搜索（如入境或出境中国旅行社）。我们还将为您指派一名正信的会员专属客户经理，协助您寻找理想的中国旅游业合作伙伴。要使用正信的会员搜索功能，请在下方注册成为会员。'
    },
    salesMaterialTranslationIntoChineseIfNeeded: {
      title: '市场材料翻译服务',
      detail: '正信的会员专属客户经理将帮助您评估您的市场材料是否适合在海外或中国市场使用，以吸引旅业同仁并建立新的合作伙伴关系。如有需要，正信可利用专门为中国出入境旅游市场设计新的市场资料的翻译团队，以低成本提供翻译服务支持。确保您的市场材料和在线内容的翻译准确且符合相关国家的文化，以满足旅游市场的需求和细微差别，将是促进您旅游业务发展的关键。要使用正信的专业旅游翻译服务，请在下方注册成为会员。'
    },
    chineseMemberSalesMaterialEvaluation: {
      title: '会员信息互享',
      detail: '在选定潜在目标旅游合作伙伴后，会员可在线访问合作伙伴提供的资料。通过我们的用户界面，会员可以轻松浏览全球会员发布的大量中英文资料，例如详细的公司简介、产品目录、营销介绍、业务状况等。此外，会员还可以查看客户评价和案例研究，以便决定是否与目标旅游合作伙伴开展对话并建立潜在合作关系。若要查阅完整的会员资料，请在下方注册成为会员。'
    },
    supportFromSmoothTravelChinaMemberEngagementManager: {
      title: '正信会员专属客户经理',
      detail: '作为正信的会员，您将被指派一名会员专属客户经理，他将带领您完成所需流程，并协助您，确保您能够在旅游行业建立新的合作伙伴关系，请在下面注册成为会员。'
    }
  },
  minimizeYourChinaBusinessRisk: {
    title: '将旅游业务风险降至最低',
    paymentGuaranteeSystem: {
      title: '支付担保系统',
      detail: "为了最大限度地降低与新的旅业合作伙伴打交道时的支付风险，正信携手银联（<span id='609135372051537921' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>详见简介</span>），为会员提供独特的支付担保系统，弥补了中外旅游业界支付习惯的差异。银联的支付担保系统将有效地消除了在中国和海外的所有支付风险。若要使用正信/银联支付担保系统，请在下面注册成为会员。",
    },
    bilingualDisputeArbitration: {
      title: '双语争议仲裁',
      detail: "如果海外会员与中国会员之间发生任何争议，正信与上海市政府的专业旅游争议解决中心（<span id='603523492931014656' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>详见简介</span>）合作，由专门解决跨境旅游争议的双语仲裁员，以独特的优势为任何一方提供可靠的仲裁服务，无需支付任何费用，并能消除文化和语言差异，公平有效地解决任何冲突。若要访问正信的争议仲裁平台，请在下面注册成为会员。",
    },
    chinaOutboundTravelInsurance: {
      title: '旅游保险',
      detail: "正信与中国太平洋保险（CPIC）（<span id='534690179236065280' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>详见简介</span>）合作开发了中国首个专为游客量身定制的旅游综合保险，为中国游客、中国出入境旅游行业和海外合作伙伴的利益提供安全保障。一旦发生意外，大部分费用都将得到保障，从而降低中国会员和海外会员的经济风险。欲了解更多有关正信与太平洋保险合作的保险计划，只需在下方注册成为会员。",
    },
    memberEvaluationFeedbackLoop: {
      title: '会员评价/反馈',
      detail: "正信携手上海市旅游质量监测服务中心（<span id='603523492931014656' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>详见简介</span>），以提高中国与世界各国之间的跨境旅游服务质量，并让海内外优质会员与全球旅游行业的佼佼者携手合作为最终目标。正信给会员提供互惠的反馈机制，推动旅游业服务的持续改进，确保中国旅游业界最优质的企业与合适的海内外会员合作，并提升旅游产品的整体质量。",
    },
    showcaseOfTopServiceQualityMembers: {
      title: '优质服务榜单',
      detail: '正信与上海市政府将每年发布一份全球旅游业各个领域的优秀企业名单，该榜单由会员推荐和评选得出，以表彰其卓越的服务质量。该榜单将评估企业的服务质量、专业能力和客户满意度。同时还将根据会员的评价，展示优质出境和入境旅行社。',
    },
  },
  nurtureYourChinaTravelIndustryRelationships: {
    title: '旅游业务拓展',
    chinaExpertTraining: {
      title: '专家培训',
      detail: '正信提供全面的双向培训平台，为海外会员和中国会员开设了专门的培训课程。海外会员可利用该平台培训中国旅游业界合作伙伴，使其成为目的地、服务或产品方面的专家。同样，正信也为中国会员提供平台，培训海外旅游业界合作伙伴成为接待中国入境游客的专家。欲了解更多有关正信培训平台的信息，请在下方注册成为会员。',
    },
    eventPromotionTargetingChinasOutboundTravelIndustry: {
      title: '针对中国出入境旅游业的活动推广',
      detail: '会员可通过正信来组织针对旅游业界的推广活动，包括培训、研讨会、路演、旅业展会和相关旅游产品发布会。若要向旅游业界推广您的活动，请在下方注册成为会员。',
    },
    smoothTravelLogoSealOfApprovalOnChinaMarketingMaterials: {
      title: '正信标志使用授权',
      detail: '正信是上海市政府的官方旅游网站，将华东地区的旅游业与世界各地的旅游行业实体联系在一起。正信会员可在旅游行业市场材料上使用由上海市政府批准的标志。',
    },
  },
  refineYourChinaStrategy: {
    title: '优化中国旅业战略',
    chinaOutboundConsumerAndTravelIndustryInsights: {
      title: '旅游消费者和旅游行业动态',
      detail: '正信利用广泛的政府和行业资源，开发最新的中国出入境旅游市场研究、行业趋势以及消费者行为和偏好。正信通过调查、深度访谈、焦点小组讨论、研讨会和实地考察等方式，对中国出入境旅游市场的现状和中国出入境旅游者的看法进行更新。我们的目标是让您深入了解中国出入境旅游市场的现状，提供数据和分析，帮助您充分利用中国乃至全球市场的需求、变化和动态。若要获取最新的中国出入境游市场和消费者洞察，请在下面注册成为会员。',
    },
    customChinaMarketResearch: {
      title: '专属旅游市场调研方案',
      detail: '正信的会员可根据需要，委托定制专属的旅游市场调研（费用自理）。定制旅游市场调研可包括品牌知名度调查、市场份额分析、主要市场参与者识别、产品渠道分析和建议、竞争对手分析、消费者细分识别、价格分析、战略规划和数据分析等。我们的最终目标是帮助您抓住旅游市场机遇，制定有针对性的市场营销战略，并协助您在旅游市场寻找合适的合作伙伴。若要定制正信的旅游市场调研，请在下方注册成为会员。',
    },
  },

  operatingOfficeAddress: '经营办公地址',
  pleaseEnterOperatingOfficeAddress: '请输入经营办公地址',
  pleaseEnterOperatingOfficeAddressEn: '请输入经营办公地址 (英文)',
  mainContactForTheChineseMarket: '联系人姓名',
  pleaseEnterMainContactForTheChineseMarket: '请输入联系人姓名',
  pleaseEnterMainContactForTheChineseMarketEn: '请输入联系人姓名 (英文)',
  mainContactForTheChineseMarketPhone: '联系人电话',
  pleaseEnterMainContactForTheChineseMarketPhone: '请输入联系人电话',
  mainContactForTheChineseMarketEmail: '联系人邮箱',
  pleaseEnterMainContactForTheChineseMarketEmail: '请输入联系人邮箱',
  pleaseEnterCorrectlyMainContactForTheChineseMarketEmail: '请正确填写联系人邮箱',
  pleaseEnterContactInformation: '请输入联系方式(座机)',
  officialWebsiteAddress: '官网地址',
  pleaseEnterOfficialWebsiteAddress: '请输入官网地址',
  termsAndConditions: '条款和条件',
  pleaseEnterTermsAndConditions: '请输入条款和条件',
  other: '其他',
  pleaseUploadQRCode: '请上传微信二维码',
  releaseTime: '发布时间',
  eligibleToJoinTheQualityEnterprise: '您已符合优质会员的加入要求和标准,请耐心等待官方审核后通知结果',
  youAreNotYetACorporateMemberOnlyCorporateMembersCanParticipateInTheQualityMemberSelection: '您还不是企业会员,只有企业会员才能参加优质会员评选',
  creationTime: '创建时间：',
  orderStatus: '订单状态',
  approvalComments: '审批备注',
  paymentConfirmation: '支付确认中,请稍后,请勿刷新页面或离开',
  paymentCompleted: '支付完成!',
  backToList: '返回列表',

  /**
   * 以下是之前表里的数据
   */
  homepage: '首页',
  corporate: '企业',
  search: '搜索',
  services: '服务',
  visitShanghai: '入境上海',
  login: '登录',
  signUp: '注册',
  bannertoptitle: '文旅跨境服务平台',
  joinUs: '加入我们',
  newsInformation: '旅业动态',
  viewMore: '查看更多',
  membershipPlans: '会员计划',
  memberbottom: '欢迎成为正信会员！不同会员可享受到不同的推荐和服务，请查看您的会员权益。',
  learnMore: '了解更多',
  visitors: '游客',
  individualMember: '个人会员',
  corporateMember: '正信会员',
  corporateVIP: '高级企业会员-VIP',
  publicInformation: '公开信息',
  training: '考核培训',
  corporateInformation: '基础资料',
  corporateCollaboration: '企业合作',
  platformPrivileges: '平台特权',
  industryInformation: '行业资讯',
  bestselection: '年度优质会员评选',
  rankingBoard: '排行榜',
  rankbottom: '正信优选全球的旅游服务企业名单',
  boardOfDirectors: '理事会',
  fAQ: '常见问题',
  contactUs: '联系我们',
  contactusbottom: '我们全天候随时准备为您服务 (当您通过电子邮件联系正信时，请在主题行中附上您的会员 ID)',
  homepageBannerIndustryNews: '主页横幅/行业新闻',
  keyMemberBenefitsTopServiceQualityMembers: '主要成员权益/顶级服务质量会员',
  memberEvents: '会员活动',
  destinationTrainingSystem: '目的地培训系统',
  tourGuideAndTravelPlannerCertification: '导游和旅行规划师证书',
  memberSearchEngine: '会员搜索引擎',
  memberContact: '会员联系方式',
  memberEngagementManagerSupport: '会员专属支持',
  smoothTravelLogo: '正信商标使用',
  corporateMemberIntroductionEvaluationComplaintReport: '企业会员自我介绍/评价/投诉报告',
  paymentGuaranteeSystem: '付款担保制度',
  chinaTravelIndustryInsights: '中国旅游行业洞察',
  chinaOutboundTravelInsurance: '中国出境旅游保险',
  smoothTravelEventsMemberEvents: '正信行业活动/企业会员活动',
  submittedForReviewSuccessfullyPleaseWaitForTheCompletionOfPlatformReview: '提交审核成功,请等待平台审核完毕!',
  tplatformprivileges: '平台特权',
  unionPayPayment: '银联支付',
  baoxian: '保险购买',
  insurancePurchase: '保险购买',
  viewall: '查看全部',
  joinAsAnIndividualMember: '加入个人会员',
  joinAsACorporateMember: '加入正信会员',
  joinAsAssociationOrDestinationTourismBureauMember: '加入协会/目的地旅游局会员',
  limitedToIndividualAccountsOnly: '只限个人账号加入',
  limitedToCorporateAccountsOnly: '只限企业账号加入',
  alreadyCorporateMember: '已是企业会员',
  alreadySmoothtravelMember: '已是正信会员',
  alreadyCommitAuditThanks: '您的会员申请已经提交至正信总部进行审核，我们将尽快处理，感谢您耐心等候。',
  trial: '申请试用',
  year: '年',
  pleasechoose: '请选择',
  personalcenter: '个人中心',
  detailedinformationsupplement: '补充信息',
  top10Selection: '年度Top10评选正在进行中，',
  clickhere: '点这里',
  toknowmore: '了解更多!!',
  accountregistration: '个人账号注册',
  associationDestinationTourismBureauRegistration: '协会/目的地旅游局账号注册',
  enteremail: '请输入邮箱',
  email: '邮箱',
  entertheverificationcode: '请输入验证码',
  verificationcode: '验证码',
  enteryourpassword: '请输入密码',
  password: '密码',
  enterarepeatpassword: '请输入重复密码',
  verifypassword: '重复密码',
  passworderrorpleasereenter: '重复密码错误，请重新输入',
  wrongPassword: '密码错误!',
  pleaseenteryourname: '请输入姓名',
  pleaseenteryournameEn: '请输入姓名 (英文)',
  name: '姓名',
  nameEn: '姓名 (英文)',
  individualresume: '个人简介',
  individualresumeEn: '个人简介 (英文)',
  sendcaptcha: '发送验证码',
  iagreeTermsofservice: '我同意服务条款和隐私政策',
  getcodenote: '注:点击获取验证码按钮,即可通过电子邮件接收您的验证码。检查您的收件箱并输入代码以完成注册。',
  iagree: '我同意',
  termsofservice: '服务条款',
  and: '和',
  close: '关闭',
  privacypolicy: '隐私政策',
  nextstep: '下一步',
  completeregistration: '完成注册',
  haveanaccountlogin: '已有账号,去登录',
  selectthetype: '请选择你要注册的账号类型',
  personalaccount: '个人账号',
  suitableforpersonal: '适合个人使用，如导游、领队等',
  corporateaccount: '企业账号',
  suitableforcompanyuse: '适合公司使用，加入正信与更多优质服务商建立合作及业务交流',
  note: '注意：账号类型选择注册成功后将不能更改',
  haveanaccount: '已有账号，去登录',
  enterprise: '企业账号注册',
  notesaved: '注意：请选择最合适的行业类别，行业类型选择保存后将不能更改。',
  identityWarning:
    '例如，您是一家提供地面交通、住宿预订等服务的旅行社，请在该项中选择“旅行社”，并在下面的“主营业务”项中选择您所涉及的详细业务信息，如有补充，请您在“公司简介”项中说明。',
  businessidentity: '行业类型',
  otherBusinesses: '主营业务 (可多选)',
  psBusinessidentity: '请选择行业类型',
  qybriefintroduction: '简介',
  pleaseProvideABriefPersonalIntroduction: '请输入个人简介',
  pleaseProvideABriefPersonalIntroductionEn: '请输入个人简介 (英文)',
  peqybriefintroduction: '请输入简介',
  shouyeemail: '邮箱号',
  pleaseenteryouremailnumber: '请输入邮箱号',
  shouyepass: '密码',
  shouyepleaseenteryourpassword: '请输入密码',
  noaccountyet: '还没有账号? 去注册',
  shouyeforgetthepassword: '忘记密码？',
  modifyemail: '修改邮箱',
  pleaseenterourwebsiteloginpassword: '请输入网站登录密码',
  pleaseentertheverificationcode: '请输入验证码',
  shouyeverificationcode: '验证码',
  sendverificationcode: '发送验证码',
  synextstep: '下一步',
  accomplish: '完成',
  verificationphone: '验证手机',
  pleaseenteryourphonenumber: '请输入手机号',
  acknowledgementoforder: '年度会员费支付',
  orderservices: '订购服务',
  seniorcorporatemember: '高级企业会员',
  servicehours: '服务期限',
  orderingenterprise: '订购企业',
  orderprice: '年度会员费',
  modofpayment: '支付方式',
  eChatPay: '微信支付',
  paybyAlipay: '支付宝支付',
  banktransfer: '银行转账',
  payment: '支付',
  goToPay: '去支付',
  wechat: '微信',
  alipay: '支付宝',
  turnonthecellphone: '打开手机',
  sweep: '扫一扫',
  shouyebanktransfer: '银行转账',
  fterpaymentiscompleted: '完成付款后，请上传付款图片回执或截图',
  fullnameofenterprise: '企业全称',
  paymentQrcode: '收款码',
  deqi: '德启（上海）管理咨询有限公司',
  bankaccount: '银行账户',
  depositbank: '开户银行',
  transfernote: '转账备注',
  bankAddress: '银行地址',
  swiftCode: 'Swift Code',
  pleasenote123: '（打款时请备注此信息）',
  paymentAmount: '付款金额',
  paymentreceipt: '付款回执',
  pleaseuploadapicture: '请上传付款回执图片或截图，支持 jpg、jpeg、png',
  submit: '提交',
  confirmPayment: '确认支付',
  good: '好的',
  corporatemembershiptrialapplication: '企业会员试用申请',
  inordertobetter: '为了更好的给不同类型的企业/企业会员试用是为加入正信平台的企业提供的一个限期的福利政策，我们会根据你填写资料的完整度作为一个考量标准给予申请的企业一定期限的企业会员试用资格。',
  filloutthepaperworkfirst: '先去填写资料',
  applynow: '立即申请',
  joinmembersuccessfully: '加入会员成功',
  applicationssuccessful: '申请成功，请等待官方审核',
  order: '我的订单',
  hset: '设置',
  hlanguage: '语言',
  plsSelectLanguage: '请选择语言',
  hlogout: '退出登录',
  languageswitch: '语言切换',
  logoutornot: '是否退出登录',
  hquit: '退出',
  councilis: '理事会是会员代表大会的执行机构，对会员代表大会负责',
  alllists: '所有榜单',
  confirmexit: '确认退出',
  registeredsuccessfully: '注册成功',
  personalidentity: '个人身份',
  associationDestinationTourismBureau: '协会/目的地旅游局',
  identity: '身份',
  psIdentity: '请选择身份',
  broadheading: '大类',
  industry: '行业',
  cancel: '取消',
  hsave: '保存',
  completeheregistration: '完成注册，去填写更多资料',
  completeRegistrationAndSubmitForReview: '完成注册，提交审核',
  pleaseenteranewemailaddress: '请输入新的邮箱',
  pleaseentername: '请输入名称',
  pleaseenternameEn: '请输入名称 (英文)',
  name1: '名称',
  resetpasswords: '重置密码',
  backtologin: '返回登录',
  qualificationCertificate: '资质证书',
  roomjingannew: '上海市静安区吴江路188号903室',
  runby: '运营方:',
  media: '战略合作伙伴：',
  termof: '服务条款',
  corporationname: '企业名',
  corporationnameEn: '企业 (英文) 名',
  enteraname: '请输入企业名',
  enteranameEn: '请输入企业 (英文) 名',
  associationName: '协会名',
  associationNameEn: '协会 (英文) 名',
  pleaseUploadProfilePicture: '请上传头像',
  pleaseEnterAssociationName: '请输入名称',
  pleaseEnterAssociationNameEn: '请输入（英文）名',
  footerAddress: '上海市静安区吴江路188号903室',
  stayloggedin: '保持登录',
  finish: '完成',
  onlyenterpris: '只限企业账号加入',
  paidPleaseWaitForOfficialReview: '已提交支付,请等待官方审核',
  submittedPleaseWaitForOfficialReview: '已提交,请等待官方审核',
  personalaccountonly: '只限个人账号加入',
  directtesting: '直接考试',
  youdonothavepermission: '请加入会员',
  approvalIsRequiredForAccess: '通过审核后才有权限',
  pleaseLoginFirst: '通过注册审核后才有权限',
  individualUsersDoNotHaveThatPermission: '个人用户没有该权限',
  ok: '好的',
  getcode: '获取验证码',
  subordinateRegion: '企业所在地',
  psSubordinateRegion: '请选择企业所在地',
  paymentTips0: '注：支付宝/微信/银行转账，支持货币为人民币。',
  paymentTips1: '请支付完成之后，务必保留相关截图或单据，并提交给平台运营人员审核。',
  paymentTips2: '注：Asia Pay的支付路径支持港币支付，点击跳转之后系统会自动通过实时汇率为您结算。汇率由Asia Pay或发卡行处理。',
  aliPayTips: '打开手机支付宝扫一扫',
  wechatPayTips: '打开手机微信扫一扫',
  ps:'注：',

  // 排行榜挪过来的
  outTop10ListsOfTheRecommendedCompanies7toGlobalTourismIndustry: '排行榜为全球的旅游同业推荐优中选优的优质服务企业名单',
  numberOfRankings: '榜单数量：',
  launchTime: '推出时间：',
  blacklist: '黑榜',
  ranklistone: '榜单是在旅游行业中对服务质量、专业能力和客户满意度等不同方面的表现或特定指标进行排名和评比。相关的榜单类型譬如：',
  ranklisttwo: '1. 旅游目的地榜单：评选受欢迎旅游目的地，通常基于目的地的景点数量、文化价值、旅游设施、环境质量等因素进行评估。',
  ranklistthree: '2. 住宿业榜单：评选好评住宿业或住宿业品牌，根据客户评价、服务质量、设施条件、地理位置和价格等因素进行排名。',
  ranklistfour: '3. 航空公司榜单：评选最佳服务航空公司，通常考虑航班准点率、客户满意度、服务质量、机上设施等指标。',
  ranklistfive: '4. 旅游景点榜单：评选最佳旅游景点，考虑景点的美景、历史价值、游客体验和服务质量等方面。',
  ranklistsix: '5. 旅游活动榜单：评选最佳旅游活动或节日，根据活动的独特性、影响力、参与度和游客反馈等因素进行排名。',
  ranklistseven: '6. 最佳旅行社榜单：评选出在旅游行业中表现出色的旅行社，根据客户评价、服务质量、产品多样性和创新性等因素进行排名。',
  ranklisteight: '7. 高端旅行社榜单：评选出专注于高端旅游市场并提供奢华旅行体验的旅行社，考虑包括行程定制能力、服务水平、高端酒店合作等因素。',
  ranklistnine: '8. 旅行社网络榜单：评选最大或最具影响力的旅行社网络或连锁机构，基于其分支机构的数量、全球覆盖范围和市场份额等指标进行排名。',
  ranklisttwenty: '10. 消费者评价榜单：基于消费者的真实评价和反馈，评选出受欢迎的旅行社，客户满意度、服务态度、行程安排和售后支持等是评估的重点。',
  ranklisttwentyone: '11. 国家或地区的最佳旅行社榜单：评选出在特定国家或地区内表现突出的旅行社，通常侧重考虑他们对当地旅游资源的了解、专业知识和服务质量。',
  ranklisttwentytwo: '这些榜单可以为业界提供参考和指导，帮助他们做出更好的旅行决策。然而，每个榜单都有其评选标准和偏好，平台将综合考虑多个来源的信息，以获取更全面和客观的观点。',
  ranklist: '榜单',
  listintroduction: '榜单介绍',

  // 理事会挪过来的
  councilone: '理事会是正信的常设权力机构，由正信会员大会选举产生，对正信负责。理事会由会长、副会长若干人，理事若干人组成。理事会的组成人员从业10年以上，具有良好的职业道德、较高的业务水平、较强的议事能力和奉献精神，热心行业公益活动的会员代表中选举产生。每届理事任期与当届会员代表任期相同。理事候选人由各会员按相应当名额推选或者选举产生。',
  'thePlatformEstablishesAnExecutiveCouncil,WhichIsElectedFromAmongTheDirectors.DuringTheRecessOfTheBoardOfDirectors,TheExecutiveCouncilExercisesThePowersOfTheBoardAndIsAccountableToTheBoard': '本平台设立常务理事会。常务理事从理事中选举产生。在理事会闭会期间，常务理事会行使理事会职权，对理事会负责。',
  boardMembers: '理事会成员',
  boardComposition: '理事会构成',
  boardCompositionOne: '1. 主席（1名）： 负责整体领导和决策，主持理事会会议，确保平台的战略目标得到实现。',
  boardCompositionTwo: '2. 副主席（1名）： 协助主席工作，负责在主席缺席时履行职责，以及特定项目的领导。',
  boardCompositionThree: '3. 秘书组：',
  boardCompositionThreeOne: '（1）秘书长（1名）： 管理秘书组工作，协助主席和副主席，负责理事会文件和决议的记录。',
  boardCompositionThreeTwo: '（2）秘书（2名）： 协助秘书长，负责文件管理、会议准备和相关事务。',
  boardCompositionFour: '4. 理事（5名）： 参与决策制定，提供战略建议，代表平台在特定领域发言。',
  boardCompositionFive: '5. 常务理事（3名）： 参与协助执行和管理平台日常运营。',
  membershipRequirements: '入会要求',
  counciltwo: '(一) 取得本平台会员资格',
  councilthree: '(二) 在旅游业的业务领域内有一定的影响力',
  membershipRequirementsThree: '(三) 具备国际视野和合作经验',
  membershipRequirementsFour: '(四) 积极参与平台活动和倡议',
  electionAndRemovalOfDirectors: '理事的选举和罢免',
  councilfour: '(一) 第一届理事由发起人申请成立时的会员共同提名：',
  councilfourOne: '• 发起人应在成立平台时明确提名第一届理事，确保代表各方利益和专业领域。',
  councilfive: '(二) 根据会员代表大会的授权：',
  councilfiveOne: '• 理事的选举和罢免由会员代表大会授权执行，确保决策的合法性和透明性。',
  councilfiveTwo: '• 会员代表大会可通过投票或其他合法方式选举理事。',
  councilfiveThree: '(三) 选举程序：',
  councilfiveFour: '• 提名阶段：会员可提名具有一定影响力和专业经验的候选人。',
  councilfiveFive: '• 选举阶段：会员代表大会通过投票选举理事，确保广泛代表性。',
  councilfiveSix: '(四) 罢免程序：',
  councilfiveSeven: '• 罢免提案：会员代表大会成员或理事会成员可以提出罢免提案。',
  councilfiveEight: '• 理事会审议：理事会对罢免提案进行审议，确保程序公正。',
  councilfiveNine: '• 会员代表大会投票：会员代表大会通过投票表决是否罢免理事。',
  councilfiveTen: '(五) 增补程序：',
  councilfiveEleven: '• 理事会在任期内可以根据需要增补理事，但增补人数不得超过原理事总数的1/5。',
  councilfiveTwelve: '• 增补程序需经过理事会同意，并在下一届会员代表大会上报备。',
  councilfiveThirteen: '(六) 单位调整代表：',
  councilfiveFourteen: '• 理事单位如需调整代表，应书面通知本平台，并报备理事会或常务理事会。',
  councilfiveFifteen: '• 调整代表的单位，若同时为常务理事，其代表应同时调整。',
  // councilsix: '每个理事单位只能选派一名代表担任理事。单位调整理事代表，由其书面通知本平台，报理事会或者常务理事会备案。该单位同时为常务理事的，其代表一并调整。',
  rightsOfDirectors: '理事的权利',
  councilseven: '(一) 理事会的选举权、被选举权和表决权:',
  councilsevenOne: '• 选举权：有权参与理事会成员的选举程序，确保合理代表性。',
  councilsevenTwo: '• 被选举权：有资格成为理事会成员的候选人，能够为平台做出贡献。',
  councilsevenThree: '• 表决权：在理事会会议上享有平等的表决权，参与决策和平台事务的讨论。',
  councileight: '(二) 对本平台工作情况、财务情况、重大事项的知情权，建议权和监督权;',
  councileightOne: '• 知情权：有权掌握平台的工作进展、财务状况和重大决策，确保透明度。',
  councileightTwo: '• 建议权：有权提出对平台工作的建议和意见，促进平台的发展和改进。',
  councileightThree: '• 监督权：有权对平台工作进行监督，确保平台按照既定目标和规范运行。',
  councilnine: '(三) 参与制定行业优质服务标准的制定，提出意见建议;',
  councilnineOne: '• 制定权：有权参与制定行业优质服务标准，推动整个行业的提升。',
  councilnineTwo: '• 意见建议：有权提出对行业标准的意见和建议，以确保标准的合理性和实用性。',
  councilten: '(四) 向会长或理事会提出召开临时会议的建议权。',
  counciltenOne: '• 提议权：有权向会长或理事会提出召开临时会议的建议，以应对紧急事务。',
  counciltenTwo: '• 参与权：在临时会议上有权参与讨论和决策，确保及时有效的应对问题。',
  rightsOfDirectorsFive: '(五) 参与平台重要活动和宣传:',
  rightsOfDirectorsFiveOne: '• 参与活动：有权参与平台组织的重要活动，展示形象和推动合作。',
  rightsOfDirectorsFiveTwo: '• 宣传权：有权参与平台宣传工作，提升在业界和公众中的形象和认知。',
  rightsOfDirectorsSix: '(六) 享有知识产权和商业机密的保护权:',
  rightsOfDirectorsSixOne: '• 知识产权：有权享有对于平台发展所做出贡献的知识产权的保护。',
  rightsOfDirectorsSixTwo: '• 商业机密：有权保护和享有平台的商业机密，确保平台的竞争力和独特性。',
  dutiesOfDirectors: '理事的义务',
  councileleven: '理事应当遵守法律、法规和本章程的规定，忠实履行职责、维护本平台利益，并履行以下义务：',
  counciltwelve: '(一) 出席理事会会议，执行理事会决议:',
  counciltwelveOne: '• 出席义务：理事应定期参加理事会会议，确保及时了解平台的发展和决策情况。',
  counciltwelveTwo: '• 执行决议：积极执行理事会通过的决议，确保平台方向的一致性和稳定性。',
  councilthirteen: '(二) 在职责范围内行使权利，不越权:',
  councilthirteenOne: '• 权责一致：行使权利时应当在自身职责范围内合理行使，确保决策的合法性和有效性。',
  councilthirteenTwo: '• 不越权：避免越权行使权利，保持决策的合理性和透明度。',
  councilfourteen: '(三) 不利用理事职权谋取不正当利益:',
  councilfourteenOne: '• 廉洁操守：不得利用理事职权谋取个人私利，维护平台的公正和廉洁。',
  councilfifteen: '(四) 不从事损害本平台合法利益的活动:',
  councilfifteenOne: '• 维护利益：理事应当积极维护本平台的合法权益，不从事任何损害平台利益的活动。',
  councilsixteen: '(五) 不得泄露在任职期间所获得的涉及本平台的保密信息，但法律、法规另有规定的除外:',
  councilsixteenOne: '• 保密义务：保守平台的商业机密和敏感信息，确保平台信息的安全性和稳定性。',
  councilseventeen: '(六) 谨慎、认真、勤勉、独立行使被合法赋予的职权:',
  councilseventeenOne: '• 谨慎勤勉：在履行职责时应谨慎认真，保持勤勉的态度，确保决策的准确性和可行性。',
  councilseventeenTwo: '• 独立行使权利：行使被合法赋予的职权时要保持独立性，不受不正当影响。',
  councileighteen: '(七) 接受监事对其履行职责的合法监督和合理建议:',
  councileighteenOne: '• 监督接受：理事应积极接受监事对其履行职责的合法监督，配合监事的工作。',
  councileighteenTwo: '• 合理建议：对于监事的合理建议，理事应予以认真考虑和积极改进。',
  dutiesOfDirectorsEight: '(八) 积极参与平台发展规划和战略制定:',
  dutiesOfDirectorsEightOne: '• 规划参与：理事应积极参与平台的长期发展规划和战略制定，为平台的未来方向提出建设性建议和意见。',
  dutiesOfDirectorsEightTwo: '• 战略执行：与其他理事共同协作，确保制定的发展规划和战略是全面而有利的。',
  dutiesOfDirectorsNine: '(九) 代表平台参与行业及社会事务:',
  dutiesOfDirectorsNineOne: '• 行业代表：作为平台的代表，理事应积极参与相关行业的活动和事务，推动平台在行业内的影响力。',
  dutiesOfDirectorsNineTwo: '• 社会参与：积极参与社会公益事业，为提升文旅质量服务形象作出贡献，与社会建立良好关系。',
  powersOfTheBoardOfDirectors: '理事会的职权',
  councilnineteen: '(一) 执行会员代表大会的决议;',
  counciltwenty: '(二) 选举和罢免常务理事、负责人:',
  counciltwentyone: '(三) 决定其他重大事项。',
  powersOfTheBoardOfDirectorsFour: '(四) 制定和审批平台内部管理制度',
  powersOfTheBoardOfDirectorsFive: '(五) 审核和批准合作协议和重要合同',
  otherProvisions: '其他说明',
  counciltwentytwo: '(一) 换届和会期：',
  counciltwentytwoOne: '• 理事会与会员代表大会任期相同，与会员代表大会同时换届。',
  counciltwentythree: '(二) 理事会会议条件:',
  counciltwentythreeOne: '• 理事会会议须有2/3以上理事出席方能召开。其决议须经到会理事2/3以上表决通过方能生效。',
  counciltwentyfour: '(三) 失去资格条件:',
  counciltwentyfourOne: '• 理事2次不出席理事会会议，自动丧失理事资格。',
  counciltwentyfive: '(四) 常务理事的选举方式:',
  counciltwentyfiveOne: '• 常务理事由理事会采取无记名投票方式从理事中选举产生。',
  counciltwentysix: '(五) 负责人的选举方式:',
  counciltwentysixOne: '• 负责人由理事会采取无记名投票方式从常务理事中选举产生。',
  counciltwentyseven: '(六) 罢免程序:',
  counciltwentysevenOne: '• 罢免常务理事、负责人，须经到会理事2/3以上投票通过。',
  counciltwentyeight: '(七) 选举程序:',
  counciltwentyeightOne: '• 选举常务理事、负责人，按得票数确定当选人员。但当选的得票数不得低于总票数的2/3。',
  // counciltwentynine: '但当选的得票数不得低于总票数的2/3。',
  councilthirty: '(八) 会议频率:',
  councilthirtyOne: '• 理事会每年至少召开1次会议，情况特殊的，可采用线上形式召开。',
  councilthirtyone: '(九) 临时会议:',
  councilthirtyoneOne: '• 经会长或者1/5的理事提议，应当召开临时理事会会议。',
  otherProvisionsTen: '(十) 临时会议主持:',
  otherProvisionsTenOne: '• 会长不能主持临时理事会会议，由提议召集人推举本平台一名负责人主持会议。',
  executiveCouncil: '常务理事会',
  councilthirtythree: '本平台设立常务理事会，是理事会的重要组成部分，由理事会选举产生，人数为3人。常务理事会行使理事会的关键职权，在理事会闭会期间对理事会负责。',
  councilthirtyfour: '(一) 职权范围：',
  councilthirtyfourOne: '• 常务理事会行使理事会多项职权，包括但不限于紧急决策、日常管理、财务审批等。',
  councilthirtyfive: '(二) 任期和换届：',
  councilthirtyfiveOne: '• 常务理事会与理事会任期相同，与理事会同时进行选举和换届。',
  councilthirtysix: '(三) 召开会议条件：',
  councilthirtysixOne: '• 常务理事会会议须有2/3以上常务理事出席方能召开，其决议须经到会常务理事2/3以上表决通过方能生效。',
  councilthirtyseven: '(四) 失去资格条件：',
  councilthirtysevenOne: '• 常务理事2次不出席常务理事会会议，自动丧失常务理事资格。',
  councilthirtyeight: '(五) 会议频率：',
  councilthirtyeightOne: '• 常务理事会至少每6个月召开1次会议，情况特殊的，可采用通讯形式召开。',
  councilthirtynine: '(六) 临时会议:',
  councilthirtynineOne: '• 经会长或1/3以上的常务理事提议，应当召开临时常务理事会会议。',
  executiveCouncilSeven: '(七) 临时会议主持:',
  executiveCouncilSevenOne: '• 会长不能主持临时常务理事会会议，由提议召集人推举本平台1名负责人主持会议。',
  council: '理事会',
  councilthirtytwo: '会长不能主持临时理事会会议，由提议召集人推举本平台一名负责人主持会议。',

  // source orderFormSettings 挪过来的
  enterpriseMembershipRecharge: '企业会员充值',
  loginEmail: '登录邮箱',
  modify: '修改',
  loginMobileNumber: '登录手机',
  loginPassword: '登录密码',
  pleaseEnterWebsiteLoginPassword: '请输入网站登录密码',
  pleaseEnterNewEmail: '请输入新的邮箱',
  'successMessage,E.g.,RegisteredSuccessfully/InformationModifiedSuccessfully/PaymentSuccessful': '成功提示，例“注册成功/信息修改成功/支付成功”',
  'warningMessage,E.g.,InsufficientPermissions': '警告提示，如“权限不足”',
  myorder: '我的订单',
  corporatememberpurchase: '普通企业会员购买',
  paymentamount: '支付金额：',
  ordernumber: '订单编号：',
  expirationTime: '到期时间：',
  modeofpayment: '支付方式：',
  wechatpay: '微信支付',
  set: '设置',
  notadded: '未添加',
  add: '添加',
  haveset: '已设置',

  // source usercenter 挪过来的
  myOrders: '我的订单',
  settings: '设置',
  language: '语言',
  logout: '退出登录',
  editProfile: '编辑资料',
  leaveEnterprise: '退出企业',
  personalMembership: '个人会员',
  contactInformation: '联系方式',
  myExpertCertificates: '我的专家证书',
  myTrainingRecords: '我的培训记录',
  expirationime: '到期时间：',
  ontinuetheexamination: '继续考试',
  alreadypassed: '已通过',
  qualificationcertificate: '资质证书',
  permanentvalidity: '永久有效',
  mycredentials: '我的资质证书',
  sucasID: '如身份证/护照、领队证/导游证等、职业培训证书、目的地和培训证书或资料、曾获得的荣誉等。支持 jpg、jpeg 和 png 图片及 pdf 文件。',
  gotoCertificate: '暂无证书，快去添加证书吧',
  notYet: '暂未获得',
  goaddinformation: '暂无信息,快去添加吧',
  website: '个人网址',
  telephone: '联系电话',
  /**
   * 表里数据结束
   */
}
