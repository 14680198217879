/**
 * eggTest
 */
import axios from '@/request/http'
import { userAdminInfo } from '@/store/modules/admin'

const admin = {
  // 注册
  register(data) {
    return axios.post(`/api/members/register`, data)
  },
  // 登录
  login(data) {
    return axios.post(`/api/members/login`, data)
  },
  dictionarys(params) {
    return axios.get(`/open/dictionarys/all`, { params })
  },
  // 语言
  language(params) {
    return axios.get(`/api/language`, { params })
  },
  // 设置当前语言
  setLanguage() {
    return axios.post(`/api/language`)
  },
  // 获取与验证码
  getPhoneCode(params) {
    return axios.get(`/api/code`, { params })
  },
  // 验证验证码
  verifyPhoneCode(data) {
    return axios.post(`/api/code`, data)
  },
  // 获取当前用户信息
  getUserInfo() {
    return new Promise(resolve => {
      axios.get(`/api/members`).then(res => {
        // 写入 store
        userAdminInfo().setUserInfo(res.data)

        resolve(res)
      })
    })
  },
  getOtherUser(params) {
    return axios.get(`/api/members/${params.id}`)
  },
  refreshToken() {
    return axios.put(`/api/refreshToken`)
  },
  amendEnterprise(data) {
    return axios.put(`/api/members/enterprise`, data)
  },
  // amendEnterpriseExtend(data) {
  //   return axios.put(`/api/members/enterpriseExtend`, data)
  // },
  // amendMember() {
  //   return axios.put(`/api/members/info`)
  // },
  amendMemberExtend(data) {
    return axios.put(`/api/members/base`, data)
  },

  // 附件
  getMemberAttachment: params => axios.get('/api/members/attachment/', params),
  addMemberAttachment: data => axios.post(`/api/members/attachment`, data),
  removeMemberAttachment: id => axios.delete(`/api/members/attachment/${id}`),

  labelsList(params) {
    return axios.get(`/api/label`, { params })
  },
  creatLabel(data) {
    return axios.post(`/api/label`, data)
  },
  delLabel(data) {
    return axios.delete(`/api/label/${data}`)
  },
  // 证书
  setCertificate(data) {
    return axios.post(`/api/certificate`, data)
  },

  // 产品
  addProduct(data) {
    return axios.post(`/api/prods`, data)
  },
  editProduct(data) {
    return axios.put(`/api/prods/${data.id}`, data.data)
  },
  getProduct(params) {
    return axios.get('/api/prods', { params })
  },
  getProductDetail(data) {
    return axios.get(`/api/prods/${data}`)
  },
  delProduct(id) {
    return axios.delete(`/api/prods/${id}`)
  },

  // 更多资料
  addmoreData(data) {
    return axios.post(`/api/informations`, data)
  },
  getmoreData(params) {
    return axios.get(`/api/infomations`, { params })
  },
  putmoreData(data) {
    return axios.put(`/api/infomations/${data.id}`, data.form)
  },
  delmoreData(id) {
    return axios.delete(`/api/infomations/${id}`)
  },
  getMoreDataDetail(id) {
    return axios.get(`/api/infomations/${id}`)
  },

  // 合作列表
  cooperatesList(params) {
    return axios.get(`/api/cooperates`, { params })
  },
  staffList(params) {
    return axios.get(`/api/staff`, { params })
  },
  evaluateList(params) {
    return axios.get(`/api/evaluate`, { params })
  },
  setevaluate(data) {
    return axios.post(`/api/evaluate`, data)
  },
  whatTypeOfCode(params) {
    return axios.get(`/api/what-type-of-code`, { params })
  },
  tryOut(data) {
    return axios.post(`/api/try-out`, data)
  },

  // 验证折扣码
  verificationOfficeCode(code) {
    return axios.get(`/api/orders/office-code/`, { params: { code } })
  },
  // 订单
  sendOrder(data) {
    return axios.post(`/api/orders`, data)
  },
  payment(params) {
    return axios.put(`/api/orders/receipt/${params.id}`, params.data)
  },
  // membersInfo(data) {
  //   return axios.put(`/api/members/info`, data)
  // },
  orderList(params) {
    return axios.get('/api/member-log', { params })
  },
  verifyPassword(params) {
    return axios.post(`/api/members/${params.memberId}/v-password`, params.data)
  },
  editEmail(data) {
    return axios.put(`/api/user`, data)
  },
  getEmail() {
    return axios.get(`/api/user`)
  },
  putPassword(data) {
    return axios.put(`/api/members/password`, data)
  },
  unloadCerti(params) {
    return axios.get('/api/certificate/types', { params })
  },
  delCerti(params) {
    return axios.delete(`api/certificate/${params.id}`)
  },
  // 验证是否订单完成
  verifyOrder(id) {
    return axios.get(`/api/orders/${id}`)
  },
  // 验证PayPal
  verifyPaypal(data) {
    return axios.put(`/api/orders/paypal/${data.id}`, data.data)
  },
  verifyAsiaPay(data) {
    return axios.post(`/open/asia-pay`, data)
  },

  // 宣传文章
  publicityArticleList: params => axios.get('/api/publicity-article', { params }), // 查
  addPublicityArticle: data => axios.post('/api/publicity-article', data), // 增
  deletePublicityArticle: id => axios.delete(`/api/publicity-article/${id}`), // 删
  getPublicityArticleDetail: id => axios.get(`/api/publicity-article/${id}`), // 详情
  editPublicityArticle: (id, data) => axios.put(`/api/publicity-article/${id}`, data) // 改
}
export default admin
