import { createApp } from 'vue'
import ElementPlus, { ElMessage } from 'element-plus'

import App from './App.vue'
import router from './router'
import './permission'
// element-theme
import './styles/scss/element/index.scss'
import '@/assets/fonts/fonts.css' // 字体包
import '@/request/http.js'
import store from './store'
import i18n from '@/i18n'

const app = createApp(App)

const ElMessageCfg = { duration: 2000, customClass: 'globalElMessageStyle' }
app.config.globalProperties.$ElMessage = msg => {
  return ElMessage({ message: msg, ...ElMessageCfg })
}
app.config.globalProperties.$ElMessage.success = msg => {
  return ElMessage.success({ message: msg, ...ElMessageCfg })
}
app.config.globalProperties.$ElMessage.warning = msg => {
  return ElMessage.warning({ message: msg, ...ElMessageCfg })
}
app.config.globalProperties.$ElMessage.info = msg => {
  return ElMessage.info({ message: msg, ...ElMessageCfg })
}
app.config.globalProperties.$ElMessage.error = msg => {
  return ElMessage.error({ message: msg, ...ElMessageCfg })
}
// 过滤富文本为页面普通展示
app.config.globalProperties.richTextFilter = value => {
  const regx = /<\/?[a-zA-Z]+(\s+[a-zA-Z]+=".*")*>|&[a-zA-Z]+;/g

  return value.replaceAll(regx, '')
}

app.use(ElementPlus).use(store).use(router).use(i18n).mount('#app')
