export default {
  labelMaxNote: 'Select up to 6 tags based on the highest number of outbound travelers or revenue.',
  labelCustomNote: 'If you haven\'t found applicable tags, please fill in the custom tags.',
  frequentlySearch: 'Frequently search',
  yes: 'yes',
  no: 'no',
  chinese: 'Chinese',
  english: 'English',
  other: 'other',
  pleasechoose: 'Please choose',
  uploadProfilePicture: 'Upload logo as avatar',
  customtag: 'Custom tag：',
  label: 'label:',
  enteracustomlabel: 'Please enter a custom label',
  pleaseEnter: 'Please enter ',
  alipay: 'Alipay',
  wechat: 'WeChat',
  transfer: 'Transfer',
  creditCard: 'Credit card',
  pendingPayment: 'Pending payment',
  paid: 'Paid',
  inApprovalProcess: 'In approval process',
  paymentCancel: 'Payment cancel',
  paymentSuccessful: 'Payment successful',
  download: 'Download',
  china: 'China',
  international: 'International & Hong Kong, Macau, Taiwan',
  province: 'Province',
  city: 'City',
  continent: 'Continent',
  countriesAndRegions: 'Countries and regions',
  relatedResults: 'Related results',
  maxUploadSize: 'Maximum upload %s',
  pleaseInput: 'Please input',
  pleaseProvideYourEmailAddress: 'Please provide your email address',
  pleaseEnterTheCorrectEmailAddress: 'Please enter the correct email address',
  sentSuccessfully: 'Sent successfully',
  uploadArticleCover: 'Upload article cover (recommended jpg/jpeg/png image, size 562x256)',
  psUploadArticleCover: 'Please upload article cover',
  pleaseFillInTheArticleTitle: 'Please fill in the article title in Chinese',
  pleaseFillInTheArticleTitleEn: 'Please fill in the article title',
  anonymousUser: 'Anonymous user',
  credentialsPendingReview: 'Credentials pending review',
  rejected: 'Rejected',
  refunded: 'Refunded',
  picture: 'Picture',
  cooperate: 'Cooperate',
  registrationBenefits24: '24 years of registration and trial in the first half of the year',
}
