export default {
  /**
   * 以下是之前表里的数据
   */
  theOnlineTrainingExamFo: 'The online training exam for tourism experts is divided into several parts, and only after answering all the questions correctly for each part can it be saved for the next part of the training assessment. You can conduct the training several times, starting from the last saved chapter section and starting the next stage of training assessment. Complete all the Q&A sections of the chapter to obtain the electronic expert certificate for this training.Obtaining all correct answers will earn you the expert certificate in that category.',
  nextQuestion: 'Next question',
  singlechoiceQuestion: 'Single-choice question',
  multiplechoiceQuestion: 'Multiple-choice question',
  'section2Progress:': 'Section 2 Progress:',
  submit: 'Submit',
  hint: 'Hint',
  okay: 'Okay',
  'thereAreIncorrectAnswersInThisSection.PleaseReattemptAndSubmitTheCorrectAnswers.': 'There are incorrect answers in this section. Please reattempt and submit the correct answers.',
  'congratulations!': 'Congratulations!',
  'youHavePassedTheExamAndObtainedTheExpertCertificate.': 'You have passed the exam and obtained the expert certificate.',
  progress: 'Progress：',
  youpassedtheexamandgot: 'You passed the exam and got',
  expertcertificate: 'Expert certificate',
  mistakes: 'mistake',
  mistakesNum: 'There are still %d questions unanswered correctly. Please try again.',
  /**
   * 表里数据结束
   */
}
