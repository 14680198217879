export default {
  ifApplicablePleaseInputYourCodeHere: 'If applicable, please input your code here.',
  memberID: 'Member ID',
  promotionCode: 'Code (optional)',
  pleaseEnterPromotionCode: 'Please input your code',
  unableToAdd: 'Unable to add',
  personalData: 'Personal Data',
  detailsOfCooperation: 'Details of cooperation',
  cooperationPartner: 'Cooperation Partner',
  cooperativeProducts: 'Cooperative Products',
  offTheShelf: 'Off the shelf',
  cooperationMaterials: 'Cooperation Materials',
  cooperationMaterialsUpload: 'Cooperation materials can be uploaded, such as contracts, agreements, etc. Supported formats include jpg, jpeg and png images, and pdf files.',
  myEvaluationOfCooperation: 'My evaluation of cooperation',
  peAPartnershipEvaluation: 'Please enter a partnership evaluation',
  anonymousReviews: 'Anonymous reviews',
  acceptCooperation: 'Accept Cooperation',
  refusalToCooperation: 'Refusal To Cooperation',
  scanQrCodeAddWeChat: 'Scan the QR code to add WeChat.',
  uniqueSellingPoint: 'Unique Selling Point in Chinese',
  uniqueSellingPointEn: 'Unique Selling Point',
  numberOfTourGuides: 'Number of Tour Guides in Chinese',
  numberOfTourGuidesEn: 'Number of Tour Guides',
  numberOfQualifiedTravelPlanners: 'Number of Qualified Travel Planners in Chinese',
  numberOfQualifiedTravelPlannersEn: 'Number of Qualified Travel Planners',
  membershipInWhichAssociations: 'Membership in Which Associations (e.g. NTA, VIRTUOSO) in Chinese',
  membershipInWhichAssociationsEn: 'Membership in Which Associations (e.g. NTA, VIRTUOSO)',
  complaintContact: 'Complaint Contact in Chinese',
  complaintContactEn: 'Complaint Contact',
  emergencyContact: 'Emergency Contact in Chinese',
  emergencyContactEn: 'Emergency Contact',
  numberOfSuccessfullyArrangedVisitorsToChina: 'Number of Successfully Arranged Visitors to China in Chinese',
  numberOfSuccessfullyArrangedVisitorsToChinaEn: 'Number of Successfully Arranged Visitors to China',
  totalNumberOfVehicles: 'Total Number of Vehicles in Chinese',
  totalNumberOfVehiclesEn: 'Total Number of Vehicles',
  totalPassengerCapacityOfVehicles: 'Total Passenger Capacity of Vehicles in Chinese',
  totalPassengerCapacityOfVehiclesEn: 'Total Passenger Capacity of Vehicles',
  chainOrIndependent: 'Chain or Independent in Chinese',
  chainOrIndependentEn: 'Chain or Independent',
  chainName: 'Chain Name in Chinese',
  chainNameEn: 'Chain Name',
  accommodationRatingStarRating: 'Accommodation Rating/Star Rating in Chinese',
  accommodationRatingStarRatingEn: 'Accommodation Rating/Star Rating',
  numberOfRooms: 'Number of Rooms in Chinese',
  numberOfRoomsEn: 'Number of Rooms',
  numberOfBeds: 'Number of Beds in Chinese',
  numberOfBedsEn: 'Number of Beds',
  restaurantType: 'Restaurant Type in Chinese',
  restaurantTypeEn: 'Restaurant Type',
  cuisineRegionFlavor: 'Cuisine Region/Flavor in Chinese',
  cuisineRegionFlavorEn: 'Cuisine Region/Flavor',
  typeOfHonorsMichelinStarRating: 'Type of Honors/Michelin Star Rating in Chinese',
  typeOfHonorsMichelinStarRatingEn: 'Type of Honors/Michelin Star Rating',
  maximumSimultaneousDiningCapacity: 'Maximum Simultaneous Dining Capacity in Chinese',
  maximumSimultaneousDiningCapacityEn: 'Maximum Simultaneous Dining Capacity',
  businessHours: 'Business Hours in Chinese',
  businessHoursEn: 'Business Hours',
  operationalRoutes: 'Operational Routes in Chinese',
  operationalRoutesEn: 'Operational Routes',
  totalNumberOfAircraft: 'Total Number of Aircraft in Chinese',
  totalNumberOfAircraftEn: 'Total Number of Aircraft',

  tourLeaderProof: 'Tour Leader Certificate',
  tourGuideCertificate: 'Tour Guide Certificate',
  tourLeaderCertificate: 'Tour Leader Certificate',
  itineraryPlannerTrainingCertificate: 'Itinerary Planner Training Certificate',
  taxPaymentReceipt: 'Tax Payment Receipt',
  vehicleOperationCertificateIfOwnCar: 'Vehicle Operation Certificate (if applicable)',
  allVehicleInsurancePhotosOfAllVehiclesAllVehicleModelsAndProfiles: 'All Vehicle Insurance, Photos of All Vehicles, All Vehicle Models and Profiles (if applicable)',
  vehicleOperationCertificate: 'Vehicle Operation Certificate',
  vehiclePhotosVehicleProfiles: 'Vehicle Photos/Vehicle Profiles',
  foodPermit: 'Food Permit',
  coachDriverPhotosOtherDocuments: 'Coach Driver Photos and Other Documents',
  commercialVehicleOperationPermit: 'Commercial Vehicle Operation Permit',

  unlimited: 'unlimited',
  evaluationContentIsRequired: 'Evaluation content is required',
  groupTours: 'Group tours',
  airTicketAndHotel: 'Air ticket and hotel',
  airTicket: 'Air ticket',
  accommodation: 'Accommodation',
  touristAttraction: 'Tourist attraction',
  socialAccounts: 'Social accounts',
  weibo: 'Weibo',
  wechat: 'WeChat',
  wechatAccount: 'WeChat account',

  renewMembership: 'Renew Membership',

  /**
   * 以下是之前表里的数据
   */
  identitySelection: 'Identity selection',
  regionSelection: 'Region selection',
  pleaseSelect: 'Please select',
  pleaseEnterPersonalWebsite: 'Please enter personal website',
  companyName: 'Company name (Chinese)',
  companyNameEn: 'Company name (English)',
  companyType: 'Industry',
  operatingStatus: 'Operating status',
  companyLocation: 'Company location',
  pleaseEnterIndustryLicenseNumber: 'Please enter industry license number',
  pleaseEnterRegisteredAddress: 'Please enter registered address',
  pleaseEnterBusinessAddress: 'Please enter business address',
  pleaseEnterCompanyContactInformation: 'Please enter company contact information',
  pleaseEnterContactInformation: 'Please enter contact information',
  pleaseEnterCompanyFaxNumber: 'Please enter company fax number',
  pleaseEnterCompanyContactPerson: 'Please enter company contact person',
  'pleaseEnterComplaintContactPerson(name/ContactNumber/Email)': 'Please enter complaint contact person (name / contact number / email address)',
  pleaseEnterCompanyWebsite: 'Please enter company website',
  starRating: 'Star rating',
  features: 'Features',
  isItAGroupCompany: 'Is it a group company',
  'ifItIsAGroupCompany,PleaseEnterTheGroupName': 'If it is a group company, please enter the group name',
  isThirdpartyFundSupervisionAvailable: 'Is third-party fund supervision available',
  companySize: 'Company size',
  cateringServices: 'Catering services',
  serviceLanguages: 'Service languages',
  serviceLanguagesEn: 'Service languages',
  psServiceLanguages: 'Please enter service languages, e.g.: Chinese, English, Thai, German, etc in Chinese',
  psServiceLanguagesEn: 'Please enter service languages, e.g.: Chinese, English, Thai, German, etc',
  'industry-MajorCategory-Identity': 'Industry - Major category - Identity',
  domestic: 'Domestic',
  international: 'International',
  pleaseEnterProductPrice: 'Please enter product price',
  enterpriseInfoots: 'Add more descriptions or names and content of services according to product needs (e.g., Number of service participants - 20 people)',
  detailedDescription: 'Detailed description',
  pleaseFillInIfNeeded: 'Itinerary, please fill in if needed',
  basicsdata: 'Basic info',
  enterpriseinformation: 'Company info',
  detailedinformationsupplement: 'Supplement info',
  qualificationcertificate: 'Qualification ',
  product: 'Products',
  pleaseenterthebusinessname: 'Please enter the business name (Chinese)',
  pleaseenterthebusinessnameEn: 'Please enter the business name (English)',
  unabletosavechange: 'This section cannot be changed. For any changes needed, please',
  contactthecustomerservice: 'contact our support team for <br/>assistance.',
  individualresume: 'Individual resume',
  briefintroduction: 'Brief Description of your Organization in Chinese',
  briefintroductionEn: 'Brief Description of your Organization',
  enterpriselabel: 'Label',
  save: 'Save',
  uploadProfilePicture: 'Upload logo as avatar',
  addlable: 'Add label',
  selected: 'Selected labels (up to 6) :',
  destinationtag: 'Destination tag:',
  input1: 'Please enter your business license number',
  inputtop1: 'Business license number',
  registeredaddress: 'Registered address',
  businessaddress: 'Business address',
  enterprisefaxnumber: 'Enterprise fax number',
  pleaseenterthecontactperson: 'Please enter the contact person (name/phone/email address)',
  contactperson: 'Contact person (name/phone/email address)',
  pleaseenterthepersonresponsible: 'Please enter the person responsible for the complaint ',
  personresponsibleforcomplaint: 'Person responsible for complaint',
  website: 'Enterprise website',
  unloadziliao: 'Such as business license, permit, main business person ID card, insurance related information, tour guide certificate, tour leader certificate, trip planner training certificate, etc. jpg, jpeg, png and pdf files are supported.',
  additiveproduct: 'Add product',
  producttitle: 'Product title',
  articleTitle: 'Article title in Chinese',
  articleTitleEn: 'Article title',
  enterArticleTitle: 'Please enter the article title in Chinese',
  enterArticleTitleEn: 'Please enter the article title',
  entertheproducttitle: 'Please enter the product title',
  productprice: 'Product price',
  entertheproductprice: 'Please enter the product price',
  pleaseCompleteExplanation: 'Please complete explanation',
  pleaseCompleteTheItinerary: 'Please complete the itinerary',
  name: 'Name',
  content: 'Content',
  adddescriptionitem: 'Add description item',
  enteritinerary: 'Please enter itinerary',
  cancel: 'Cancel',
  customtag: 'Custom tag:',
  enteracustomlabel: 'Please enter a custom label',
  add: 'Add',
  confirm: 'Confirm',
  title: 'Title in Chinese',
  titleEn: 'Title',
  enterthetitle: 'Please enter the title in Chinese',
  enterthetitleEn: 'Please enter the title',
  entercontent: 'Please enter content in Chinese',
  entercontentEn: 'Please enter content',
  adddata: 'Add materials',
  resubmitApplication: 'Resubmit application',
  getback: 'Get back',
  businessidentity: 'Industry',
  otherBusinesses: 'Company Activity',
  pleaseenterpersonalbusinessprofile: 'Please enter personal/business profile',
  selectedabels: 'Selected labels (up to 6) :',
  tagname: 'Tag name',
  pleaseenterabelname: 'Please enter a label name',
  tradeicensenumber: 'Trade license number',
  allfeatures: 'All features (multiple options)',
  completed: 'Completed',
  jpgpngandpdf: 'jpg, jpeg, png and pdf files are supported.',
  businesslicense: 'Business license',
  uploaded: '(Uploaded)',
  businesspermits: 'Business permits',
  mainbusinesspersonIDcard: 'Main business person ID card',
  insuranceinformation: 'Insurance information',
  close: 'Close',
  compile: 'Compile',
  remove: 'Delete',
  price: 'Price',
  pleaseenterdetails: 'Please enter details',
  addstroke: 'Add stroke',
  productlabel: 'Product label',
  pleaseenteryourname: 'Please enter your name',
  individuallabel: 'Individual label',
  selectidentity: 'Select identity',
  touristcertificate: 'Tourist certificate',
  vocationaltrainingcertificateobtained: 'Vocational training certificate obtained',
  obtaineddestinationandproducttrainingcertificatesormaterials: 'Obtained destination and product training certificates or materials',
  havewonhonors: 'Have won honors',
  contactway: 'Contact way',
  phone: 'Phone',
  pleaseenteracontactnumber: 'Please enter a contact number',
  email: 'Email address',
  pleaseenteremail: 'Please enter email address',
  pleaseEnterAValidEmailAddress: 'Please enter a valid email address',
  hwebsite: 'Website',
  jpgpng: 'jpg, jpeg and png files are supported.',
  basicinfo: 'Basic info',
  pleaseentername: 'Please enter Organization Name',
  fillinuploadtheinformation: 'Become a registered annual member and enjoy all the benefits of SmoothTravel.',
  yourAccountIsCurrentlyUnderReviewYouCanFillInOrEditMoreInformationToCompleteTheReviewFaster: 'Your account is currently under review. You can expedite the process by enriching your profile. Thank you for your cooperation.',
  theInformationOnThisPageIsNotPublic: 'The information on this page is not public, and it is recommended to complete the information in order to facilitate the subsequent cooperation and exchange between enterprises',
  applyforprobation: 'Code (optional)',
  year: 'year',
  purchase: 'Purchase',
  corporatmembershiptrialapplication: 'Corporate membership trial application',
  dmomembershiptrialapplication: 'Association/Destination Tourism Bureau membership trial application',
  corporatemembershiptrial: 'Corporate membership trial is a limited period of welfare policy for enterprises that join the platform, we will be based on the completeness of the information you fill in as a consideration criteria to give the application of a certain period of corporate membership trial qualification.',
  dmocorporatemembershiptrial: 'Association/Destination Tourism Bureau membership trial is a limited period of welfare policy for enterprises that join the platform, we will be based on the completeness of the information you fill in as a consideration criteria to give the application of a certain period of corporate membership trial qualification.',
  filloutthepaperworkfirst: 'Fill out the paperwork first',
  applow: 'Apply now',
  applicationis: 'Application is successful, please wait for official review.',
  applicationisSuccessful: 'Congratulations on becoming a member of SmoothTravel! You now have full access to our wide range of unique services. ',
  pleaseselecttheocationofyourbusiness: 'Please select the location of your business',
  modifysuccessfully: 'Modify successfully',
  ok: 'OK',
  deleteornot: 'Do you want it to be deleted?',
  destinationCountriesReceivingTourists: 'Destination countries and regions receiving inbound Chinese tourists',
  psDestinationCountriesReceivingTourists: 'Please select destination countries and regions receiving inbound Chinese tourists',
  whetherTheDomesticBusinessCoversTheWholeCountry: 'Whether the domestic business covers the whole country',
  psWhetherTheDomesticBusinessCoversTheWholeCountry: 'Please select whether the domestic business covers the whole country',
  theMainMarketForDomesticBusiness: 'The main market for domestic business in Chinese',
  theMainMarketForDomesticBusinessEn: 'The main market for domestic business',
  psTheMainMarketForDomesticBusiness: 'Please enter the main market for domestic business in Chinese',
  psTheMainMarketForDomesticBusinessEn: 'Please enter the main market for domestic business',
  publicityPicture: 'Publicity Photos',
  publicityDocument: 'Marketing Materials',
  publicityArticle: 'Media Coverage in English or Chinese',
  pdfpptwodexcel: 'File upload support pdf, ppt, word and excel.',
  articleLabel: 'Article label',
  addArticle: 'Add article',

  // source = corporate 挪过来的
  sortByRating: 'Sort by rating',
  initiateCooperation: 'Cooperate',
  privateMessage: 'Message',
  basicInformation: 'Basic information',
  moredata: 'More data',
  reviews: 'Reviews',
  viewMore: 'View more',
  sendTo: 'Send to:',
  cooperationProduct: 'Cooperation product (optional):',
  cooperationTitle: 'Cooperation title',
  cooperationDetails: 'Cooperation details',
  uploadFiles: 'Upload files',
  reportComplaint: 'Report complaint',
  reportcomplaintTarget: 'Report/complaint target:',
  reportcomplaintTitle: 'Report/complaint title',
  'report/complaintDetails': 'Report/complaint details',
  productDetails: 'Product details',
  systemMessage: 'System message',
  search: 'Search',
  region: 'Geographic Location',
  typeofenterprise: 'Primary business',
  listofenterprises: 'List of enterprises',
  complaintsandreports: 'Report',
  supplementalInformation: 'More information',
  pleaseenteracollaborationtitle: 'Please enter a collaboration title',
  uploadhtxy: '(Upload cooperation materials, such as contracts, agreements, etc.)',
  send: 'Send',
  viewdetails: 'View details',
  pleaseenterareportcomplainttitle: 'Please enter a report/complaint title',
  pleaseenterareportcomplaint: 'Please enter a report/complaint',
  myorder: 'My order',
  set: 'set',
  logout: 'Log out',
  issuecooperation: 'Initiate collaboration',
  sendpictures: 'Send pictures',
  pleaseenteramessage: 'Please enter a message',
  type: 'Type',
  selectthearea: 'Select the area',
  submit: 'Submit',
  unfold: 'Unfold',
  packup: 'Pack up',
  enterprise: 'Enterprise',
  price1: 'Price:',
  journey: 'Journey',
  successfulcomplaint: 'Successful complaint',
  youdonothavepermission: 'You do not have permission',
  numberOfYearsInOperation: 'Number of years in operation',
  pleaseEnterNumberOfYearsInOperation: 'Please enter number of years in operation',
  globalNumberOfEmployees: 'Global number of employees',
  psGlobalNumberOfEmployees: 'Please select global number of employees',
  mainContactForTheChineseMarket: 'Main contact for the Chinese market',
  mainContactForTheChineseMarketEn: 'Main contact for the Chinese market',
  pleaseEnterMainContactForTheChineseMarket: 'Please enter name of main contact for Chinese market in Chinese',
  pleaseEnterMainContactForTheChineseMarketEn: 'Please enter name of main contact for Chinese market',
  mainContactForTheChineseMarketPhone: 'The phone number of the main contact for the Chinese market',
  pleaseEnterMainContactForTheChineseMarketPhone: 'Please enter the phone number of the main contact for the Chinese market',
  mainContactForTheChineseMarketEmail: 'The email address of the main contact for the Chinese market',
  pleaseEnterMainContactForTheChineseMarketEmail: 'Please enter the email address of the main contact for the Chinese market',
  officialWebsiteAddress: 'Official website Address',
  pleaseEnterOfficialWebsiteAddress: 'Please Enter official website Address',
  termsAndConditions: 'Terms and Conditions',
  pleaseEnterTermsAndConditions: 'Please enter terms and Conditions',
  operatingOfficeAddress: 'Operating office address',
  operatingOfficeAddressEn: 'Operating office address',
  pleaseEnterOperatingOfficeAddress: 'Please enter operating office address in Chinese',
  pleaseEnterOperatingOfficeAddressEn: 'Please enter operating office address',
  noBusinessWasFound: 'There are no companies',
  goaddinformation: 'I don\'t have any information yet. Go and add it now',

  // source hotelcenter 挪过来的
  regularCorporateMembership: 'Regular corporate membership',
  accountReviewInProgress: 'Account review in progress',
  accountReviewFailed: 'Account review failed',
  dataCompleteness: 'Data completeness:',
  relevantCertificatesAndMaterials: 'Relevant certificates and materials',
  myCollaborations: 'My collaborations',
  notConfirmed: 'Not confirmed',
  underReview: 'Under review',
  approved: 'Approved',
  editProduct: 'Edit product',
  qualifiedEmployees: 'Qualified employees',
  moreMaterials: 'More materials',
  tourGuide: 'Tour guide',
  teamLeader: 'Team leader',
  removeEmployee: 'Remove employee',
  addEmployee: 'Add employee',
  expand: 'Expand',
  collapse: 'Collapse',
  advancedEnterpriseMembership: 'Advanced Enterprise Membership',
  nocooperation: 'No cooperation',
  noProductsYetAddYourProductsNow: 'No products yet. Add your products now!',
  noDataYetAddYourInformationNow: 'No data yet. Add your information now!',
  noQualifiedEmployeesYetAddYourEmployeesNow: 'No qualified employees yet. Add your employees now!',
  noevaluate: 'No evaluations yet. Evaluations from collaborating enterprises will appear here after collaboration!',
  'customTags:': 'Custom tags:',
  'destinationTags:': 'Destination tags:',
  'selectedTags(upTo6):': 'Selected tags (up to 6):',
  addTags: 'Add tags',
  searchResults: 'Search results',
  'employeeNotFound.DirectlyAdd“xxAsANewEmployee.': 'Employee not found. Directly add “xx as a new employee.',
  addDocument: 'Add document',
  addPicture: 'Add picture',
  puProductImage: 'Please upload product images!',
  editdata: 'Edit data',
  noPersonalCorporateProfileProvided: 'No personal/corporate profile provided',
  dataIntegrity: 'Data integrity:',
  expirationtime: 'Expiration time:',
  basicinformation: 'Basic information',
  moreinformation: 'More materials',
  uploadziliao: 'Such as ID card/passport, tour leader pass/tour guide pass, vocational training certificate, destination and training certificate or information, honors obtained, etc. jpg, jpeg, png and pdf files are supported.',
  viewmore: 'View more',
  evaluate: 'Evaluate',
  fornewemployees: 'For new employees',
  directaddition: 'Direct addition',
  noismy: 'None of these are the employees I\'m looking for',
  nofound: 'Not found with',
  relevantemployee: 'relevant employee',
  cooperativeenterprise: 'Cooperative enterprise:',
  toevaluate: 'To evaluate',
  noinformationgotoaddit: 'No information, go to add it!',
  nocertificategotoaddacertificate: 'No certificate, go to add a certificate!',
  noevaluation: 'No evaluation',
  permissionswitch: 'Permissions switch:',
  havebeenadded: 'Have been added',
  iftheemployee: 'If the employee is not found, add it directly',
  whethetoremovtheemployee: 'Whether to remove the employee',
  confirmremoval: 'Confirm remove',
  gotoCertificate: 'No certificate, go to add a certificate',
  corporatemember: 'Corporate member',
  associationMember: 'Association member',
  premiumenterprisemember: 'Premium member',
  ranking: 'Ranking',
  turndown: 'turn down',
  businesslicensenumber: 'Business License Number:',
  egisteredddress: 'Registered Address:',
  companycontact: 'Company contact:',
  corporatefaxnumber: 'Corporate fax Number:',
  enterprisecontact: 'Enterprise Contact:',
  complaintholder: 'Complaint holder:',
  searchNow: 'Search',
  reset: 'Reset',
  resultFindBegin: "",
  resultFindCener: " results found (in ",
  resultFindEnd: " seconds)",
  /**
   * 表里数据结束
   */
}
