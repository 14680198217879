/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */

import axios from 'axios'
import { ElMessage } from 'element-plus'
import { userAdminInfo } from '@/store/modules/admin.js'
import admin from "@/request/api/module/admin";

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    // 401: 未登录状态，跳转登录页
    case 401: // 未登录
      // toLogin()
      break
    // 403 token过期
    // 清除token并跳转登录页
    case 403:
      setTimeout(() => {
        // toLogin()
      }, 1000)
      break
    // 404请求不存在
    case 404:
      // window.$message.error('请求的资源不存在')
      break
    case 500:
      // store.commit('changeNetwork', false)
      // window.$message.error('网络异常!')
      break
    // router.push({path:'refresh'})
    default:

  }
}

const headerConfig = {
  json: {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'X-Requested-With': 'XMLHttpRequest'
    }
  },
  form: {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  file: {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  },
  excel: {
    responseType: 'arraybuffer'
  }
}

const instance = axios.create({
  baseURL: `${import.meta.env.VITE_APP_BASE_API}/`,
  timeout: 10000
})

// 设置默认请求头
// instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
instance.defaults.headers.post['Content-Type'] = headerConfig.json.headers['Content-Type']

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  config => {
    /** ********************token配置********************** */
    const adminInfo = userAdminInfo()
    const { token } = adminInfo
    const { language } = adminInfo
    // eslint-disable-next-line no-unused-expressions,no-param-reassign
    token && (config.headers['access-token'] = token)
    language && (config.headers.language = language)
    return config
  },
  error => Promise.error(error)
)

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  res => {
    if (res.data.code === 20000) {
      return Promise.resolve(res.data)
    }
    // 请重新登录
    if (res.data.code === 4030001) {
      userAdminInfo().logout()
      ElMessage.error(res.data.msg)
    }

    ElMessage.error(res.data.msg)

    return Promise.reject(res.data)
  },
  // 请求失败
  // eslint-disable-next-line consistent-return
  error => {
    const { response } = error
    if (response) {
      // 请求已发出，但是不在2xx的范围
      errorHandle(response.status, response.data.window.$message)
      // return Promise.reject(response)
      return Promise.resolve(response)
    }
    // 处理断网的情况
    // eg:请求超时或断网时，更新state的network状态
    // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
    // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
    // store.dispatch('changeNetwork', false);
    // router.push({path:'refresh'})
    // window.$message.error(response || '其他错误')

    // messageOnce.error('网络异常，请稍后重试')
    // window.$message.error('网络异常，请稍后重试')
  }
)

export default instance
