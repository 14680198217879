/**
 *私信模块
 */
import axios from '@/request/http'

const privateapi = {
  getChatList(data) {
    return axios.get(`/api/chat?memberId=${data?.memberId || 0}`)
  },
  getSystem() {
    return axios.get(`/api/chat/system-last`)
  },
  getMessage(params) {
    return axios.get(`/api/chat-message`, { params })
  },
  sendMessage(data) {
    return axios.post(`/api/chat`, data)
  },
  infoMation() {
    return axios.get(`/api/chat-message/unread`)
  }
}
export default privateapi
