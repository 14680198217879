import axios from '@/request/http'

const cooperates = {
  sendCooperates(data) {
    return axios.post(`/api/cooperates`, data)
  },
  // 修改合作
  editCooperates(params) {
    return axios.put(`/api/cooperates/${params.id}`, params.form)
  },
  // 合作详情
  cooperatesDetail(id) {
    return axios.get(`/api/cooperates/${id}`)
  },
  acceptCooperates(params) {
    return axios.put(`/api/cooperates/${params.id}/accept`, params.status)
  }
}
export default cooperates
