import { createI18n } from 'vue-i18n'

const language = localStorage.getItem('language') || 'cn'
const getLanguage = () => {
  let files = []
  // 这里用不了 `./${language}/*.js` 这种写法, 没细查
  if (language === 'en') {
    files = import.meta.globEager(`./en/*.js`)
  } else {
    files = import.meta.globEager(`./cn/*.js`)
  }
  const messages = { [language]: {} }

  Object.keys(files).forEach(path => {
    const name = path.match(/([A-Za-z0-9_]+)/g)[1]
    if (files[path].default) {
      messages[language][name] = files[path].default
    }
  })

  return messages
}

const i18n = createI18n({
  locale: language,
  legacy: false,
  globalInjection: true,
  fallbackLocale: 'cn',
  messages: getLanguage()
})

export default i18n
