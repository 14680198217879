import axios from '@/request/http'

const enterprise = {
  // 企业列表
  getEnterpriseList(params) {
    return axios.get(`/api/members/enterprise`, { params })
  },
  // 企业信息
  getEnterpriseDetail(params) {
    return axios.get(`/api/members/${params.id}`)
  },
  //
  getEnterpriseInfo(params) {
    return axios.get(`/api/informations/${params.id}`)
  },
  // 产品列表
  getProdsList(params) {
    return axios.get(`/api/prods`, { params })
  },
  searchSenior(params) {
    return axios.get(`/api/members/staff`, { params })
  },
  addSenior(data) {
    return axios.post(`/api/staff`, data)
  },
  delSenior(data) {
    return axios.delete(`/api/staff/enterpriseBreakAway`, { data })
  },

  complaintEn(data) {
    return axios.post(`/api/complaint`, data)
  },
  setAuthorization(data) {
    return axios.put(`/api/staff/${data.id}/authorization`, data.data)
  },
  quitEnterprise(params) {
    return axios.delete(`/api/staff/breakAway`, { params })
  }
}
export default enterprise
