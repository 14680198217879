import axios from '@/request/http'

const enterprise = {
  // 地区列表
  getAddressList(params) {

    return axios.get(`/api/address`, { params })
  }
}
export default enterprise
